import React, { Component, Fragment } from "react";
import * as ORDER from "../../api/order";
import { connect } from "react-redux";
import { addMyCart, updateToken } from "../../actions/index";
import * as AUTH from "../../api/auth";
import { toast } from "react-toastify";
import moment from "moment";
import * as GENERAL from "../../api/general";

const stateArr = GENERAL.getStateList();
class PaymentCheckout extends Component {
  constructor(props) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    let orderNum = null;
    let topupNum = null;
    if (params.get("order")) {
      // topup ref no use TUEW
      if (params.get("order").match(/TUEW/g)) {
        orderNum = null;
        topupNum = params.get("order");
      } else if (params.get("order").match(/INV/g)) {
        orderNum = params.get("order");
        topupNum = null;
      }
    }
    this.state = {
      orderID: orderNum,
      topupID: topupNum,
      status: params.get("status") ? params.get("status") : null,
      loading: true,
      isMember: AUTH.getAccessToken() ? 1 : 0,
    };
  }

  render() {
    const { orderID, status, isMember, topupID } = this.state;
    return orderID && status ? (
      <section className="section-b-space light-layout">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                {status == "success" ? (
                  <div>
                    <i className="fa fa-check-circle" style={{ color: "green" }} aria-hidden="true" />
                    <h2 style={{ textTransform: "capitalize" }}>thank you for your order</h2>
                    <p>Payment has been received.</p>
                  </div>
                ) : status == "fail" ? (
                  <div>
                    <i className="fa fa-times" style={{ color: "red" }} aria-hidden="true" />
                    <h2 style={{ textTransform: "capitalize" }}>Payment Fail</h2>
                    <p>Failed. Please try again later</p>
                  </div>
                ) : status == "cancel" ? (
                  <div>
                    <i className="fa fa-times" style={{ color: "black" }} aria-hidden="true" />
                    <h2 style={{ textTransform: "capitalize" }}>Cancel Payment</h2>
                    <p>The order has been cancelled.</p>
                  </div>
                ) : null}

                <p>Order No : {orderID}</p>

                {isMember && status == 'success' ? (
                  <button style={gotoList} onClick={() => this.props.history.replace({ pathname: "/members/order-detail/" + orderID })} className="btn">
                    Check Order Details
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : topupID && status ? (
      <section className="section-b-space light-layout">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                {status == "success" ? (
                  <div>
                    <i className="fa fa-check-circle" style={{ color: "green" }} aria-hidden="true" />
                    <h2 style={{ textTransform: "capitalize" }}>thank you for your order</h2>
                    <p>Payment has been received.</p>
                  </div>
                ) : status == "fail" ? (
                  <div>
                    <i className="fa fa-times" style={{ color: "red" }} aria-hidden="true" />
                    <h2 style={{ textTransform: "capitalize" }}>Payment Fail</h2>
                    <p>Failed. Please try again later</p>
                  </div>
                ) : status == "cancel" ? (
                  <div>
                    <i className="fa fa-times" style={{ color: "black" }} aria-hidden="true" />
                    <h2 style={{ textTransform: "capitalize" }}>Cancel Payment</h2>
                    <p>The request has been cancelled.</p>
                  </div>
                ) : null}

                <p>Ref No : {topupID}</p>
              </div>
            </div>
            <div className="col-sm-12" style={{textAlign: "center"}}>
              <a href="/" className="btn btn-solid">
                back to home
              </a>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <section className="p-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-section">
                <h1>404</h1>
                <h2>page not found</h2>
                <a href="index.html" className="btn btn-solid">
                  back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const gotoList = {
  backgroundColor: "#00b346",
  color: "white",
  padding: "10px 20px",
};

const mapStateToProps = (state) => ({
  symbol: state.data.symbol,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  addMyCart: (cart) => dispatch(addMyCart(cart)),
  updateToken: (token) => dispatch(updateToken(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCheckout);
