import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as Order from "../../../api/order";
import { Link } from "react-router-dom";
import { updateToken, addMyCart } from "../../../actions/index";
import { connect } from "react-redux";
import * as AUTH from "../../../api/auth";
import * as DOWNLINE from "../../../api/downline";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import SideMenu from "../sidemenu";
import Select from "react-select";
import "../pagination.css";
const TabList = [
  { name: "All", value: "0" },
  { name: "To Pay", value: "1" },
  { name: "To Ship", value: "2,7,14" },
  { name: "To Receive", value: "3" },
  { name: "Cancelled", value: "5,11" },
  { name: "Refunded", value: "6" },
  // { name: "To Review", value: "4" },
];
class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: [],
      isLoading: true,
      order: "",
      orderDetails: [],
      offset: 0,
      data: [],
      perPage: 5,
      currentPage: 1,
      pagination: 5,
      currentTab: TabList[0],
      memberList: [],
      roleOptions: [
        {
          value: 'mine',
          label: 'Mine'
        },
        {
          value: 'd',
          label: 'Reseller'
        },
        {
          value: 'p,f',
          label: 'Customer'
        }
      ],
      selectedRoleOption: null,
      selectedRole: null,
      options: [
        {
          value: 'all',
          label: 'All'
        }
      ],
      selectedOption: null,
      disableUserChange: true,
    };
    this.myRef = React.createRef();
  }

  componentWillMount() {
    this.getOrderList();
    this.getDownlineList();
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.getOrderList(true);
      }
    );
  };

  changeTab(tab) {
    this.setState({ currentTab: tab, orderList: [], orderDetails: [], isLoading: true, currentPage: 1 }, () => {
      this.getOrderList();
    });
  }

  getDownlineList(role = null) {
    if (role == 'p,f') {
      DOWNLINE.getCustomerDownlineList({ per_page: 999, page: 1, search: this.state.input, acc_type: 'p,f' })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((member) => {
              this.state.options.push({ value: member.id, label: member.name + " - " + member.email });
            });
          } else {
            // this.setState({})
          }
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            this.tokenExpiredHandle();
          }
        }).then(()=> {
          this.setState({ disableUserChange: false});
        });
      
    }

    if (role == 'd') {
      DOWNLINE.getDownlineList({ per_page: 999, page: 1, search: this.state.input, acc_type: 'd,m' })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((member) => {
              this.state.options.push({ value: member.id, label: member.name + " - " + member.email });
            });
          } else {
            // this.setState({})
          }
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            this.tokenExpiredHandle();
          }
        }).then(()=> {
          this.setState({ disableUserChange: false });
        });
    }
  }

  handleRoleChange = (selectedRoleOption) => {
    this.setState({ selectedRoleOption});    
    if (selectedRoleOption.value) {
      this.state.disableUserChange = true;

      if(selectedRoleOption.value != 'mine') {
        this.state.options = [
          {
            value: 'all',
            label: 'All'
          },
        ];
        this.getDownlineList(selectedRoleOption.value);
      } 

      this.getOrderList(selectedRoleOption.value);
      this.setState({ selectedOption: null });
    } 
  }

  handleUserChange = (selectedOption) => {
    this.setState({ selectedOption });
    if (selectedOption.value !== "0") {
      let $role = this.state.selectedRoleOption ? this.state.selectedRoleOption.value : null;
      this.getOrderList($role, selectedOption.value);
    }
  }

  getOrderList(role = 'mine', selectedOption = null, loadMore) {
    let params = {
      per_page: 10,
      page: this.state.currentPage,
    };
    
    if (this.state.currentTab.value !== "0") {
      params.status = this.state.currentTab.value;
    }

    let actionApi = ''
    if (role == 'mine') {
      actionApi = Order.getOrderList(params);
    } else {
      params.role = role;
      if (selectedOption)
        params.target_id = selectedOption;
        
      actionApi = Order.getDownlineOrderList(params);
    }

    actionApi
      .then((res) => {
        if (res.data === null) {
          this.setState({
            order: false,
            isLoading: false,
          });
        } else if (!res.errors) {
          this.setState(
            {
              orderList: res.data.filter((order) => order.order_detail !== null),
              order: true,
              pagination: res.pagination,
              orderDetails: res.data.order_detail,
            },
            () => {
              if (loadMore) window.scrollTo(0, this.myRef.current.offsetTop + 120);
              this.setState({
                isLoading: false,
              });
            }
          );
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
          this.setState({
            order: false,
            isLoading: false,
          });
        }
      });
  }

  render() {
    const { orderList, order, currentTab, options, selectedOption, roleOptions, selectedRoleOption } = this.state;
    const { user, isDealer } = this.props;
    const getBadge = (status) => {
      return status === "1"
        ? "badge badge-info" //pending
        : status === "2"
        ? "badge badge-secondary" //processing
        : status === "3"
        ? "badge badge-success" //delivered
        : status === "4"
        ? "badge badge-success" //collected
        : status === "5"
        ? "badge badge-dark" //cancelled
        : status === "6"
        ? "badge badge-success" //refunded
        : status === "7"
        ? "badge badge-success" //payment cleared / received
        : status === "8"
        ? "badge badge-warning" //payment clearing
        : status === "9"
        ? "badge badge-info" //deposit received
        : status === "10"
        ? "badge badge-info" //waiting stock
        : status === "11"
        ? "badge badge-secondary" //request cancelled
        : status === "12"
        ? "badge badge-secondary" //request refund
        : status === "13"
        ? "badge badge-danger" //request refund rejected
        : "badge badge-primary";
    };
    return (
      <div>
        <Breadcrumb title={"Orders"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab={"order"} />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="form-row">
                      <div className="col-6">
                        <div className="page-title">
                          <h2>Order List</h2>
                        </div>
                      </div>
                      {/* <div className="col-6 text-right">
                        <button className="btn btn-solid small">Return Refund</button>
                      </div> */}
                    </div>
                    <div className="welcome-msg">
                      <p>All your recent purchases will be display here.</p>
                    </div>
                    <hr />
                    <div className="welcome-msg">
                      <p>Filter order.</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                          <div className="mb-2">
                            <Select
                              value={selectedRoleOption}
                              styles={customStyles}
                              placeholder="Select role"
                              onChange={this.handleRoleChange}
                              options={roleOptions}
                            />
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div className="mb-2">
                            <Select
                              isDisabled={this.state.disableUserChange}
                              value={selectedOption}
                              styles={customStyles}
                              placeholder="Select user (name/email)"
                              onChange={this.handleUserChange}
                              options={options}
                            />
                          </div>
                        </div>
                    </div>

                    <div className="box-account box-info">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="box" ref={this.myRef}>
                            {/* <div className="box-title" /> */}
                            <div className="filter-tabs">
                              {TabList.map((tab, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={currentTab.name == tab.name ? "c-pointer active" : "c-pointer"}
                                    onClick={() => this.changeTab(tab)}>
                                    {tab.name}
                                  </div>
                                );
                              })}
                            </div>
                            {this.state.isLoading && orderList ? (
                              <div className="loading-cls" />
                            ) : (
                              <div className="box-content">
                                {order ? (
                                  <Fragment>
                                    <div style={{ marginTop: "10px" }}>
                                      {orderList.map((order, index) => (
                                        <Fragment key={index}>
                                          <div style={orderBox}>
                                            <span className={getBadge(order.status.id) + " orderlist-tag"}>
                                              {order.status.id == "1" ? "Pending Payment" : order.status.status_name}
                                            </span>
                                            {
                                              order.customer ? <>
                                                <span className="badge bg-primary orderlist-tag ml-2">Customer</span>
                                              </> : null
                                            }
                                            {isDealer ? (
                                              order.buyer && order.buyer.id == 0 ? (
                                                <span className="badge bg-primary orderlist-tag ml-2">One Time Customer</span>
                                              ) : order.buyer && order.buyer.id && order.buyer.id !== user.id ? (
                                                <span className="badge bg-primary orderlist-tag ml-2">Downline</span>
                                              ) : null
                                            ) : null}
                                            {order.buyer && order.buyer.fname && order.buyer.id !== user.id ? (
                                              <p style={{ marginBottom: "0px" }}>Purchased By : {order.buyer.fname}</p>
                                            ) : null}
                                            <p style={{ marginBottom: "0px" }}>
                                              Order{" "}
                                              <Link to={`${process.env.PUBLIC_URL}/members/order-detail/${order.refno}`}>
                                                #{order.refno}
                                              </Link>
                                            </p>
                                            <div className="form-row">
                                              <div className="col-lg-6">
                                                <p style={{ fontSize: "13px", marginBottom: "3px" }}>Placed on {order.cdate}</p>
                                              </div>
                                              <div className="col-lg-6">
                                                <Link to={`${process.env.PUBLIC_URL}/members/order-detail/${order.refno}`}>
                                                  <button
                                                    style={manageBtn}
                                                    type="button"
                                                    className="btn btn-outline-primary btn-sm">
                                                    Manage
                                                  </button>
                                                </Link>
                                              </div>
                                            </div>
                                            <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
                                            {order.order_detail.map((info, index) => (
                                              <Fragment key={index}>
                                                <div className="order-list row">
                                                  <div className="col-lg-5 col-md-5 col-12">
                                                    <p>
                                                      {info.pname}{" "}
                                                      {info.combo ? (
                                                        <div className="combo-list order text-left">
                                                          {info.combo.map((comboItem, index) => {
                                                            return (
                                                              <p key={index}>
                                                                <span>-</span> {comboItem}
                                                              </p>
                                                            );
                                                          })}
                                                        </div>
                                                      ) : null}
                                                      {info.attribute1
                                                        ? "(" + info.attribute1 + (info.attribute2 ? "+ " + info.attribute2 : "")
                                                        : null}
                                                      {info.attribute1 ? ")" : null}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-2 col-md-2 col-4">
                                                    <p>RM {info.unit_price}</p>
                                                  </div>
                                                  <div className="col-lg-2 col-md-2 col-4">
                                                    <p>
                                                      <span style={{ color: "#bfbfbf" }}>Qty:</span> {info.qty}
                                                    </p>
                                                  </div>
                                                  <div className="col-lg-3 col-md-3 col-4">
                                                    <p>RM {info.sub_total}</p>
                                                  </div>
                                                </div>
                                                <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
                                              </Fragment>
                                            ))}
                                            <Fragment>
                                              <div className="row text-right">
                                                <div className="col-6  col-sm-8 col-lg-9">
                                                  <p className="mb-0">Shipping Fees :</p>
                                                  {order.packing ? <p className=" mt-1 mb-0">Packing Fees :</p> : null}
                                                  <p className="mb-0" style={{ marginTop: 10 }}>
                                                    Total Price :
                                                  </p>
                                                </div>
                                                <div className="col-6  col-sm-4 col-lg-3">
                                                  <p className="mb-1">
                                                    RM{" "}
                                                    {order.shipping_fee
                                                      ? order.shipping_fee
                                                      : parseFloat(order.total_price - order.subtotal).toFixed(2)}
                                                  </p>

                                                  {order.packing ? (
                                                    <p className="mb-1">RM {parseFloat(order.packing_fee).toFixed(2)}</p>
                                                  ) : null}

                                                  <div className="form-row justify-content-end align-items-end">
                                                    RM
                                                    <h3 className="mr-1 ml-1" style={{ color: "#007bff", marginBottom: "0px" }}>
                                                      {order.total_price}
                                                    </h3>
                                                  </div>
                                                </div>
                                              </div>
                                            </Fragment>
                                          </div>
                                        </Fragment>
                                      ))}
                                    </div>
                                  </Fragment>
                                ) : (
                                  <div className="mt-3">
                                    <h6>No Order Records Found</h6>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {orderList.length > 0 ? (
                      <div>
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pagination.total_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const orderBox = {
  border: "1px solid #ddd",
  padding: "15px",
  marginBottom: "18px",
};

const manageBtn = {
  float: "right",
  marginBottom: "10px",
};

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0px 8px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    height: "auto",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "0px",
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: "#B7D432",
    padding: "unset !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: "0px",
    paddingBottom: "0px",
    margin: "0px",
  }),
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

function mapStateToProps(state) {
  return {
    user: state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
