import React, { Component } from "react";
import { toast } from "react-toastify";
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from "../../../api/profile";
import { Link, Redirect } from "react-router-dom";
import { updateToken, addMyCart } from "../../../actions/index";
import { connect } from "react-redux";
import * as AUTH from "../../../api/auth";
import * as GENERAL from "../../../api/general";
import SideMenu from '../sidemenu'

const stateArr = GENERAL.getStateList();
const re = /^[0-9\b]+$/;
const reZip = /^\d{5}$/;

class NewAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      fname: "",
      lname: "",
      shipping: 0,
      billing: 0,
      street1: "",
      street2: "",
      city: "",
      zip: "",
      state: "",
      mobileno: "",
      country: "MY",
      addErr: false,
      failMsg: "",
      id: props.match.params.id ? props.match.params.id : 0,
    };
  }

  handleSubmit(e) {
    this.setState({
      failMsg: "",
    });
    e.preventDefault();
    let valid = true;
    if(this.state.state==""){
      valid = false
      this.setState({
        addErr: true,
        failMsg: 'State is required'
      })
    }
    if (!re.test(this.state.mobileno)) {
      valid = false;
      this.setState({
        addErr: true,
        failMsg: "The Mobile Number is invalid",
      });
    }
    this.state.zip = this.state.zip.trimEnd();
    if (!reZip.test(this.state.zip)) {
      valid = false;
      this.setState({
        addErr: true,
        failMsg: "This Zip code is invalid, it may only contain 5 digit number",
      });
    }
    if (valid === true) {
      const addressData = new FormData();
      if(this.state.title!=='0')
        addressData.append("title", this.state.title);
      addressData.append("fname", this.state.fname);
      // addressData.append("lname", this.state.lname);
      addressData.append("shipping", this.state.shipping);
      addressData.append("billing", this.state.billing);
      addressData.append("street1", this.state.street1);
      addressData.append("street2", this.state.street2);
      addressData.append("city", this.state.city);
      addressData.append("zip", this.state.zip);
      addressData.append("state", this.state.state);
      addressData.append("mobileno", this.state.mobileno);
      addressData.append("country", this.state.country);
      Profile.addAddress(addressData)
        .then((res) => {
          toast.success("New address created successfully!", { autoClose: 3000, position: "top-center" });
          this.setState({
            fname: "",
            lname: "",
            shipping: "",
            billing: "",
            street1: "",
            street2: "",
            city: "",
            zip: "",
            state: "",
            mobileno: "",
            country: "",
          });
          this.props.history.replace("/members/address");
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          } else {
            toast.error(err.message, { autoClose: 3000, position: "top-center" });
          }
        });
    }
  }

  render() {
    const { addErr, failMsg } = this.state;
    return (
      <div>
        <Breadcrumb title={"Add New Address"} />
        {/*Regsiter section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab={'address'}/>
              </div>

              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>Add New Address</h2>
                    </div>
                    <div className="welcome-msg">
                      <p>Fill up the form below to add new address.</p>
                    </div>
                    <div className="box-account box-info">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-title" />
                            <div className="row">
                              <div className="col-lg-6 col-6" style={{ marginTop: "8px", textAlign: "left" }}>
                                <Link style={{ color: "#2576FF" }} to={`${process.env.PUBLIC_URL}/members/address`}>
                                  <i className="fa fa-chevron-left" />&nbsp; Back
                                </Link>
                              </div>
                            </div>
                            <div className="box-content">
                              <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                <div className="form-row" style={{ marginTop: "15px" }}>
                                  <div className="col-md-6">
                                    <label for="title">Title</label>
                                    <select
                                      required
                                      onChange={(e) => this.setState({ title: e.target.value })}
                                      value={this.state.title}
                                      className="form-control"
                                      id="title">
                                      <option value="0">Select title(Optional)</option>
                                      <option value="1">Mr</option>
                                      <option value="2">Ms</option>
                                      <option value="3">Mrs</option>
                                      <option value="4">Madam</option>
                                    </select>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="fname">Name</label>
                                    <input
                                      onChange={(e) => this.setState({ fname: e.target.value })}
                                      value={this.state.fname}
                                      type="text"
                                      className="form-control"
                                      id="fname"
                                      placeholder="Your name"
                                      required
                                    />
                                  </div>
                                </div>
                                 
                                <div className="form-row" style={{ marginTop: "15px" }}>
                                  <div className="col-md-6">
                                    <label htmlFor="street1">Street 1</label>
                                    <input
                                      onChange={(e) => this.setState({ street1: e.target.value })}
                                      value={this.state.street1}
                                      type="text"
                                      className="form-control"
                                      id="street1"
                                      placeholder="18, Jalan Example"
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="street2">Street 2</label>
                                    <input
                                      onChange={(e) => this.setState({ street2: e.target.value })}
                                      value={this.state.street2}
                                      type="text"
                                      className="form-control"
                                      id="street2"
                                      placeholder="Taman Example(Optional)"
                                    />
                                  </div>
                                </div>
                                <div className="form-row" style={{ marginTop: "15px" }}>
                                  <div className="col-md-6">
                                    <label htmlFor="city">City</label>
                                    <input
                                      onChange={(e) => this.setState({ city: e.target.value })}
                                      value={this.state.city}
                                      type="text"
                                      className="form-control"
                                      id="city"
                                      placeholder="Kepong"
                                      required
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="zip">Zip</label>
                                    <input
                                      onChange={(e) => this.setState({ zip: e.target.value })}
                                      value={this.state.zip}
                                      type="text"
                                      className="form-control"
                                      id="zip"
                                      placeholder="52100"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row" style={{ marginTop: "15px" }}>
                                  <div className="col-md-6">
                                    <label htmlFor="state">State</label>
                                    {/* <input onChange={(e)=>this.setState({state : e.target.value})} value={this.state.state} type="text" className="form-control" id="state" placeholder="Kuala Lumpur" required /> */}
                                    <select
                                      onChange={(e) => this.setState({ state: e.target.value })}
                                      value={this.state.state}
                                      className="form-control"
                                      id="state">
                                      {stateArr.map((state, index) => (
                                        <option key={index} value={Object.keys(state)}>{Object.values(state)}</option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="mobileno">Mobile Number</label>
                                    <input
                                      onChange={(e) => this.setState({ mobileno: e.target.value })}
                                      value={this.state.mobileno}
                                      type="number"
                                      className="form-control"
                                      id="mobileno"
                                      placeholder="01xxxxxxxxx"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-row" style={{ marginTop: "15px" }}>
                                  <div className="col-md-6">
                                    <label for="shipping">Make as default Shipping Address:</label>
                                    <select
                                      required
                                      onChange={(e) => this.setState({ shipping: e.target.value })}
                                      value={this.state.shipping}
                                      className="form-control"
                                      id="shipping">
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                  <div className="col-md-6">
                                    <label for="billing">Make as default Billing Address:</label>
                                    <select
                                      required
                                      onChange={(e) => this.setState({ billing: e.target.value })}
                                      value={this.state.billing}
                                      className="form-control"
                                      id="billing">
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                  {addErr ? <div style={errMsg}>{failMsg}</div> : null}
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  <button style={{ marginTop: "20px" }} type="submit" className="btn btn-solid">
                                    Confirm
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const errMsg = {
  color: "red",
  marginLeft: 6,
  paddingBottom: 5,
  marginTop: "10px",
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(
  null,
  mapDispatchToProps
)(NewAddress);
