import React,  { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import "./common/index.scss";
import Slider from "react-slick";
import { toast } from 'react-toastify'
import { Link } from "react-router-dom";

// import { Link } from "react-router-dom";
// import Categories from "../components/home/categories";
import Categories2 from "../components/home/categories2";
import ShippingModal from "../components/home/shippingModal";
// import moment from "moment";
import SpecialProducts from "../components/home/special-products"
import SpecialCategories from "../components/home/special-categories"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./custom-slick.css";
// Import custom components
import { svgFreeShipping, svgservice, svgoffer } from "../services/script";
import Cookies from "universal-cookie";
import "./home.css";
import * as GENERALS from "../api/general";
import moment from "moment";
import { withRouter } from "react-router-dom"

const cookies = new Cookies();
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addressModal: false
    };
  
  }

  async componentWillMount() {
    document.getElementById("BODY").style.touchAction = "manipulation";
  }

  componentWillUnmount() {
    document.getElementById("BODY").style.touchAction = null;
  }

  async componentDidMount() {
    if (!this.props.isLoading) {
      let hvPopBefore = await localStorage.getItem("popUp");
      if (!hvPopBefore) {
        this.setPopUpToStorage();
      } else {
        let popDateTime = JSON.parse(hvPopBefore);
        if (new Date(moment(popDateTime).add(1, "days")) < new Date()) {
          this.setPopUpToStorage();
        }
      }
      let query = new URLSearchParams(this.props.location.search);
      this.ref = query.get("ref");
      if (this.ref) {
        this.getReferralDetails();
      }
    }
  }
  
  getReferralDetails() {
    GENERALS.getReferralDetails(this.ref)
      .then((res) => {
        if (res) {
          if (typeof res.referral === "object" && res.referral !== null) {
            localStorage.setItem("referral", JSON.stringify(res.referral));
          }
        }
      })
      .catch((err) => {
    });
  }
  
  setPopUpToStorage() {
    this.setState({ open: true }, () => {
      localStorage.setItem("popUp", JSON.stringify(new Date()));
    });
  }

  render() {
    const { bannerImg, history, isLoading } = this.props;
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = websiteTitle;
    var seoDesc = "No more upfront inventory costs or shipping logistics. With dropshipping, products are send directly from VBOOX Dropship to your customers.";
    var seoImage = process.env.BASE_URL + "/logo.png";
    const toastId = "dropshipping";
    const InfoToast = () => (
      <Fragment>
        <div className='toastify-header'>
          <div className='title-wrapper'>
            {/* <Avatar size='sm' color='info' icon={<Info size={12} />} /> */}
            <h6 className='text ml-50 mb-0'>Are You Looking for Passive Income? Dropshipping is what you need!!!!</h6>
          </div>
         
        </div>
        <div className='toastify-body' >
          <Link className="btn btn-solid small"  to={`${process.env.PUBLIC_URL}/dropshipping-info`} data-lng="en">
          Get Started
            </Link>

        </div>
      </Fragment>
        );

    if(! toast.isActive(toastId)) {
      toast(<InfoToast />, { autoClose: false, hideProgressBar: true, position: "bottom-right", toastId: toastId});
    }

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} />
          <meta property="og:title" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={process.env.BASE_URL} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={seoDesc} />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={seoDesc} />
          <meta name="twitter:image" content={seoImage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta itemprop="description" property="description" content={seoDesc} />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{seoTitle}</title>
        </Helmet>
        {this.state.addressModal === true ? <ShippingModal></ShippingModal> : null}
        <section className="p-0">
          <Slider className="slide-1 home-slider" autoplay dots>
            {bannerImg && bannerImg.length > 0 ? (
              bannerImg.map((img) => {
                return (
                  <img key={img.id} src={img.filename} />
                );
              })
            ) : (
                <div>
                  <div className="home home39 text-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home-banner/banner-no-image.jpg)` }}>
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <div className="slider-contain"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </Slider>
        </section>
        {/*collection banner layout*/}
        <section className="banner-padding absolute-banner pb-0">
          <div className="container absolute-bg">
            <div className="service p-0">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                    <div className="media-body">
                      <h4 >Delivery In Same Day</h4>
                      <p>Fastest 30 minute*</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                    <div className="media-body">
                      <h4>24 X 7 service</h4>
                      <p>online service for new customer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                    <div className="media-body">
                      <h4 >Combo offer</h4>
                      <p>new online special festival offer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*collection banner layout end*/}
        {!isLoading ? (
          <>
            <SpecialProducts title="Special Offer" subtitle="" history={history} />
            <SpecialCategories title="Popular Category" subtitle="" history={history}/>
            <Categories2 title="Categories" subtitle="" history={history} />
          </>
        ) : null}
      </div>
    );
  }
}

const closeBtn = {
  width: "100px",
  alignSelf: "center",
};

const infoText = {
  fontSize: 17,
  marginVertical: 10,
};

const modalBox = {
  padding: 30,
  textAlign: "center",
  borderWidth: 1,
};

export default withRouter(Home);
