import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as Order from "../../../api/order";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { updateToken } from "../../../actions/index";
import { connect } from "react-redux";
import * as AUTH from "../../../api/auth";
import * as General from "../../../api/general";
import SideMenu from "../sidemenu";
import Modal from "react-responsive-modal";
const stateArr = General.getStateList();
const getBadge = (status) => {
  return status === "1"
    ? "badge badge-info" //pending
    : status === "2"
      ? "badge badge-secondary" //processing
      : status === "3"
        ? "badge badge-success" //delivered
        : status === "4"
          ? "badge badge-success" //collected
          : status === "5"
            ? "badge badge-dark" //cancelled
            : status === "6"
              ? "badge badge-success" //refunded
              : status === "7"
                ? "badge badge-success" //payment cleared / received
                : status === "8"
                  ? "badge badge-warning" //payment clearing
                  : status === "9"
                    ? "badge badge-info" //deposit received
                    : status === "10"
                      ? "badge badge-info" //waiting stock
                      : status === "11"
                        ? "badge badge-secondary" //request cancelled
                        : status === "12"
                          ? "badge badge-secondary" //request refund
                          : status === "13"
                            ? "badge badge-danger" //request refund rejected
                            : "badge badge-primary";
};
class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      shippingDetails: [],
      billingDetails: [],
      paymentDetails: [],
      orderList: [],
      isLoading: true,
      refno: props.match.params.id ? props.match.params.id : 0,
      cancelSuccess: false,
      bankName: "",
      bankAccName: "",
      bankAccNo: "",
      openModal: false,
      openPaymentModal: false,
      paymentMethodList: [],
      paymentMethod: undefined,
      paymentUrl: undefined,
      paymentData: {},
      checkoutId: undefined,
      loadingPayment: false,
    };
  }

  componentWillMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getOrderDetail();
    this.getBankInfo();
  }

  resize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  getBankInfo() {
    General.getSystemSettingsGeneral().then((res) => {
      this.setState({
        bankName: res.data.bank_name.value,
        bankAccName: res.data.bank_account_name.value,
        bankAccNo: res.data.bank_account_no.value,
      });
    });
  }

  cancelOrder() {
    Order.cancelOrder(this.state.refno)
      .then((res) => {
        toast.success(res.data.order);
        this.setState({ cancelSuccess: true });
        this.props.history.replace("/members/order");
      })
      .catch((err) => {
        toast.error(err.message, { autoClose: 3000, position: "top-center" });
      });
  }

  getPaymentMethodList() {
    Order.getPaymentMethod()
      .then(method => {
        this.setState({
          paymentMethodList: method.data,
          paymentMethod: method.data[0].id,
          openPaymentModal: true,
        });
      })
      .catch(err => {
        toast.error('Cannot get payment method', {
          autoClose: 3000,
          position: 'top-center'
        })
      });
  }

  changePaymentMethod(method) {
    this.setState({
      paymentMethod: method,
    });
  }

  makePayment() {
    this.setState({
      loadingPayment: true,
    });
    const paymentForm = new FormData();
    paymentForm.append('order_ref', this.state.refno);
    paymentForm.append('payment_method', this.state.paymentMethod);
    Order.makePayment(paymentForm)
      .then((res) => {
        this.setState({
          loadingPayment: false,
        });
        if (res.data.payment_type === "Kiple Pay") {
          this.setState(
            {
              paymentUrl: res.data.payment_redirect.url,
              paymentData: res.data.payment_redirect.data,
            },
            () => {
              this.submitKipleBtn.click();
            }
          );
        } else if (res.data.payment_type === "IPay88") {
          this.setState(
            {
              paymentUrl: res.data.payment_redirect.url,
              paymentData: res.data.payment_redirect.data,
            },
            () => {
              this.submitIpayBtn.click();
            }
          );
        } else if (res.data.payment_type == "eGHL") {
          this.setState(
            {
              paymentUrl: res.data.payment_redirect.url,
              paymentData: res.data.payment_redirect.data,
            },
            () => {
              this.submitEghlBtn.click();
            }
          );
        } else if (res.data.payment_type === "Revenue Monster") {
          this.setState(
            {
              paymentUrl: res.data.payment_redirect.url,
              checkoutId: res.data.payment_redirect.checkoutId,
            },
            () => {
              this.submitRevenueMonsterBtn.click();
            }
          );
        } else if (res.data.payment_type === "PayMasterUPP") {
          window.location = res.data.payment_redirect.url;
        } else if (res.data.payment_type === "BankTransfer") {
          window.location.reload();
        }
      })
      .catch((err) => {
        this.setState({
          loadingPayment: false,
        });
        toast.error(err.message, { autoClose: 3000, position: "top-center" });
      });
  }

  getOrderDetail() {
    Order.getOrderDetail(this.state.refno)
      .then((res) => {
        console.log(res);
        this.setState(
          {
            orderDetails: res.data,
            shippingDetails: res.data.shipping,
            billingDetails: res.data.billing_info,
            paymentDetails: res.data.payment,
            orderList: res.data.order_detail,
          },
          () => {
            this.setState({
              isLoading: false,
            });
          }
        );
      })
      .catch((err) => {
        if (err.http_code == 404) {
          this.props.history.replace("/404-not-found");
        } else if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
        }
      });
  }

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        paySlipFile: file,
        paySlipUrl: reader.result,
      });
    };
    if (file) reader.readAsDataURL(file);
  };

  handleSubmit(e) {
    e.preventDefault();
    const slipData = new FormData();
    slipData.append("inputImage", this.state.paySlipFile);
    Order.uploadPaySlip(slipData, this.state.refno)
      .then((res) => {
        toast.success("Update Bank Transfer Reference Successful!", { autoClose: 3000 });
        window.location.reload();
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        }
        toast.error(err.message);
      });
  }

  orderReceived() {
    Order.orderReceived(this.state.refno)
      .then((res) => {
        toast.success("Order Received");
        this.props.history.replace("/members/order");
      })
      .catch((err) => {
        toast.error(err.message, { autoClose: 3000, position: "top-center" });
      });
  }

  getState(state) {
    for (let i = 0; i < stateArr.length; i++) {
      if (state == Object.keys(stateArr[i]).toString()) return Object.values(stateArr[i]).toString();
    }
  }

  renderCancelConfirmation = () => {
    const { openModal } = this.state;
    return (
      <Modal open={openModal} closeOnOverlayClick={false} onClose={() => this.setState({ openModal: false })} center>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content p-3">
            <h3 className="text-center" style={{ color: "black" }}>
              Confirmation
            </h3>
            <div className="modal-body text-center">
              <p>Are you sure you want to cancel this order?</p>

              <div className="mt-2 d-inline-flex">
                <button
                  className="btn btn-solid medium mr-2"
                  onClick={() => {
                    this.cancelOrder();
                  }}>
                  Confirm
                </button>
                <button className="btn btn-solid medium" onClick={() => this.setState({ openModal: false })}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderPayment = () => {
    const { openPaymentModal, paymentMethodList, paymentMethod } = this.state;
    return (
      <Modal open={openPaymentModal} closeOnOverlayClick={false} onClose={() => this.setState({ openPaymentModal: false })} center>
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content p-3">
            <h3 className="text-center" style={{ color: "black" }}>
              Make Payment
            </h3>
            <div className="modal-body text-center payment-box-popup">
              <div className="big-selection-list">
                <p className="mt-2">Please select the payment method</p>
                <ul style={{ display: "grid" }}>
                  {paymentMethodList.map((method) => {
                    return (
                      <li key={method.id} onClick={() => this.changePaymentMethod(method.id)}>
                        <div className="radio-option ">
                          <input
                            type="radio"
                            name="payment-group"
                            id={method.id}
                            checked={paymentMethod == method.id}
                            value={method.id}
                            onChange={this.changePaymentMethod.bind(this)}
                          />
                          <div>
                            <label htmlFor={method.name}>
                              {method.title}
                            </label>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-solid medium btn-solid-big"
                  disabled={this.state.loadingPayment}
                  onClick={() => {
                    this.makePayment();
                  }}>
                  Proceed
                </button>
                <button className="btn medium mt-2" onClick={() => this.setState({ openPaymentModal: false })}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  printInvoice() {
    const method = "GET";
    const type = "application/pdf";
    const token = this.props.token;
    const headers = new Headers({ token, type });
    const options = { method, headers };

    fetch(process.env.API_URL + process.env.API_PREFIX + "/order/export-invoice/" + this.state.refno, options)
      .then((response) => response.blob())
      .then((response) => {
        var blob = new Blob([response], { type });
        const obj = URL.createObjectURL(blob);
        this.setState({ blobObject: obj });
        window.open(obj, "_blank");
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        }
      });
  }

  renderOrderInfo() {
    const { orderDetails, orderList } = this.state;
    return (
      <div style={orderBox}>
        {/* {orderDetails.buyer && orderDetails.buyer.fname && orderDetails.buyer.id!==user.id? (
      <p style={{ marginBottom: "0px" }}>
        Purchased By : {orderDetails.buyer.fname}</p>
    ) : null} */}
        <p className="text-theme" style={{ marginBottom: "0px", fontSize: "13px" }}>
          Order #{orderDetails.refno}
        </p>
        <div className="form-row">
          <div className="col-lg-6">
            <p style={{ fontSize: "10px", marginBottom: "8px" }}>Placed on {orderDetails.cdate}</p>
          </div>
          <div className="col-lg-6">
            {
              orderDetails.customer ? <>
                <span className="badge bg-primary orderlist-tag ml-2" style={{float: "right"}}>Customer</span>
              </> : null
            }
            <span
              style={{ float: "right", color: "white", padding: "5px" }}
              className={getBadge(orderDetails.status.id) + " mb-2"}>
              {orderDetails.status.id == "1" ? "Pending Payment" : orderDetails.status.name}
            </span>
          </div>
        </div>
        <hr style={{ marginBottom: "3px", marginTop: "0px" }} />
        <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
        {orderList.map((order, index) => (
          <Fragment key={index}>
            <div className="order-list row">
              <div className="col-lg-5 col-md-5 col-12">
                <p>
                  {order.pname}{" "}
                  {order.combo ? (
                    <div className="combo-list order">
                      {order.combo.map((comboItem, index) => {
                        return <p key={index}>- {comboItem}</p>;
                      })}
                    </div>
                  ) : null}
                  {order.attribute1 ? "(" + order.attribute1 + (order.attribute2 ? "+ " + order.attribute2 : "") : null}
                  {order.attribute1 ? ")" : null}
                </p>
              </div>
              {order.pprice === "0.00" || order.pprice === order.rprice ? (
                <div className="col-lg-2 col-md-2 col-4">
                  <p>RM {order.unit_price}</p>
                </div>
              ) : (
                <div className="col-lg-2 col-md-2 col-4">
                  <p>
                    <span>RM {order.pprice}</span>
                    <br />
                    <strike style={{ fontSize: 12, color: "#5f5f5f" }}>RM {order.rprice}</strike>
                  </p>
                </div>
              )}
              <div className="col-lg-2 col-md-2 col-4">
                <p>
                  <span style={{ color: "#bfbfbf" }}>Qty:</span> {order.qty}
                </p>
              </div>
              <div className="col-lg-3 col-md-3 col-4">
                <p>RM {order.sub_total}</p>
              </div>
            </div>
            {/* {order.check_review == 0 && orderDetails.status.id == 4 ? (
              <div className="text-right mb-2">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/members/review-product/${order.id}`,
                    state: { refno: orderDetails.refno, product: order },
                  }}>
                  <button className="btn btn-outline-info small">Review</button>
                </Link>
              </div>
            ) : null} */}
            <hr style={{ marginBottom: "10px", marginTop: "0px" }} />
          </Fragment>
        ))}
      </div>
    );
  }

  renderAddressInfo() {
    const { shippingDetails, billingDetails } = this.state;
    return (
      <>
        <div className="col-lg-6">
          <div style={orderBox}>
            <h4 style={{ fontSize: "15px" }}>Shipping Address</h4>
            <p style={addLbl}>
              {shippingDetails.shipping_fname} {shippingDetails.shipping_lname}
            </p>
            <p style={addLbl}>{shippingDetails.shipping_mobile_no}</p>
            <hr style={{ marginBottom: "5px", marginTop: "8px" }} />
            <p style={addLbl}>
              {shippingDetails.shipping_street1},{" "}
              {shippingDetails.street2 && shippingDetails.street2.length > 0 ? shippingDetails.street2 + "," : ""}{" "}
              {shippingDetails.shipping_city}, {shippingDetails.shipping_zip}, {this.getState(shippingDetails.shipping_state)}
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <div style={orderBox}>
            <h4 style={{ fontSize: "15px" }}>Billing Address</h4>
            <p style={addLbl}>
              {billingDetails.fname} {billingDetails.lname}
            </p>
            <p style={addLbl}>{billingDetails.mobile_no}</p>
            <hr style={{ marginBottom: "5px", marginTop: "8px" }} />
            <p style={addLbl}>
              {billingDetails.street1},{" "}
              {billingDetails.street2 && billingDetails.street2.length > 0 ? billingDetails.street2 + "," : ""}{" "}
              {billingDetails.city}, {billingDetails.zip}, {this.getState(billingDetails.state)}
            </p>
          </div>
        </div>
      </>
    );
  }

  renderOrderSummary() {
    const { orderDetails, paymentDetails } = this.state;
    return (
      <div className="col-lg-6">
        <div style={orderBox}>
          <h4 style={{ fontSize: "15px" }}>Total Summary</h4>
          <div className="form-row">
            <div className="col-lg-5">
              <p style={addLbl}>Subtotal</p>
            </div>
            <div className="col-lg-7">
              <p style={pymtPriceLbl}>RM {paymentDetails.subtotal}</p>
            </div>
          </div>
          {paymentDetails.total_discount ? (
            <div className="form-row">
              <div className="col-lg-5">
                <p style={addLbl}>Total Discount</p>
              </div>
              <div className="col-lg-7">
                <p style={pymtPriceLbl}>RM {paymentDetails.total_discount}</p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="form-row">
            <div className="col-lg-5">
              <p style={addLbl}>Shipping Fees</p>
            </div>
            <div className="col-lg-7">
              <p style={pymtPriceLbl}>RM {orderDetails.shipping_info.shipping_fee}</p>
            </div>
          </div>
          <hr style={{ marginBottom: "15px", marginTop: "8px" }} />
          <div className="form-row">
            <div className="col-lg-5">
              <p style={addLbl}>Total</p>
            </div>
            <div className="col-lg-7">
              <p className="text-theme" style={totalPriceLbl}>
                RM {paymentDetails.total_price}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { orderDetails, paymentDetails, paySlipUrl, cancelSuccess, bankAccName, bankAccNo, bankName, windowWidth, checkoutId, paymentData } = this.state;
    const { user, isDealer } = this.props;
    return (
      <div>
        {this.renderCancelConfirmation()}
        {this.renderPayment()}
        <Breadcrumb title={"Orders"} />
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab={"order"} />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    {/* <div style={{ position: "absolute", right: 40 }}>
                      <i
                        hidden={!orderDetails.invoice && orderDetails.invoice !== 1}
                        className="fa fa-print text-info pr-2 pl-2"
                        onClick={() => this.printInvoice()}
                        style={{ fontSize: 22, cursor: "pointer" }}
                      />
                    </div> */}
                    <div className="page-title" style={{ marginBottom: "20px" }}>
                      <h2>Order Detail</h2>
                    </div>

                    {this.state.isLoading && orderDetails ? (
                      <div className="loading-cls" />
                    ) : (
                      <Fragment>
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "left" }}>
                            <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/order`}>
                              <i className="fa fa-chevron-left" />
                              &nbsp; Back
                            </Link>
                          </div>
                          {orderDetails.status.id === "2" || orderDetails.status.id === "7" || orderDetails.status.id === "3" ?
                            <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "right" }}>
                              <button
                                style={uploadbtn}
                                type="button"
                                onClick={() => this.orderReceived()}
                                className="btn btn-solid">
                                Order Received
                              </button>
                              {/* <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/order`}>
                                <i className="fa fa-chevron-left" />
                                &nbsp; Back
                              </Link> */}
                            </div>
                            : null}
                          {isDealer ? (
                            <div className="col-6 text-right">
                              {orderDetails.buyer && orderDetails.buyer.id == 0 ? (
                                <span className="badge bg-primary orderlist-tag ml-2">One Time Customer</span>
                              ) : orderDetails.buyer && orderDetails.buyer.id && orderDetails.buyer.id !== user.id ? (
                                <span className="badge bg-primary orderlist-tag ml-2">Downline</span>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          {this.renderOrderInfo()}
                          <div className="form-row">
                            {paymentDetails.payment_type == "BankTransfer" &&
                              orderDetails.status.id !== "5" &&
                              orderDetails.status.id !== "11" ? (
                              <div className="col-lg-6">
                                <div className="theme-border-box p-3 mb-3" >
                                  <form onSubmit={(e) => this.handleSubmit(e)}>
                                    <div>
                                      <h4 style={{ fontSize: "15px" }}>Bank Transfer Reference</h4>
                                      {orderDetails.payslip && orderDetails.status.id !== "1" && orderDetails.status.id !== "2" ? (
                                        <Fragment>
                                          <p>
                                            This order bank transfer reference have been uploaded, your order will be process
                                            soon.
                                          </p>
                                          <div style={{ width: "100%" }}>
                                            <img
                                              style={{ border: "1px solid #ddd", height: "200px" }}
                                              alt="pay-slip"
                                              src={orderDetails.payslip}
                                            />
                                          </div>
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          {orderDetails.payslip && (orderDetails.status.id === "1" || orderDetails.status.id === "2") ? (
                                            <Fragment>
                                              <div className="form-row">
                                                <p style={{ marginLeft: "6px", marginTop: "10px", marginBottom: "10px" }}>
                                                  You may upload again if needed.
                                                </p>
                                              </div>
                                              <div>
                                                <p style={{ color: "#222222", marginBottom: "3px" }}>{bankName}</p>
                                                <p style={{ color: "#222222", marginBottom: "3px" }}>{bankAccName}</p>
                                                <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccNo}</p>
                                                <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                                                <img
                                                  style={{ border: "1px solid #ddd", height: "200px" }}
                                                  alt="pay-slip"
                                                  src={orderDetails.payslip}
                                                />
                                                <input
                                                  ref={(inputRef2) => (this.inputRef2 = inputRef2)}
                                                  accept="image/png,image/jpeg,image/jpg"
                                                  type="file"
                                                  onChange={(e) => this.handleImageChange(e)}
                                                />
                                                <button
                                                  style={uploadbtn}
                                                  type="button"
                                                  onClick={() => this.inputRef2.click()}
                                                  className="btn btn-solid mr-2">
                                                  Upload Payslip
                                                </button>
                                              </div>
                                              <button style={uploadbtn} type="submit" className="btn btn-solid">
                                                Confirm Upload
                                              </button>
                                            </Fragment>
                                          ) : (
                                            <Fragment>
                                              <p style={{ color: "#222222", marginBottom: "3px" }}>{bankName}</p>
                                              <p style={{ color: "#222222", marginBottom: "3px" }}>{bankAccName}</p>
                                              <p style={{ color: "#222222", marginBottom: "8px" }}>{bankAccNo}</p>
                                              <hr style={{ marginBottom: "10px", marginTop: "10px" }}></hr>
                                              <div className="form-row">
                                                <input
                                                  hidden
                                                  ref={(inputRef1) => (this.inputRef1 = inputRef1)}
                                                  accept="image/png,image/jpeg,image/jpg"
                                                  style={{ marginLeft: "5px", marginBottom: "5px" }}
                                                  type="file"
                                                  onChange={(e) => this.handleImageChange(e)}
                                                />
                                              </div>
                                              <div style={{ width: "100%" }}>
                                                {paySlipUrl ? (
                                                  <img
                                                    style={{ border: "1px solid #ddd", height: "200px" }}
                                                    alt="pay-slip"
                                                    src={paySlipUrl}
                                                  />
                                                ) : null}
                                              </div>
                                              <button
                                                style={uploadbtn}
                                                type="button"
                                                onClick={() => this.inputRef1.click()}
                                                className="btn btn-solid mr-2">
                                                Upload Payslip
                                              </button>
                                              <button
                                                hidden={!paySlipUrl}
                                                style={uploadbtn}
                                                type="submit"
                                                className="btn btn-solid">
                                                Confirm Upload
                                              </button>
                                            </Fragment>
                                          )}
                                        </Fragment>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            ) : null}
                            {this.renderOrderSummary()}
                            {this.renderAddressInfo()}
                            {
                              orderDetails.awb_file_url ? (
                                <div className="col-lg-6">
                                  <div style={orderBox} className="pb-1">
                                    <h4 style={{ fontSize: "15px" }}>Air Waybill</h4>
                                    {
                                      orderDetails.awb_mimetype == 'application/pdf' ?
                                        <a href={orderDetails.awb_file_url} target="_blank">AirWaybill.pdf</a> :
                                        <img src={orderDetails.awb_file_url} style={{ maxWidth: "85%", height: "auto" }} />
                                    }
                                  </div>
                                </div>
                              ) : null
                            }

                            {orderDetails.tracking_code ? (
                              <div className="col-lg-6">
                                <div style={orderBox} className="pb-1">
                                  <h4 style={{ fontSize: "15px" }}>Delivery Info</h4>
                                  <p style={addLbl}>Shipping Agent : {orderDetails.shipping_agent}</p>
                                  <p style={addLbl}>Tracking Number : {orderDetails.tracking_code}</p>
                                  <div className="text-right">
                                    <button
                                      className="btn btn-solid small m-2 mb-0"
                                      onClick={() => window.linkTrack(orderDetails.tracking_code)}>
                                      Track
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {

                            }

                          </div>
                        </div>
                        <div className="d-inline-flex">
                          {
                            orderDetails.status.id === "1" ?
                              (
                                <div style={{ textAlign: "right" }}>
                                  <button
                                    style={cancelOrd}
                                    onClick={() => this.getPaymentMethodList()}
                                    className="btn btn-solid medium mr-2">
                                    Make Payment
                            </button>
                                </div>
                              ) : ("")
                          }
                          {orderDetails.status.id !== "1" &&
                            orderDetails.status.id !== "2" &&
                            orderDetails.status.id !== "7" &&
                            !cancelSuccess ? (
                            ""
                          ) : (
                            <div style={{ textAlign: "right" }}>
                              <button
                                style={cancelOrd}
                                onClick={() => this.setState({ openModal: true })}
                                className="btn theme-border-box text-theme">
                                Cancel Order
                            </button>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* pass Kiple payment use */}
        <form method="post" action={this.state.paymentUrl}>
          <input type="hidden" name="ord_mercID" value={paymentData.ord_mercID} />
          <input type="hidden" name="ord_date" value={paymentData.ord_date} />
          <input type="hidden" name="ord_totalamt" value={paymentData.ord_totalamt} />
          <input type="hidden" name="ord_gstamt" value={paymentData.ord_gstamt} />
          <input type="hidden" name="ord_shipname" value={paymentData.ord_shipname} />
          <input type="hidden" name="ord_shipcountry" value={paymentData.ord_shipcountry} />
          <input type="hidden" name="ord_mercref" value={paymentData.ord_mercref} />
          <input type="hidden" name="ord_telephone" value={paymentData.ord_telephone} />
          <input type="hidden" name="ord_email" value={paymentData.ord_email} />
          <input type="hidden" name="ord_delcharges" value={paymentData.ord_delcharges} />
          <input type="hidden" name="ord_svccharges" value={paymentData.ord_svccharges} />
          <input type="hidden" name="ord_customfield1" value={paymentData.ord_customfield1} />
          <input type="hidden" name="ord_customfield2" value={paymentData.ord_customfield2} />
          <input type="hidden" name="ord_returnURL" value={paymentData.ord_returnURL} />
          <input type="hidden" name="merchant_hashvalue" value={paymentData.merchant_hashvalue} />
          <input hidden ref={(btn) => (this.submitKipleBtn = btn)} type="submit" value="Submit" />
        </form>
        {/* pass IPay payment use */}
        <form method="post" action={this.state.paymentUrl}>
          <input type="hidden" name="MerchantCode" value={paymentData.MerchantCode} />
          <input type="hidden" name="PaymentId" value={paymentData.PaymentId} />
          <input type="hidden" name="RefNo" value={paymentData.RefNo} />
          <input type="hidden" name="Amount" value={paymentData.Amount} />
          <input type="hidden" name="Currency" value={paymentData.Currency} />
          <input type="hidden" name="ProdDesc" value={paymentData.ProdDesc} />
          <input type="hidden" name="UserName" value={paymentData.UserName} />
          <input type="hidden" name="UserEmail" value={paymentData.UserEmail} />
          <input type="hidden" name="UserContact" value={paymentData.UserContact} />
          <input type="hidden" name="Remark" value={paymentData.Remark} />
          <input type="hidden" name="Lang" value={paymentData.Lang} />
          <input type="hidden" name="SignatureType" value={paymentData.SignatureType} />
          <input type="hidden" name="Signature" value={paymentData.Signature} />
          <input type="hidden" name="ResponseURL" value={paymentData.ResponseURL} />
          <input type="hidden" name="BackendURL" value={paymentData.BackendURL} />
          <input hidden ref={(btn) => (this.submitIpayBtn = btn)} type="submit" value="Submit" />
        </form>
        {/* pass eGHL payment use */}
        <form method="post" action={this.state.paymentUrl}>
          <input type="hidden" name="TransactionType" value={paymentData.TransactionType} />
          <input type="hidden" name="PymtMethod" value={paymentData.PymtMethod} />
          <input type="hidden" name="ServiceID" value={paymentData.ServiceID} />
          <input type="hidden" name="PaymentID" value={paymentData.PaymentID} />
          <input type="hidden" name="OrderNumber" value={paymentData.OrderNumber} />
          <input type="hidden" name="PaymentDesc" value={paymentData.PaymentDesc} />
          <input type="hidden" name="MerchantReturnURL" value={paymentData.MerchantReturnURL} />
          <input type="hidden" name="Amount" value={paymentData.Amount} />
          <input type="hidden" name="CurrencyCode" value={paymentData.CurrencyCode} />
          <input type="hidden" name="HashValue" value={paymentData.HashValue} />
          <input type="hidden" name="CustIp" value={paymentData.CustIp} />
          <input type="hidden" name="CustName" value={paymentData.CustName} />
          <input type="hidden" name="CustEmail" value={paymentData.CustEmail} />
          <input type="hidden" name="CustPhone" value={paymentData.CustPhone} />
          <input type="hidden" name="MerchantCallBackURL" value={paymentData.MerchantCallBackURL} />
          <input hidden ref={(btn) => (this.submitEghlBtn = btn)} type="submit" value="Submit" />
        </form>
        {/* pass RM payment use */}
        <form method="get" action={this.state.paymentUrl}>
          <input type="hidden" name="checkoutId" value={checkoutId} />
          <input hidden ref={(btn) => (this.submitRevenueMonsterBtn = btn)} type="submit" value="Submit" />
        </form>
      </div>
    );
  }
}

const orderBox = {
  border: "1px solid #ddd",
  padding: "15px",
  marginBottom: "18px",
};

const uploadbtn = {
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "5px",
  paddingBottom: "5px",
  fontSize: "10px",
  marginTop: "10px",
};

const cancelOrd = {
  backgroundColor: "white",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "5px",
  paddingBottom: "5px",
  fontSize: "10px",
};

const addLbl = {
  marginBottom: "3px",
};

const pymtPriceLbl = {
  fontWeight: "bold",
  float: "right",
  marginBottom: "0px",
};

const totalPriceLbl = {
  fontWeight: "bold",
  float: "right",
  marginBottom: "3px",
  fontSize: "20px",
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
