import React, { Component } from 'react';
import noImg from "../../../../assets/images/defaultProduct.jpg";

export default class ImageZoom extends Component {
    render() {
        const {image} = this.props;

        return (
            <img 
                ref={img => this.img = img}
                onError={() => this.img.src = noImg}
                style={imgStyle}
                src={`${image}`}  className="img-fluid image_zoom_cls-0" />
        );
    }
}

const imgStyle={
    minWidth: '100%',
    height: 'auto',
    objectFit:'contain',
    maxHeight:'600px'
}