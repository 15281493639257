import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { addToCart,updateToken } from "../../actions/index";
import ProductItem from "../products/product-item";
import * as PRODUCTS from "../../api/product";
import { convertToCartItem } from "../../services/index";
import loading from "../../assets/images/loader.gif";

class Categories2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      productList: [],
      tab: [0,1,2],
      currentTab: 0,
      currentPage: 1,
      moreLoading: false,
      noResult:false,
      categories:[],
      hide:false
    };
  }

  componentWillMount() {
    PRODUCTS.getCategoryList().then((res) => {
      if(res.data.length>=3){
        this.setState({
          categories: res.data.slice(0,3),
        },()=>{
          this.getCategoryList();
        });
      }else{
        this.setState({
          hide: true,
        })
      }
    });
  }

  getCategoryList() {
    let tab = this.state.currentTab;
    let params = {
      per_page: 12,
      page: this.state.currentPage,
      sort_type: "desc",
      sort_by: "id",
      include: "child"
    };
    if (tab == 0) params.id = this.state.categories[0].id;
    else if (tab == 1) params.id = this.state.categories[1].id;
    else params.id=this.state.categories[2].id;

    PRODUCTS.getCategoryProductList(params)
    .then(res => {
      if(res.data){
        this.setState(
          {
            productList: this.state.currentPage == 1 ? res.data? res.data:[] : [...this.state.productList, ...res.data],
            totalPage: res.pagination.total_page
          },
          () => {
            this.setState({
              noResult: false,
              isLoading: false,
              moreLoading: false
            });
          }
        );
      }else{
        this.setState({
          noResult: true,
          isLoading: false,
          moreLoading: false
        });
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  fetchMore() {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
        moreLoading: true
      },
      () => {
        this.getCategoryList();
      }
    );
  }

  changeTab(tab) {
    if (tab !== this.state.currentTab) {
      this.setState({ currentTab: tab, currentPage: 1, productList: [] }, () => {
        this.getCategoryList();
      });
    }
  }

  viewMoreContent = () => {
    return (
      <>
        {this.state.moreLoading ? (
          <div className="container" style={{ textAlign: "center" }}>
            <img src={loading} style={loaderStyle} alt="" />
          </div>
        ) :
        this.state.currentPage !== this.state.totalPage ? (
          <div className="container text-theme" style={getMoreStyle} onClick={() => this.fetchMore()}>
            <b>
              view more <i className="fa fa-angle-double-down" aria-hidden="true" />
            </b>
          </div>
        ) : null}
      </>
    );
  };

  render() {
    const { productList,categories,noResult,hide } = this.state;
    const { addToCart, title, subtitle, symbol,history } = this.props;
    return (
      hide?
      <div></div>
      :
      <div>
        <div className="title1" style={sectionBox}>
          {subtitle ? <h4>{subtitle}</h4> : ""}
          <h2  className="title-inner1">
            {title}
          </h2>
        </div>
        {this.state.isLoading && productList ? (
          <div className="loading-cls" />
        ) : (
          <section className="section-b-space addtocart_count" style={{paddingTop:0}}>
            <div className="container">
              <Tabs className="theme-tab">
                <TabList className="tabs tab-title">
                  <Tab onClick={() => this.changeTab(0)}>{categories[0].name}</Tab><span> | </span>
                  <Tab onClick={() => this.changeTab(1)}>{categories[1].name}</Tab><span> | </span>
                  <Tab onClick={() => this.changeTab(2)}>{categories[2].name}</Tab>
                </TabList>
                <TabPanel>
                  <div className="no-slider row">
                    {productList.map((product, index) => (
                      <ProductItem
                        product={product}
                        updateToken={updateToken}
                        symbol={symbol}
                        history={history}
                        onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1)}
                        key={index}
                      />
                    ))}
                    {productList.length == 0 ? (
                      noResult?
                      <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                      :
                      <div className="container" style={loadingContainer}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : (
                      this.viewMoreContent()
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="no-slider row">
                    {productList.map((product, index) => (
                      <ProductItem
                        product={product}
                        updateToken={updateToken}
                        history={history}
                        symbol={symbol}
                        onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1)}
                        key={index}
                      />
                    ))}
                    {productList.length == 0 ? (
                      noResult?
                      <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                      :
                      <div className="container" style={loadingContainer}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : (
                      this.viewMoreContent()
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className=" no-slider row">
                    {productList.map((product, index) => (
                      <ProductItem
                        product={product}
                        updateToken={updateToken}
                        history={history}
                        symbol={symbol}
                        onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1)}
                        key={index}
                      />
                    ))}
                    {productList.length == 0 ? (
                      noResult?
                      <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                      :
                      <div className="container" style={loadingContainer}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : (
                      this.viewMoreContent()
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const loadingContainer = {
  textAlign: "center",
  minHeight:'400px',
  justifyContent:'center'
}

const sectionBox = {
  paddingTop: 0
};

const loaderStyle = {
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  marginTop:'200px'
};

const getMoreStyle = {
  textAlign: "center",
  fontSize: 20,
  cursor: "pointer",
};

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol
  };
};

export default connect(
  mapStateToProps,
  { updateToken,addToCart }
)(Categories2);
