import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import CartPage from '../components/common/headers/common/cart-header'
import {removeFromCart} from '../actions'
import {getCartTotal} from '../services'

const CartContainer = ({symbol,cartList, total,windowWidth,removeFromCart,THIS}) => (
     <li  className="onhover-div pl-0 ml-4"><div className="cart-qty-cls">{cartList.length}</div>
            {windowWidth<=700?
             <img src={`${process.env.PUBLIC_URL}/assets/images/icon/cart.png`} className="img-fluid" alt=""/>
            :
            <Link to={`${process.env.PUBLIC_URL}/cart`}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/cart.png`} className="img-fluid" alt=""/>
            </Link>
            }
          
        <ul className="show-div shopping-cart">
            { cartList.map((item,index) => (
                <CartPage key={index} index={index} item={item} THIS={THIS} total={total} symbol={symbol} removeFromCart={() => removeFromCart(item,THIS.props)}  />
            ))}
            {(cartList.length > 0) ?
            <div style={{position:'sticky',bottom:0,zIndex:999,backgroundColor:'white',paddingBottom:'10px'}}>
            <li >
                <div className="total">
                    <h5>subtotal : <span>{symbol}{parseFloat(total).toFixed(2)}</span></h5>
                </div>
            </li>
            <li>
                <div className="buttons">
                    <Link to={`${process.env.PUBLIC_URL}/cart`} className="view-cart">
                        <button className="btn btn-solid" style={btnStyle}>view cart</button>
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/checkout`} className="checkout">
                    <button className="btn btn-solid" style={btnStyle}>checkout</button>
                    </Link>
                </div>
            </li></div>
                    :
            <li><h5>Your cart is currently empty.</h5></li>}
        </ul>

    </li>
)

const btnStyle = {
    padding:5,
    paddingLeft:8,
    paddingRight:8,
    fontSize:13,
    textTransform:'capitalize'
}

function mapStateToProps(state) {
    return {
        cartList: state.cartList.cart.sort(function compare(a, b) {
            var dateA = new Date(a.cdate);
            var dateB = new Date(b.cdate);
            return dateB - dateA;
          }),
        total: getCartTotal(state.cartList.cart),
        symbol: state.data.symbol,
    }
}

export default connect(mapStateToProps, {removeFromCart})(CartContainer);
