import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from "../../../api/profile";
import * as DEALER from "../../../api/dealer";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { updateToken, addMyCart } from "../../../actions/index";
import { connect } from "react-redux";
import * as AUTH from "../../../api/auth";
import * as GENERAL from "../../../api/general";
import SideMenu from "../sidemenu";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const stateArr = GENERAL.getStateList();
const now = new Date();
const defaultEnd = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
const defaultStart = moment(defaultEnd).subtract(1, "month");

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      lname: "",
      email: "",
      billId: "",
      billAdd: "",
      shipId: "",
      shipAdd: "",
      failMsg: "",
      updateErr: false,
      isOpen: false,
      billErr: "",
      shipErr: "",
      startDate: defaultStart,
      endDate: defaultEnd,
      loading: true,
      dashboardInfo: [],
      referralLink: "",
      point: null,
      eWallet: null,
    };
  }

  componentDidMount() {
    this.renderContent()
  }

  renderContent() {
    if (this.props.isDealer) {
      this.getMyDashboard();
    } else {
      this.getBillingAddress();
      this.getShippingAddress();
      this.getProfileInfo();
    }
  }

  handleShare = () => {
    if (navigator.canShare && navigator.canShare({ url: this.state.referralLink })) {
      navigator.share({
        url: this.state.referralLink,
        title: 'Share',
        text: 'Share to others.',
      })
        .then(() => console.log('Share was successful.'))
        .catch((error) => console.log('Sharing failed', error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  getMyDashboard() {
    let params = {
      start_date: moment(this.state.startDate).format("YYYY-MM-DD"),
      end_date: moment(this.state.endDate).format("YYYY-MM-DD"),
    };
    DEALER.getDashboard(params)
      .then((res) => {
        this.getProfileInfo();
        if (res.data) {
          this.setState({
            dashboardInfo: res.data,
            sales: res.data.sales,
            commission: res.data.commission,
            salesVolume: res.data.sales_volume,
            cancelledSales: res.data.cancelled_sales,
            agentRanking: res.data.agent_ranking,
            downline: res.data.downline,
            loading: false,
            referralLink: res.data.referral_link ? res.data.referral_link : null,
          });
        } else {
          this.setState({
            dashboardInfo: null,
            loading: false,
          });
        }
      })
      .catch((err) => {
        if (err.http_code === 401 || (err.http_code === 403 && err.message === "token expired")) {
          this.tokenExpiredHandle();
        }
      });
  }

  getProfileInfo() {
    Profile.getProfile()
      .then((res) => {
        this.setState(
          {
            fname: res.data.fname,
            lname: res.data.lname,
            email: res.data.email,
            role: res.data.dealer ? res.data.dealer : 0,
            point: res.data.point ? res.data.point : null,
            eWallet: res.data.ewallet ? res.data.ewallet : null,
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .catch((err) => {
        if (err.http_code === 401 || (err.http_code === 403 && err.message === "token expired")) {
          this.tokenExpiredHandle();
        }
      });
  }

  tokenExpiredHandle() {
    AUTH.logout();
    toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
    this.props.history.replace("/login");
  }

  getBillingAddress() {
    Profile.getDefaultBillingAddress()
      .then((res) => {
        if (!res.errors) {
          this.setState({
            billAdd:
              res.data.street1 +
              ", " +
              (res.data.street2.length > 0 ? res.data.street2 + ", " : "") +
              res.data.city +
              ", " +
              res.data.zip +
              ", " +
              this.getState(res.data.state),
            billId: res.data.id,
          });
        } else {
          //nodata
          this.setState({
            billErr: "You have not set a default billing address.",
          });
        }
      })
      .catch((err) => { });
  }

  getShippingAddress() {
    Profile.getDefaultShippingAddress()
      .then((res) => {
        if (!res.errors) {
          this.setState({
            shipAdd:
              res.data.street1 +
              ", " +
              (res.data.street2.length > 0 ? res.data.street2 + ", " : "") +
              res.data.city +
              ", " +
              res.data.zip +
              ", " +
              this.getState(res.data.state),
            shipId: res.data.id,
          });
        } else {
          //nodata
          this.setState({
            shipErr: "You have not set a default shipping address.",
          });
        }
      })
      .catch((err) => { });
  }

  getState(state) {
    for (let i = 0; i < stateArr.length; i++) {
      if (state == Object.keys(stateArr[i]).toString()) return Object.values(stateArr[i]).toString();
    }
  }

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  }

  filterList() {
    this.setState({ currentPage: 1, filterLoading: true, agentRanking: [] }, () => {
      this.getMyDashboard();
    });
  }

  renderDealerDashboard() {
    const { symbol } = this.props;
    const { dashboardInfo, salesVolume, sales, commission, cancelledSales, agentRanking, startDate, endDate, referralLink, point, eWallet } = this.state;
    const canShare = navigator.canShare;
    return (
      <div className="dashboard">
        <div>
          {referralLink && referralLink.length > 0 ? (
            canShare ? (
              <>
                <h5>My Referral Link</h5>
                <div className="form-row">
                  <input className="form-control col-12 col-md-7" disabled value={referralLink} />
                  <button onClick={this.handleShare} className="btn btn-primary medium ml-0 col-12 col-md-5">Share My Referral Link</button>
                </div>
                <hr />
              </>
            ) : (
              <>
                <h5>My Referral Link</h5>
                <div className="form-row">
                  <input className="form-control col-12 col-md-7" disabled value={referralLink} />
                  <CopyToClipboard text={referralLink} onCopy={() => toast.success("Link has been copied to clipboard", { autoClose: 3000, position: "top-center" })}>
                    <button className="btn btn-primary medium ml-0 col-12 col-md-5">Share My Referral Link</button>
                  </CopyToClipboard>
                </div>
                <hr />
              </>
            )) : null}
        </div>
        <div className="row">
          {
            eWallet ? (
              <div className="col-md-6">
                <h4 className="h5 mb-3">My Credit: {eWallet}</h4>
              </div>
            ) : null
          }
        </div>
        <div className="page-title">
          <div className="mb-1 pb-3">
            <h4 className="h5 mb-3">Today Performance</h4>
            <div className="row dashboard-row">
              <div className=" col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{symbol + " " + salesVolume.today}</h4>
                  <div>Sales</div>
                  {parseFloat(salesVolume.percent) == 0.0 ? null : parseFloat(salesVolume.percent) > 0 ? (
                    <small>
                      <span className="text-success font-weight-bold">+{salesVolume.percent}%</span> vs yesterday
                    </small>
                  ) : (
                    <small>
                      <span className="text-danger font-weight-bold">{salesVolume.percent}%</span> vs yesterday
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{symbol + " " + commission.today}</h4>
                  <div>Commission</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{sales.today}</h4>
                  <div>Orders</div>
                  {parseFloat(sales.percent) == 0.0 ? null : parseFloat(sales.percent) > 0 ? (
                    <small>
                      <span className="text-success font-weight-bold">+{sales.percent}%</span> vs yesterday
                    </small>
                  ) : (
                    <small>
                      <span className="text-danger font-weight-bold">{sales.percent}%</span> vs yesterday
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{cancelledSales.today}</h4>
                  <div>Cancelled</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-1 pb-3">
            <h4 className="h5 mb-3">Total Commission</h4>
            <div className="row dashboard-row">
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{symbol + " " + commission.last_7_days}</h4>
                  <div>Last 7 Days</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{symbol + " " + commission.last_30_days}</h4>
                  <div>Last 30 Days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-1 pb-3">
            <h4 className="h5 mb-3">Total Revenues</h4>
            <div className="row dashboard-row">
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{symbol + " " + salesVolume.last_7_days}</h4>
                  <div>Last 7 Days</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{symbol + " " + salesVolume.last_30_days}</h4>
                  <div>Last 30 Days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 pb-3">
            <h4 className="h5 mb-3">Orders</h4>
            <div className="row dashboard-row">
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{sales.last_7_days}</h4>
                  <div>Last 7 Days</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{sales.last_30_days}</h4>
                  <div>Last 30 Days</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <div className="card dashboard-col">
                  <h4>{cancelledSales.last_30_days}</h4>
                  <small>
                    Cancelled in
                    <br /> Last 30 Days
                  </small>
                </div>
              </div>
            </div>
          </div>
          {dashboardInfo.downline.total > 0 ? (
            <div className="mb-4 pb-3">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <h4 className="h5">Top Agent</h4>
                </div>
                <div className="col-lg-9 top-agent-filter">
                  <div>Date :</div>
                  <DateRangePicker
                    onApply={() => this.filterList()}
                    onEvent={(e, picker) => this.handleEvent(e, picker)}
                    startDate={startDate}
                    endDate={endDate}>
                    <input
                      className="dateRange-select-box c-pointer"
                      readOnly
                      value={startDate.format("DD-MM-YYYY") + "   to   " + endDate.format("DD-MM-YYYY")}
                    />
                  </DateRangePicker>
                  {dashboardInfo.downline && dashboardInfo.downline.total ? (
                    <div>Total Agent : {dashboardInfo.downline.total}</div>
                  ) : null}
                </div>
              </div>
              <br />
              <div style={{ overflowX: "auto" }}>
                <table className="table col-12 mb-0 text-center">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Seller</th>
                      <th scope="col">Commission (RM)</th>
                      <th scope="col">Revenues (RM)</th>
                      <th scope="col">Orders</th>
                      <th scope="col">Cancelled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentRanking && agentRanking.length > 0
                      ? agentRanking.map((agent, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{agent.name}</td>
                            <td>{agent.total}</td>
                            <td>{agent.sales_volume}</td>
                            <td>{agent.sales}</td>
                            <td>{agent.cancelled_sales}</td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </table>
                {agentRanking.length == 0 ? (
                  <div className="text-center mt-3">
                    <p>No Record Found</p>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderNormalDashboard() {
    const { billErr, shipErr } = this.state;
    return (
      <div className="dashboard">
        <div className="page-title">
          <h2>My Dashboard</h2>
        </div>
        <div className="welcome-msg">
          <p style={{ marginBottom: "2px" }}>Welcome back, {this.state.lname} !</p>
          <p>
            From your My Account Dashboard you have the ability to view a snapshot of your recent account activity and update your
            account information. Select a link below to view or edit information.
          </p>
        </div>
        <div className="box-account box-info">
          <div className="box-head">
            <h2>Account Information</h2>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="box">
                <div className="box-title">
                  <h3>Contact Information</h3>
                  <Link to={`${process.env.PUBLIC_URL}/members/account`}>Edit</Link>
                </div>
                <div className="box-content">
                  <h6>
                    {this.state.fname} {this.state.lname}
                  </h6>
                  <h6>{this.state.email}</h6>
                  <h6>
                    <Link to={`${process.env.PUBLIC_URL}/members/edit-pw`}>Edit Password</Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="box">
              <div className="box-title">
                <h3>Address Book</h3>
                <Link to={`${process.env.PUBLIC_URL}/members/address`}>Manage Addresses</Link>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h6>Default Billing Address</h6>
                  {billErr ? (
                    <Fragment>{billErr}</Fragment>
                  ) : (
                    <address>
                      {this.state.billAdd}
                      <br />
                      <Link to={`${process.env.PUBLIC_URL}/members/address-edit/${this.state.billId}`}>Edit Address</Link>
                    </address>
                  )}
                </div>
                <div className="col-sm-6">
                  <h6>Default Shipping Address</h6>
                  {shipErr ? (
                    <Fragment>{shipErr}</Fragment>
                  ) : (
                    <address>
                      {billErr}
                      {this.state.shipAdd}
                      <br />
                      <Link to={`${process.env.PUBLIC_URL}/members/address-edit/${this.state.shipId}`}>Edit Address</Link>
                    </address>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, } = this.state;
    const { isDealer } = this.props;

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab={"dashboard"} />
              </div>
              <div className="col-lg-9">
                {loading ? (
                  <div className="loading-cls" />
                ) : (
                  <div className="dashboard-right">
                    {isDealer ? this.renderDealerDashboard() : this.renderNormalDashboard()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
    symbol: state.data.symbol,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
