import moment from "moment";

export const convertToCartItem = (product, fromDatabase) => {

  function returnNormalPrice(product) {
    if (product.price.rprice > 0) {
      return parseFloat(
        product.is_promotion == 1 && product.price.pprice.replace(",", "") > 0
          ? product.price.pprice.replace(",", "")
          : product.price.rprice.replace(",", "")
      ).toFixed(2);
    } else {
      return 0;
    }
  }

  function returnDealerPrice(product) {
    return parseFloat(
      product.dealer_price.unit_price ? product.dealer_price.unit_price.replace(",", "") : product.dealer_price.replace(",", "")
    ).toFixed(2);
  }

  function getFinalPrice(oriPrice) {
    let extraPrice = 0;
    if (product.selected_pov) {
      extraPrice = parseFloat(product.selected_pov.extra_price);
    }
    return parseFloat(oriPrice) + parseFloat(extraPrice);
  }

  let oriPrice = 0;
  oriPrice =
    product.dealer_price && (parseFloat(product.dealer_price) > 0 || parseFloat(product.dealer_price.unit_price) > 0)
      ? returnDealerPrice(product)
      : returnNormalPrice(product);

  let total = getFinalPrice(oriPrice)
  let cartQty = product.qty ? parseInt(product.qty, 10) : 1;

  let extraPoint = product.selected_pov ? product.selected_pov.extra_point ? product.selected_pov.extra_point : 0 : 0;
  let finalPoint = product.point > 0 ? (product.point + extraPoint) : 0;
  let pointToEarn = product.selected_pov ? product.selected_pov.point_to_earn ? product.selected_pov.extra_point : 0 : 0;

  let cartItem = {
    pid: product.pid ? product.pid : product.id,
    name: product.name,
    slug: product.slug,
    buyable: fromDatabase ? product.buyable : true,
    combo: product.combo ? product.combo : null,
    normalPrice: getFinalPrice(returnNormalPrice(product)),
    image: Array.isArray(product.image) ? product.image[0].filename : product.image,
    selected_pov: product.selected_pov ? product.selected_pov : null,
    finalPrice: parseFloat(total).toFixed(2),
    qty: cartQty,
    productQty: product.stock_quantity ? parseInt(product.stock_quantity) : 0,
    sum: product.sum ? product.sum : 0,
    status: product.status ? product.status : "1",
    cartStatus: product.cart_status ? product.cart_status : "1",
    stockStatus: product.stock_status ? product.stock_status : "1",
    cdate: product.cart_cdate ? product.cart_cdate : moment().format("YYYY-MM-DD HH:MM:ss"),
    point: finalPoint ? finalPoint : 0,
    pointSum: finalPoint ? finalPoint * cartQty : 0,
    pointToEarn: pointToEarn,
  };
  if (fromDatabase) cartItem.cid = product.id;
  return cartItem;
};

export const getCartTotal = (cartItems) => {
  let activeItems = cartItems.filter((item) => item.buyable == true && item.qty <= item.productQty);
  var total = 0;
  for (var i = 0; i < activeItems.length; i++) {
    if (activeItems[i].pointSum <= 0) {
      total += parseFloat(activeItems[i].sum);
    }
  }
  return total;
};

export const getCartTotalPoint = (cartItems) => {
  let activeItems = cartItems.filter((item) => item.buyable == true && item.qty <= item.productQty);
  var totalPoint = 0;
  for (var i = 0; i < activeItems.length; i++) {
    if (activeItems[i].pointSum > 0) {
      totalPoint += parseFloat(activeItems[i].pointSum);
    }
  }
  return totalPoint;
};
