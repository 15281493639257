import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from "../../../api/profile";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as AUTH from "../../../api/auth";
import { updateToken, addMyCart } from "../../../actions/index";
import { connect } from "react-redux";
import SideMenu from "../sidemenu";
import AddressItem from "./address-item";
class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      addressList: [],
      isLoading: true,
      defaultMsg: "",
      addressLength: "",
    };
  }

  componentWillMount() {
    this.getAddressList();
  }

  getAddressList() {
    Profile.getAddress()
      .then((res) => {
        if (!res.errors) {
          this.setState(
            {
              addressList: res.data,
              address: true,
              isLoading: true,
              addressLength: res.data.length,
            },
            () => {
              this.setState({
                isLoading: false,
              });
            }
          );
        } else {
          this.setState({
            address: false,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
          this.setState({
            address: false,
            isLoading: false,
          });
        }
      });
  }

  render() {
    const { addressList, address, isLoading } = this.state;
    return (
      <div>
        <Breadcrumb title={"Address"} />

        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab="address" />
              </div>

              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>My Address</h2>
                    </div>
                    <div className="welcome-msg">
                      <p>All your address displaying here, you able to edit and add new address.</p>
                    </div>
                    <div className="box-account box-info">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-title" />
                            {isLoading ? (
                              <div className="loading-cls" />
                            ) : (
                              <>
                                <div className="box-content">
                                  {address ? (
                                    <Fragment>
                                      {this.state.isLoading && addressList ? (
                                        <div className="loading-cls" />
                                      ) : (
                                        <div style={{ marginTop: "10px" }}>
                                          {addressList.map((address, index) => (
                                            <Fragment key={index}>
                                              <AddressItem address={address} />
                                            </Fragment>
                                          ))}
                                        </div>
                                      )}
                                    </Fragment>
                                  ) : (
                                    <h6>No address found</h6>
                                  )}
                                </div>
                                <Link to={`${process.env.PUBLIC_URL}/members/address-new`}>
                                  <input style={addBtn} type="submit" className="btn btn-solid" value="+ Add New Address" />
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const addBtn = {
  float: "right",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "8px",
  paddingBottom: "8px",
  fontSize: "12px",
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(
  null,
  mapDispatchToProps
)(Address);
