import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { toast  } from 'react-toastify';
import * as AUTH from '../../api/auth'
import * as CART from '../../api/cart'
import {updateToken,refreshProfile} from '../../actions'
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const re = /^[0-9\b]+$/;
class Register extends Component {
  constructor(props) {
    super(props);
    this.state={
      loginErr:false,
      failMsg:''
    }
  }

  componentWillMount(){
    if(this.props.token)
      this.props.history.replace('/')
  }

  handleRegister(event){
    event.preventDefault()
    let valid = true
    this.setState({loginErr:false})
    if (event.target.mobileNum.value && !re.test(event.target.mobileNum.value)) {
      valid=false
      this.setState({
        loginErr:true,
        failMsg:'The Mobile Number is invalid'
      })
    }
    if(valid){
      let registerData = {
        fname: event.target.fname.value,
        lname: event.target.lname.value,
        email: event.target.email.value,
        password: event.target.password.value,
        sec_password: event.target.confirmPass.value,
        mobile_no: event.target.mobileNum.value
      };

      let referral = localStorage.getItem('referral');
      if (!!referral) {
        let referralData = JSON.parse(referral);
        registerData['ref'] = referralData.code;
      }

      AUTH.register(registerData).then(res=>{
        toast.success("Register Successful!", { autoClose: 4000,position:'top-center' });
        AUTH.login(registerData).then(result=>{
          this.props.updateToken(result.data.access_token)
          this.props.refreshProfile(true)
          CART.getLatestCart()
          this.props.history.replace('/')
        }).catch(err=>{
          let failMsg='Invalid username/password'
          this.setState({
            loginErr:true,
            failMsg:failMsg
          })
        })
      }).catch(err=>{
        let failMsg=''
        if(err.data){
          if(err.data.name)
            failMsg=err.data.email
          if(err.data.email)
            failMsg=err.data.email
          if(err.data.password)
            failMsg=err.data.password
          if(err.data.sec_password)
            failMsg=err.data.sec_password
            this.setState({
              loginErr:true,
              failMsg:failMsg
            })
        }
      })
    }
  }

  render() {
    const {loginErr,failMsg} = this.state
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Register | " + websiteTitle;
    var seoDesc = "Register in " + websiteTitle;
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{ seoTitle }</title>
        </Helmet>
        <Breadcrumb title={"Register"} />
        {/*Regsiter section*/}
        <section className="register-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>Register</h3>
                <div className="theme-card">
                  <form className="theme-form" onSubmit={(e)=>this.handleRegister(e)}>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="fname">First Name</label>
                        <input type="text" className="form-control" id="fname" placeholder="" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lname">Last Name</label>
                        <input type="text" className="form-control" id="lname" placeholder="" required />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="review">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="abc@example.com" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <input type="number" className="form-control" id="mobileNum" placeholder="" required />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" placeholder="At least 6 characters and contain Alphabets, Numbers and Symbols" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="ConPassword">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPass"
                          placeholder="Confirm your password"
                          required
                        />
                      </div>
                      {loginErr?
                        <div style={errMsg}>{failMsg}</div>:null
                      }
                    </div>
                    <div style={{textAlign:'right'}}>
                      <button type="submit" className="btn btn-solid" >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="theme-card">
              <h3 className="title-font">Are you looking for passive income?</h3>
              <p>
                  Stop trading time for money and create PASSIVE INCOME online.
              </p>
              <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/dropshipping-info`} data-lng="en">
                Click NOW for More Info
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const errMsg = {
  color:'red',
  marginLeft:6,
  paddingBottom:5,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
  updateToken: token => dispatch(updateToken(token)),
  refreshProfile: profile => dispatch(refreshProfile(profile)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register);