import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import * as AUTH from '../../api/auth'
import { toast  } from 'react-toastify';
import { Helmet } from "react-helmet";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.state={
      successMsg:'',
      errorEmailMsg:'',
      errorPassMsg:'',
      errorSecPassMsg:'',
      email:params.get('email'),
      token:params.get('reset_token'),
      password:'',
      sec_password:'',
    }
  }

  componentWillMount(){
    if(!this.state.email || !this.state.token)
      this.props.history.replace('/404-not-found')
  }

  submitReset(event) {
    event.preventDefault();

    this.setState({successMsg:'',errorEmailMsg:'',errorPassMsg:'',errorSecPassMsg:''})
    let data = {
        email:this.state.email,
        reset_token:this.state.token,
        password: event.target.password.value,
        sec_password: event.target.sec_password.value,
    };
    AUTH.resetPassword(data)
      .then((res) => {
        toast.success(res.message, { autoClose: 3000,position:'top-center' });
        this.props.history.replace('/login')
      })
      .catch((err) => {
        if (err.data) {
          if (err.data.email){
            this.setState({
              errorEmailMsg: err.data.email,
            });
          }
          if (err.data.password){
            this.setState({
              errorPassMsg: err.data.password,
            });
          }
          if (err.data.sec_password){
            this.setState({
              errorSecPassMsg: err.data.sec_password,
            });
          }
        }else{
          if(err.http_code==405){
            toast.error('Token has expired, Please try again.', { autoClose: 7000,position:'top-center' });
            this.props.history.replace('/forget-password')
          }
        }
      });
  }

  render() {
    const {successMsg,errorPassMsg,errorEmailMsg,errorSecPassMsg} = this.state
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Reset Password | " + websiteTitle;
    var seoDesc = "Reset password at " + websiteTitle;
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{ seoTitle }</title>
        </Helmet>
        <Breadcrumb title={"Reset password"} />
        {/*Forget Password section*/}
        <section className="pwd-page section-b-space" >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2>Reset Password</h2>
                <div className="col-lg-6 offset-lg-3">
                <p>The password has to: </p>
                <p>· between 6 and 32 characters</p>
                <p>· contain at least one letter, one number and one special character</p>
                </div>
                <form className="theme-form" onSubmit={(e) => this.submitReset(e)}>
                  <div className="form-row">
                    <div className="col-md-12">
                      <input type="password" className="form-control"
                      id="password" placeholder="New Password" required
                      onChange={(e)=>this.setState({password:e.target.value})}
                        />

                        <input  type="password" className="form-control"
                      id="sec_password" placeholder="Confirm Password" required
                      onChange={(e)=>this.setState({confirmPass:e.target.value})}

                       />

                    </div>
                    <button type="submit" className="btn btn-solid">
                      Reset Password
                    </button>
                  </div>
                </form>
                {errorEmailMsg?
                  <div style={fail}>
                    {errorEmailMsg}
                  </div>
                :null}
                {errorPassMsg?
                  <div style={fail}>
                    {errorPassMsg}
                  </div>
                :null}
                {errorSecPassMsg?
                  <div style={fail}>
                    {errorSecPassMsg}
                  </div>
                :null}
                {successMsg?
                <p style={success}>
                  {successMsg}
                </p>
                :null}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const fail={
  width:'auto',
  textAlign:'center',
  color:'red',
  paddingTop:10
}

const success={
  width:'auto',
  textAlign:'center',
  color:'green',
  paddingTop:10
}

const adjust={
  marginTop:-30
}

export default ResetPassword;
