import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { updateToken, addMyCart } from "../../../actions/index";
import { connect } from "react-redux";
import * as AUTH from "../../../api/auth";
import * as DOWNLINE from "../../../api/downline";
import SideMenu from "../sidemenu";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../pagination.css";
import DataTable, { createTheme } from "react-data-table-component";
createTheme("solarized", {
  context: {
    background: "#e3f2fd",
    text: "rgba(0, 0, 0, 0.87)",
  },
  header: {
    style: {
      fontSize: "22px",
      minHeight: "56px",
      paddingLeft: "16px",
      paddingRight: "8px",
    },
  },
});
const customTableStyles = {
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      fontSize: "14px",
      fontWeight: "600",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      minHeight: "10px",
    },
  },
};
const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "4px 8px",
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "1rem",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    cursor: "pointer",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: "#B7D432",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
  }),
};
const now = new Date();
const defaultEnd = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
const defaultStart = moment(defaultEnd).subtract(1, "month");
const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
    wrap: true,
    compact: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    grow: 2,
    compact: true,
  },
  {
    name: "Mobile",
    selector: "mobileno",
    sortable: true,
    wrap: true,
    compact: true,
  },
  {
    name: "User Type",
    selector: "userType",
    sortable: true,
    wrap: true,
    compact: true,
  },
  {
    name: "Referral",
    selector: "referral",
    sortable: true,
    wrap: true,
    compact: true,
  },
  {
    name: "Joined Date",
    selector: "joindate",
    sortable: true,
    wrap: true,
    compact: true,
  },
];
class ResellerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberList: [],
      updateErr: false,
      isOpen: false,
      startDate: defaultStart,
      endDate: defaultEnd,
      currentPage: 1,
      perPage: 10,
      loading: true,
      searchValue: "",
      typeList: [],
      firstLoad: true,
      noRecord: false,
      filterLoading: false,
      selectedType: { value: "0", label: "All User Type" },
    };
  }

  componentDidMount() {
    DOWNLINE.getDealerTypes().then((types) => {
      let optionList = [{ value: "0", label: "All User Type" }];
      let list = Object.entries(types.data);
      if (list && list.length > 0) {
        list.map((type) => {
          optionList.push({ value: type[0], label: type[1].name });
        });
        this.setState({ typeList: list, options: optionList });
      }
      this.getMyDownlines();
    });
  }

  getMyDownlines() {
    let params = {
      per_page: this.state.perPage,
      page: this.state.currentPage,
      search: this.state.searchValue,
      join_start: moment(this.state.startDate).format("YYYY-MM-DD"),
      join_end: moment(this.state.endDate).format("YYYY-MM-DD"),
      acc_type: 'd,m',
    };
    if (this.state.selectedType && this.state.selectedType.value !== "0") {
      params.dealer_type = this.state.selectedType.value;
    }
    DOWNLINE.getDownlineList(params)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.setState(
            {
              memberList: res.data.data,
              pagination: res.data.pagination,
              loading: false,
              filterLoading: false,
            },
            () => {
              if (this.state.firstLoad) this.setState({ firstLoad: false });
            }
          );
        } else {
          this.setState(
            {
              loading: false,
              filterLoading: false,
              noRecord: this.state.firstLoad ? true : false,
            },
            () => {
              if (this.state.firstLoad) this.setState({ firstLoad: false });
            }
          );
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        }
      });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedType: selectedOption }, () => {
      this.filterList();
    });
  };

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  }

  handlePageClick = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.getMyDownlines();
      }
    );
  };

  handlePerPageChanges = (page) => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.getMyDownlines();
      }
    );
  };

  getDealerType(type) {
      if (this.state.typeList.find(TYPE=>TYPE[0]==type))
        return this.state.typeList.find(TYPE=>TYPE[0]==type)[1]?this.state.typeList.find(TYPE=>TYPE[0]==type)[1].name:'-';
      else
        return '-'
  }

  filterList() {
    this.setState({ currentPage: 1, filterLoading: true, memberList: [] }, () => {
      this.getMyDownlines();
    });
  }

  onRowClicked = (item) => {
    this.props.history.push("/members/reseller-details/" + item.id);
  };


  clearFilter(){
    this.setState({
      per_page:10,
      page:1,
      searchValue:'',
      startDate:defaultStart,
      endDate:defaultEnd,
      selectedType:{ value: "0", label: "All User Type" } ,
      loading:true
    },()=>this.getMyDownlines())
  }

  render() {
    const { selectedType, startDate, endDate, memberList, noRecord, filterLoading, options, pagination } = this.state;
    const tbData = [];
    memberList.map((member) => {
      tbData.push({
        id: member.id,
        name: member.name ? member.name : "-",
        email: member.email,
        mobileno: member.mobileno ? member.mobileno : "-",
        userType: this.getDealerType(member.dealer_type_id),
        referral: member.referral ? member.referral.name : "-",
        joindate: member.joindate,
      });
    });
    return (
      <div>
        <Breadcrumb title={"Resellers"} />

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab={"reseller"} />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="row align-items-center">
                      <div className=" col-6 col-md-8">
                        <div className="page-title mb-2">
                          <h2>Reseller List</h2>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 text-right mb-2">
                        <Link to="add-reseller" className="btn btn-solid small">
                          <i className="fa fa-plus mr-1" />
                          Add new reseller
                        </Link>
                      </div>
                    </div>
                    <div className="welcome-msg">
                      <p>All your reseller will display here.</p>
                    </div>
                    <hr />
                    {this.state.loading ? (
                      <div className="loading-cls" />
                    ) : (
                      <>
                        <div className="row table-filter" hidden={noRecord}>
                          <div className="input-group searchBar col-lg-8 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              onKeyDown={(e) => (e.key == "Enter" ? this.filterList() : {})}
                              placeholder="Search name/email/mobile"
                              onChange={(e) => this.setState({ searchValue: e.target.value })}
                            />
                            <div className="input-group-append">
                              <button className="btn btn-solid medium searchBtn" onClick={() => this.filterList()}>
                                <i className="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <Select styles={customStyles} value={selectedType} onChange={this.handleChange} options={options} />
                          </div>
                          <div className="col-lg-12">
                            <span className="mr-2">Joined Date Range :</span>
                            <DateRangePicker
                              onEvent={(e, picker) => this.handleEvent(e, picker)}
                              onApply={() => this.filterList()}
                              startDate={startDate}
                              endDate={endDate}>
                              <input
                                className="dateRange-select-box c-pointer mr-3 mb-2"
                                readOnly
                                value={startDate.format("DD-MM-YYYY") + "   to   " + endDate.format("DD-MM-YYYY")}
                              />
                            </DateRangePicker>
                            <button className="btn btn-solid medium" style={{position:'relative',top:-2}}
                              onClick={() => this.clearFilter()}>
                              Reset
                            </button>
                          </div>
                        </div>

                        {filterLoading ? (
                          <div className="loading-cls" />
                        ) : memberList.length > 0 ? (
                          <DataTable
                            data={tbData}
                            columns={columns}
                            onRowClicked={this.onRowClicked}
                            theme="solarized"
                            customStyles={customTableStyles}
                            highlightOnHover
                            pagination
                            pointerOnHover
                            paginationServer
                            paginationTotalRows={pagination.total}
                            onChangeRowsPerPage={this.handlePerPageChanges}
                            onChangePage={this.handlePageClick}
                          />
                        ) : (
                          <h6 className="mt-4">No Resellers Found</h6>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(null, mapDispatchToProps)(ResellerList);
