import React, { Component } from "react";
import { Link } from "react-router-dom";
import noImg from "../../../assets/images/defaultProduct.jpg";
import Modal from "react-responsive-modal";

class ProductModal extends Component {
  render() {
    const { product, onCloseModal, open, symbol, updateQty, cartItems } = this.props;
    return (
      <Modal open={open} onClose={onCloseModal} center>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content quick-view-modal">
            <div className="modal-body">
              <div className="row mr-0 ml-0">
                <div className="col-lg-6  col-xs-12">
                  <div className="quick-view-img" >
                    <img
                      style={{
                        minWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                        maxHeight: "400px",
                      }}
                      ref={(img) => (this.img = img)}
                      onError={() => (this.img.src = noImg)}
                      src={`${product.image ? product.image : noImg}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <h3 style={{ width: "96%" }}> {product.name} </h3>
                    {product.dealer_price && parseFloat(product.dealer_price) > 0 ? (
                      //dealer price
                      <h3>
                        <span className="money">
                          {symbol}
                          {product.dealer_price}
                        </span>
                      </h3>
                    ) : //normal price
                    product.is_promotion == 1 &&
                      parseFloat(product.price.pprice) > 0 &&
                      product.price.rprice !== product.price.pprice ? (
                      <h3>
                        {symbol}
                        {product.finalPrice ? product.finalPrice : product.price.pprice}
                        <del>
                          <span className="money">
                            {symbol}
                            {product.price.rprice}
                          </span>
                        </del>
                      </h3>
                    ) : (
                      <h3>
                        <span className="money">
                          {symbol}
                          {product.finalPrice ? product.finalPrice : product.price.rprice}
                        </span>
                      </h3>
                    )}
                    <div className="product-description border-product">
                      {product.data_option ? (
                        <div style={{ marginBottom: 10, marginLeft: 5 }}>
                          {product.data_option.map((option, i) => {
                            return (
                              <div key={i} className="row" style={{ alignItems: "center" }}>
                                <div className="col-7" style={optionStyles}>
                                  {option.attribute1 + " " + (option.attribute2 ? "+ " + option.attribute2 : "")}
                                  <span style={{ display: "inline-block" }}>
                                    {" "}
                                    - {symbol}{" "}
                                    {parseFloat(
                                      parseFloat(option.extra_price) +
                                        parseFloat(
                                          product.dealer_price && parseFloat(product.dealer_price) > 0
                                            ? product.dealer_price
                                            : product.is_promotion == 1 &&
                                              parseFloat(product.price.pprice) > 0 &&
                                              product.price.rprice !== product.price.pprice
                                            ? product.price.pprice
                                            : product.price.rprice
                                        )
                                    ).toFixed(2)}
                                  </span>
                                </div>
                                {cartItems.find(
                                  (item) =>
                                    item.pid == product.id &&
                                    item.selected_pov && item.selected_pov.id == option.id &&
                                    item.qty > 0
                                ) ? (
                                  <div className="col-5 text-center">
                                    <div
                                      className="d-inline-flex"
                                      style={{ width: "90%", flexWrap: "nowrap", justifyContent: "center" }}>
                                      <span className="mr-0 w-25">
                                        <button
                                          type="button"
                                          className="qty-btn w-100"
                                          onClick={() => updateQty(product, "minus", option)}
                                          data-type="minus"
                                          data-field="">
                                          <i className="fa fa-minus" style={{ color: "white" }}></i>
                                        </button>
                                      </span>
                                      <input
                                        type="text"
                                        required
                                        name="quantity"
                                        value={
                                          cartItems.find(
                                            (item) =>
                                              item.selected_pov && item.selected_pov.id == option.id && item.pid == product.id
                                          ).qty
                                        }
                                        onChange={(e)=>this.props.changeQty(e,option.id)}
                                        maxLength={3}
                                        style={qtyInput}
                                        className="form-control input-number text-center w-50 p-0"
                                      />
                                      <span className="w-25">
                                        <button
                                          type="button"
                                          className="qty-btn w-100"
                                          onClick={() => updateQty(product, "plus", option)}
                                          data-type="plus"
                                          data-field="">
                                          <i className="fa fa-plus" style={{ color: "white" }}></i>
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-5 addtocart_btn " style={{ textAlign: "center" }}>
                                    <button
                                      className="add-button add_cart bg-theme"
                                      title="Add to cart"
                                      style={btnStyle}
                                      onClick={() => updateQty(product, "add", option)}>
                                      Add To Cart
                                    </button>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="border-product">
                      <Link to={`${process.env.PUBLIC_URL}/product/${product.slug}`} className="btn btn-solid medium">
                        view detail
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const qtyInput = {
  border: "1px solid #ced4da",
  fontSize:14,
};

const btnStyle = {
  width: "90%",
  color: "white",
  paddingLeft: 10,
  paddingRight: 10,
  border: 0,
};

const optionStyles = {
  padding: "8px",
};

export default ProductModal;
