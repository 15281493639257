import React, {Component} from 'react';
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from '../../../api/profile';
import * as AUTH from '../../../api/auth';
import { toast } from 'react-toastify';
// import { Link } from "react-router-dom";
import {updateToken,addMyCart} from '../../../actions/index'
import { connect } from "react-redux";
import SideMenu from '../sidemenu';

class ChangePassword extends Component {
    constructor (props) {
        super (props);
        this.state = {
            password: '',
            secPassword: '',
            failMsg: '',
            updateErr: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {

    }

    handleSubmit(e){
        e.preventDefault();
        const passwordData = new FormData();
        passwordData.append('password', this.state.password);
        passwordData.append('sec_password', this.state.secPassword);
        Profile.changePw(passwordData).then((res) => {
            toast.success("Edit Password Successful!", { autoClose: 3000,position:'top-center' });
            this.setState({
                password: '',
                secPassword: ''
            })
        }).catch(err => {
            if(err.http_code==401 || (err.http_code==403 && err.message=='token expired')){
                AUTH.logout(this.props)
                toast.error("Session Expired", { autoClose: 3000,position:'top-center' });
                this.props.history.replace('/login')
            }else{
                let failMsg='';
                let mssg = 'Invalid information provided!'
                toast.error(mssg, { autoClose: 3000,position:'top-center' });
                if(err.data){
                    if(err.data.password){
                        failMsg = err.data.password
                    }
                    if(err.data.sec_password){
                        failMsg = err.data.sec_password;
                    }
                    this.setState({
                        updateErr: true,
                        failMsg: failMsg
                    })
                }
            }
        })
    }

    render (){
        const { updateErr, failMsg } = this.state
        return (
            <div>
                <Breadcrumb title={'Change Password'}/>
                    {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <SideMenu activeTab={'password'}/>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Edit Password</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>For your account's security, please do not share your password with anyone else.</p>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="box">
                                                        <div className="box-title"></div>
                                                        <div className="box-content">
                                                        <form className="theme-form" onSubmit={(e)=>this.handleSubmit(e)}>
                                                            <div className="form-row">
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="email">New Password</label>
                                                                    <input onChange={(e)=>this.setState({password : e.target.value})} value={this.state.password} type="password" className="form-control" id="password"
                                                                        placeholder="New Password" required="" />
                                                                </div>
                                                                <div className="col-md-6 mt-3">
                                                                    <label htmlFor="review">Confirm Password</label>
                                                                    <input onChange={(e)=>this.setState({secPassword : e.target.value})} value={this.state.secPassword} type="password" className="form-control" id="secPassword"
                                                                        placeholder="Confirm Password" required="" />
                                                                </div>
                                                            </div>
                                                            {updateErr?
                                                                <div style={errMsg}>{failMsg}</div>:null
                                                            }
                                                            <div className="form-row">
                                                                <div style={{paddingLeft: '5px'}} className="mt-3">
                                                                    <input type="submit" className="btn btn-solid" value="Confirm"/>
                                                                </div>
                                                            </div>
                                                        </form>
                                                     </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
        )
    }

}

const errMsg = {
    color:'red',
    paddingBottom:8,
}

const mapDispatchToProps = dispatch => ({
    updateToken: token => dispatch(updateToken(token)),
    addMyCart: cart => dispatch(addMyCart(cart))
  })

export default connect(null,mapDispatchToProps)(ChangePassword);