import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  constructor(props){
    super(props)
    this.state={
      categoryLv:props.categoryLv?props.categoryLv:[]
    }
  }

  render() {
    const { title, parent } = this.props;
    return (
      <div className="breadcrumb-section" style={breadcrumb}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="page-title">
                <h2>{title}</h2>
              </div>
            </div>
            <div className="col-md-6">
              <nav aria-label="breadcrumb" className="theme-breadcrumb">
                <ol className="breadcrumb" style={textStyle}>
                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                  </li>
                  {parent ? (
                    Array.isArray(parent)?
                    parent.reverse().map(level=>{
                      return (
                        <Link key={level.id} to={`${process.env.PUBLIC_URL}/product-category/`+level.id} 
                          className="breadcrumb-item" aria-current="page">
                          {level.name}
                        </Link>
                      )
                    })
                    :
                    <li className="breadcrumb-item" aria-current="page">
                      {parent}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const breadcrumb = {
  padding:'8px'
};

const textStyle = {
  fontSize:13
}

export default Breadcrumb;
