import { combineReducers } from 'redux';
// import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

// Import custom components
import generalReducer from './general';
import cartReducer from './cart';
import authReducer from './authentication';


const rootReducer = combineReducers({
    data: generalReducer,
    cartList: cartReducer,
    auth: authReducer,
});

export default rootReducer;