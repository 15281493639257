import React, { Component } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../actions/index";
import * as PRODUCTS from "../../api/product";
import loading from "../../assets/images/loader.gif";
import { Link } from "react-router-dom";
import noImg from "../../assets/images/defaultProduct.jpg";

class SpecialCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      categoryList: [],
      nonFeatureTopCategory: [],
      currentPage: 1,
      moreLoading: false,
      noResult: false,
      loadedMore: false,
    };
  }

  componentWillMount() {
    this.getCategoryList();
  }

  getCategoryList() {
    let params={
      page:this.state.currentPage,
      per_page:10
    }
      PRODUCTS.getHomeCategoryList(params)
      .then(res => {
        if(res.data){
          this.setState(
            {
              categoryList: res.data//this.state.currentPage == 1 ? (res.data ? res.data : []) : [...this.state.categoryList, ...res.data],
              //totalPage: res.pagination.total_page
            },
            () => {
              this.setState({
                isLoading: false,
                moreLoading: false
              });
            }
          );
        }else{
          this.setState({
            noResult:true,
            isLoading: false,
            moreLoading: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          noResult: true,
          isLoading: false,
          moreLoading: false,
        });
      });
  }

  render() {
    const { categoryList } = this.state;
    const { title, subtitle } = this.props;
    return (
      <div hidden={categoryList && categoryList.length==0} style={{minHeight:'300px',justifyContent:'center'}}>
        <div className="title1" style={sectionBox}>
          {subtitle ? <h4>{subtitle}</h4> : ""}
          <h2 className="title-inner1 mb-0">
            {title}
          </h2>
        </div>
        {this.state.isLoading ? (
          <div className="loading-cls" />
        ) : (
          <section className="section-b-space addtocart_count pt-4" >
            <div className="container">
              <div className="no-slider row">
                {categoryList.map((cat,index)=>{return(
                    <div key={index} className="category-box" style={{marginBottom:'15px'}}>
                      <div className="img-wrapper">
                        <div className="front" style={{marginBottom:10,height:'auto'}}>
                          <Link to={{ pathname: "/product-category/" + cat.id, state: { from: "category", category: cat.name } }}>
                            <img
                              style={{objectFit:'cover',height:'auto'}}
                              ref={img => (this.img = img)}
                              src={`${cat.wimage ? cat.wimage : noImg}`}
                              onError={() => (this.img.src = noImg)}
                              className="img-fluid"
                              alt={cat.name}
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="product-detail">
                        <div>
                          <Link to={{ pathname: "/product-category/" + cat.id, state: { from: "category", category: cat.name } }}>
                            <h6 style={catName}>{cat.name}</h6>
                          </Link>
                        </div>
                      </div>
                    </div>
                )})}
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const catIcon = {
  height:20,
  width:20,
  marginRight:4,
  marginLeft:4,
}



const sectionBox = {
  paddingTop: 0
};

const loaderStyle = {
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  marginTop:'200px'
};


const getMoreStyle = {
  textAlign: "center",
  fontSize: 20,
  cursor: "pointer",
};

const catName = {
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  fontSize:19,
  WebkitBoxOrient: "vertical",
  height: "40px",
  marginBottom: 2,
  padding:4,
  lineHeight: '30px'
};

const subCatName = {
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  fontSize:18,
  WebkitBoxOrient: "vertical",
  height: "40px",
  marginBottom: 2,
  padding:4,
  lineHeight: '30px'
}

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol
  };
};

export default connect(
  mapStateToProps,
  { addToCart }
)(SpecialCategories);
