import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from "../../../api/profile";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as AUTH from "../../../api/auth";
import { updateToken, addMyCart, refreshProfile } from "../../../actions/index";
import { connect } from "react-redux";
import defaultAvatar from "../../../assets/images/defaultAvatar.jpg";
import SideMenu from "../sidemenu";
import { Helmet } from "react-helmet";

const mobileReg = /^[0-9\b]+$/;
class WithdrawSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failMsg: "",
      withdrawMethod: "",
      bankId: "",
      bankName: "",
      accName: "",
      accNumber: "",
      methodErr: false,
      updateErr: false,
      isOpen: false,
      avatarFile: null,
      loading: true,
    };
    this.inputRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.getProfileInfo();
    this.getUserBank();
  }

  getProfileInfo() {
    Profile.getProfile()
      .then((res) => {
        this.setState({
          withdrawMethod: res.data.withdraw_method,
          loading: false,
        });
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
          toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
        }
      });
  }

  getUserBank() {
    Profile.getBank()
      .then((res) => {
        this.setState({
          bankId: res.data[0]['id'],
          bankName: res.data[0]['bank_name'],
          accName: res.data[0]['account_name'],
          accNumber: res.data[0]['account_number'],         
        });
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        }
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    let valid = true;
    if (this.state.withdrawMethod == null) {
      valid = false;
      this.setState({ methodErr: true });
    }
    if (valid) {
      this.setState({ imgFailMsg: "" });
      const profileData = new FormData();
      profileData.append("withdraw_method", this.state.withdrawMethod);
      profileData.append("bank_id", this.state.bankId);
      profileData.append("bank_name", this.state.bankName);
      profileData.append("account_name", this.state.accName);
      profileData.append("account_number", this.state.accNumber);

      Profile.updateWithdrawSetting(profileData)
        .then((res) => {
          this.props.refreshProfile(true);
          toast.success("Update Withdraw Setting Successful!", { autoClose: 3000, position: "top-center" });
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          } else {
            let failMsg = "";
            if (err.data) {
              if (err.data.email) {
                failMsg = err.data.email;
                this.setState({
                  updateErr: true,
                  failMsg: failMsg,
                });
              }
              if (err.data.gender) {
                toast.error(err.data.gender, { autoClose: 3000, position: "top-center" });
              }
            } else if (err.message && err.http_code == 500) {
              this.setState({
                imgFailMsg: err.message,
              });
            } else {
              toast.error("Invalid Information Provided", { autoClose: 3000, position: "top-center" });
            }
          }
        });
    }
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { updateErr, failMsg, loading, bankName, accName, accNumber, methodErr } = this.state;
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Withdraw Setting | " + websiteTitle + " Member Area";
    var seoDesc = "";
    var seoImage = process.env.BASE_URL + "/logo.png";
    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{ seoTitle }</title>
        </Helmet>
        <Breadcrumb title={"Withdraw Setting"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab="withdraw" />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>Withdraw Setting</h2>
                    </div>
                    <div className="welcome-msg">
                      <p>Here's your personal withdraw setting information.</p>
                    </div>
                    <div className="box-account box-info">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="box">
                            <div className="box-title"></div>
                            <div className="box-content">
                              {loading ? (
                                <div className="loading-cls" />
                              ) : (
                                <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                  <div className="form-row">
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="review">Withdraw Method</label>
                                      <div className="radio">
                                        <input
                                          onChange={(e) => this.setState({ withdrawMethod: e.target.value, methodErr: false })}
                                          checked={this.state.withdrawMethod === "1"}
                                          type="radio"
                                          value="1"
                                          className="c-pointer"
                                        />
                                        <span className="c-pointer ml-1 mr-4" onClick={() => this.setState({ withdrawMethod: "1" })}>
                                          Bank In
                                        </span>

                                        <input
                                          onChange={(e) => this.setState({ withdrawMethod: e.target.value, methodErr: false })}
                                          checked={this.state.withdrawMethod === "2"}
                                          type="radio"
                                          value="2"
                                          className="c-pointer"
                                        />
                                        <span className="c-pointer ml-1 mr-4" onClick={() => this.setState({ withdrawMethod: "2" })}>
                                          EWallet
                                        </span>
                                      </div>
                                      { methodErr ? <div style={errMsg}>Withdraw method is required</div> : null }
                                    </div>
                                  </div>
                                  {this.state.withdrawMethod == '1' ? 
                                  <> 
                                  <div className="form-row">
                                    <div className="col-md-12 mt-3">
                                      <label htmlFor="email">Bank Name</label>
                                      <input
                                        maxLength={50}
                                        onChange={(e) => this.setState({ bankName: e.target.value })}
                                        value={bankName}
                                        type="text"
                                        className="form-control"
                                        id="bank_name"
                                        placeholder="Bank Name"
                                        required = {this.state.withdrawMethod == '1' ? true : false}
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="form-row">
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="email">Account Name</label>
                                      <input
                                        maxLength={50}
                                        onChange={(e) => this.setState({ accName: e.target.value })}
                                        value={accName}
                                        type="text"
                                        className="form-control"
                                        id="acc_name"
                                        placeholder="Account Name"
                                        required = {this.state.withdrawMethod == '1' ? true : false}
                                      />
                                      {updateErr ? <div style={errMsg}>{failMsg}</div> : null}
                                    </div>
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="review">Account Number</label>
                                      <input
                                        maxLength={50}
                                        onChange={(e) => this.setState({ accNumber: e.target.value })}
                                        value={accNumber}
                                        type="number"
                                        className="form-control"
                                        id="acc_number"
                                        placeholder="Account Number"
                                        required = {this.state.withdrawMethod == '1' ? true : false}
                                      />
                                    </div>
                                  </div>
                                  </>:null}
                                  
                                  <div className="mt-3">
                                    <input type="submit" className="btn btn-solid" value="Update" />
                                  </div>
                                </form>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const avatarStyle = {
  borderRadius: 130 / 2,
  height: 130,
  width: 130,
  objectFit: "cover",
};

const errMsg = {
  color: "red",
  paddingBottom: 8,
};

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
  refreshProfile: (profile) => dispatch(refreshProfile(profile)),
});

export default connect(null, mapDispatchToProps)(WithdrawSetting);
