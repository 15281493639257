import React, { Component } from "react";
import Slider from "react-slick";
import "../common/index.scss";
import { Helmet } from "react-helmet";
// import custom Components
import ProductListing from "../collection/common/product-listing";
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "../collection/common/filterBar";
import * as PRODUCTS from "../../api/product";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      from: props.location.state ? props.location.state.from : "",
      id: props.match.params.id ? props.match.params.id : 0,
      categoryName: props.location.state ? props.location.state.category : "",
      searchValue: decodeURI(window.location.search?window.location.search.substring(1).split('=')[1]:''),
      productList: [],
      loading: true,
      mode: "grid",
      pagination: {},
      layoutColumns: 2,
      filterParams: {
        per_page: 18,
        include: "child",
      },
      currentPage: 1,
      currentSort: "Newest",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname ||
      (this.props.location.pathname == prevProps.location.pathname && this.props.location.search !== prevProps.location.search)) {
      this.refresh();
    }
  }

  refresh() {
    this.setState(
      {
        from: this.props.location.state ? this.props.location.state.from : "",
        id: this.props.match.params.id ? this.props.match.params.id : 0,
        categoryName: this.props.location.state ? this.props.location.state.category : "",
        searchValue: decodeURI(window.location.search?window.location.search.substring(1).split('=')[1]:''),
        productList: [],
        loading: true,
        pagination: {},
        layoutColumns: 2,
        filterParams: {
          per_page: 18,
          include: "child",
        },
        currentPage: 1,
        currentSort: "Newest",
      },
      () => {
        if(window.location.pathname=='/product-search'){
          this.getSearchProducts(1);
        }else
          this.getThisCategoryList(this.state.id, 1);
      }
    );
  }

  componentWillMount() {
    document.getElementById("BODY").style.touchAction = "manipulation";
  }

  componentWillUnmount() {
    document.getElementById("BODY").style.touchAction = null;
  }

  componentDidMount() {
    if(window.location.pathname=='/product-search'){
      this.getSearchProducts(1);
    }else
      this.getThisCategoryList(this.state.id, 1);
  }

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  getSearchProducts(page){
    let params = {
      value:this.state.searchValue,
      per_page:12,
      page:page
    }
    this.setState({ currentPage: page });
    PRODUCTS.searchProduct(params).then((res) => {
      if (res.http_code==200 && res.data.list) {
        this.setState({
          productList: page == 1 ? res.data.list : [...this.state.productList, ...res.data.list],
          pagination: res.data.pagination,
          loading: false,
        });
      } else {
        this.setState({
          pagination: res.data.pagination,
          previousLevel: "",
          productList: [],
          loading: false,
          categoryLv: [],
        });
      }
    })
    .catch((err) => {
    });
  }

  getThisCategoryList(id, page) {
    let params = this.state.filterParams;
    params.id = id;
    params.page = page;
    this.setState({ currentPage: page });
    PRODUCTS.getCategoryProductList(params)
      .then((res) => {
        if (res.data) {
          this.setState({
            pagination: res.pagination,
            productList: page == 1 ? res.data : [...this.state.productList, ...res.data],
            loading: false,
            categoryName: res.data[0].category[0].name,
            categoryLv: res.data[0].category.filter((cat) => cat.id !== id),
          });
          if(this.state.mode=='list'){
            document.querySelector(".collection-grid-view ul").style = "display:none";
            document.querySelector(".product-wrapper-grid").classList.add("list-view");
            var elems = document.querySelector(".infinite-scroll-component .row").childNodes;
            [].forEach.call(elems, function (el) {
              el.className = "";
              el.classList.add("col-xl-12");
            });
          }
        } else {
          this.setState({
            pagination: res.pagination,
            previousLevel: "",
            productList: [],
            loading: false,
            categoryLv: [],
          });
        }
      })
      .catch((err) => {
      });
  }

  updateSorting(currentSort) {
    let params = this.state.filterParams;
    if (currentSort == "Newest") {
      params.sort_type = "desc";
      params.sort_by = "id";
    } else if (currentSort == "AscPrice") {
      params.sort_type = "asc";
      params.sort_by = "rprice";
    } else if (currentSort == "DescPrice") {
      params.sort_type = "desc";
      params.sort_by = "rprice";
    } else if (currentSort == "AscName") {
      params.sort_type = "asc";
      params.sort_by = "name";
    } else if (currentSort == "DescName") {
      params.sort_type = "desc";
      params.sort_by = "name";
    }

    this.setState(
      {
        filterParams: params,
        loading: true,
        currentSort: currentSort,
      },
      () => {
        this.getThisCategoryList(params.id, 1);
      }
    );
  }

  render() {
    const {
      id,
      productList,
      pagination,
      loading,
      categoryName,
      currentPage,
      filterParams,
      currentSort,
      categoryLv,
      mode,
      searchValue
    } = this.state;
    var websiteTitle = localStorage.getItem("website");
    if (categoryName) {
      var seoTitle = categoryName + ' | ' + websiteTitle;
      var seoDesc = "Buy " + categoryName + " at " + websiteTitle;
    } else {
      var seoTitle = "Search for " + searchValue + " | " + websiteTitle;
      var seoDesc = "Search for " + searchValue + " at " + websiteTitle;;
    }
    var seoImg = process.env.BASE_URL + "/logo.png";

    return (
      <div >
        {loading && productList ? (
          <div className="loading-cls" style={{marginTop:'15%',marginBottom:'15%'}} />
        ) : (
          <>
            <Breadcrumb parent={categoryLv} title={categoryName ? categoryName :
              window.location.pathname=='/product-search'?"Product Search : "+searchValue:"Product List"} />
            <Helmet>
              <meta property="og:image" content={seoImg} data-react-helmet="true" />
              <meta property="og:title" itemprop="name" content={seoTitle} />
              <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
              <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

              <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
              <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
              <meta name="twitter:image" content={seoImg} data-react-helmet="true" />
              <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

              <meta property="description" content={seoDesc} data-react-helmet="true" />
              <meta property="title" content={seoTitle} data-react-helmet="true" />
              <title>{ seoTitle }</title>
            </Helmet>
            <section className="section-b-space addtocart_count" style={{ paddingTop: 25 }}>
              <div className="collection-wrapper mt-4 mb-3">
                <div className="container">
                  <div className="row">
                    <div className="collection-content col">
                      <div className="page-main-content">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="collection-product-wrapper">
                              {productList.length >0 ?
                                <div className="product-top-filter">
                                  <div className="container-fluid p-0">
                                    <div className="row">
                                      <div className="col-12">
                                        <FilterBar
                                          pagination={pagination}
                                          filterParams={filterParams}
                                          currentSort={currentSort}
                                          changeMode={(mode) => this.setState({ mode: mode })}
                                          updateSorting={this.updateSorting.bind(this)}
                                          onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              :null}
                              <div className="product-wrapper-grid">
                                <div className="container-fluid">
                                  <div className="row">
                                    <ProductListing
                                      colSize={this.state.layoutColumns}
                                      id={id}
                                      productList={productList}
                                      loading={loading}
                                      mode={mode}
                                      history={this.props.history}
                                      page={currentPage}
                                      pagination={pagination}
                                      fetchNext={window.location.pathname=='/product-search'?this.getSearchProducts.bind(this):this.getThisCategoryList.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    );
  }
}

export default ProductList;
