import * as AUTH from './auth'
const axios = require("axios");

export async function getCommissionList(params) {
  let url=process.env.API_URL + process.env.API_PREFIX +'/commission-entry?'
  if(params.status)
    url+="&status="+params.status
  if(params.per_page)
    url+="&per_page="+params.per_page
  if(params.page)
    url+="&page="+params.page
  if(params.start_date)
    url+="&start_date="+params.start_date
  if(params.end_date)
    url+="&end_date="+params.end_date
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if(data.http_code=='200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getDashboard(params) {
  let url=process.env.API_URL + process.env.API_PREFIX +'/dashboard?'
  if(params.start_date)
    url+="&top_agent_start="+params.start_date
  if(params.end_date)
    url+="&top_agent_end="+params.end_date
      return new Promise((resolve, reject) => {
      axios
        .get(url,{
          headers: {
            Accept: "application/json",
            'token': AUTH.getAccessToken()
          }
        })
        .then(response => {
          const { data } = response;
          if(data.http_code=='200')
            resolve(data);
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data) {
              reject(error.response.data);
            }
          }
        })
    });
  }
  
