import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import {Link} from 'react-router-dom'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class DetailsTopTabs extends Component {
    render (){
        const {item} = this.props
        return (
            <section className="tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link active">
                                        <i className="icofont icofont-ui-home"></i>Description</span>
                                    <div className="material-border"></div>
                                </Tab>

                            </TabList>
                            <TabPanel className="tab-pane fade mt-4 mb-4 show active">
                            {item.descr?
                            <span className="container" style={{whiteSpace:'pre-wrap'}}>
                                { ReactHtmlParser(item.descr) }
                                <div className="product-specs" hidden={item.specification.length == 0}>
                                  <h3>Specification</h3>
                                  { ReactHtmlParser(item.specification) }
                                </div>
                            </span>
                            :<div>No Description</div>
                            }
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}

export default DetailsTopTabs;