const axios = require("axios");

export async function getBannerType(type) {
  let url=process.env.API_URL + process.env.API_PREFIX +'/banner/type/'+type
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getFooterMenu() {
  let url=process.env.API_URL + process.env.API_PREFIX +'/system-settings/cms-list?type=f'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getMenuContent(link) {
  let url=process.env.API_URL + process.env.API_PREFIX +'/system-settings/cms-content?filename='+link
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function getSystemSettingsGeneral() {
  let url=process.env.API_URL + process.env.API_PREFIX +'/system-settings/general'
    return new Promise((resolve, reject) => {
    axios
      .get(url,{
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error)
      })
  });
}

export async function sendEnquiry(data) {

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/enquiry/contact-us",data,{
        headers: {
          Accept: "application/json"
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function checkShippingAddressAvailability(data) {
  let addressData = new FormData();
  addressData.append('shipping_street1', data.shipping_street1);
  addressData.append('shipping_street2', data.shipping_street2);
  addressData.append('shipping_city', data.shipping_city);
  addressData.append('shipping_state', data.shipping_state);
  addressData.append('shipping_zip', data.shipping_zip);
  addressData.append('shipping_country', data.shipping_country);
  
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX +"/shipping/service-availability",addressData,{
        headers: {
          Accept: "application/json"
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        console.log(error.response);
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export function returnStatusName(status){
  if(status=='13' || status=='14')
    return 'Out of Stock'
  else if(status=='11')
    return 'Coming Soon'
  else if(status=='11')
    return 'View Only'
  else if(status=='16')
    return 'Inquiry'
  else if(status=='15')
    return 'Discontinued'
}

export function getStateList(){
  const stateList = [
    {"":"Please Select"},
    {"SGR":"Selangor"},
    {"KUL":"W.P. Kuala Lumpur"}
  ]
  return stateList
}

export async function getReferralDetails(ref) {
  return new Promise((resolve, reject) => {
    axios
    .get(process.env.API_URL + process.env.API_PREFIX + '/referral/' + ref, {
      headers: {
        Accept: "application/json"
      }
    })
    .then(response => {
      const { data } = response;
      if (data.http_code == '200')
        resolve(data.data);
    })
    .catch(error => {
      if (error.response) {
        if (error.response.data) {
          reject(error.response.data);
        }
      }
    })
  });
}