import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { updateToken, addMyCart } from "../../actions/index";
import { connect } from "react-redux";
import * as AUTH from "../../api/auth";
import * as DEALER from "../../api/dealer";
import SideMenu from "./sidemenu";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./pagination.css";
import DataTable, { createTheme } from "react-data-table-component";
createTheme("solarized", {
  context: {
    background: "#e3f2fd",
    text: "rgba(0, 0, 0, 0.87)",
  },
  header: {
    style: {
      fontSize: "22px",
      minHeight: "56px",
      paddingLeft: "16px",
      paddingRight: "8px",
    },
  },
});
const customTableStyles = {
  headCells: {
    style: {
      paddingLeft: "8px", 
      paddingRight: "8px",
      fontSize: "14px",
      fontWeight: "600",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", 
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      minHeight: "10px",
    },
  },
};
const customStyles = {
  container: (provided) => ({
    ...provided,
    width:230
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 8px",
    textTransform:"capitalize"
  }),
  input: (provided) => ({
    ...provided,
    fontSize: "1rem",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    cursor: "pointer",
  }),
  option: (provided) => ({
    ...provided,
    textTransform:"capitalize"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: "#B7D432",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
  }),
};

const now = new Date();
const defaultEnd = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
const defaultStart = moment(defaultEnd).subtract(1, "month");
const getBadgeClass = (status) =>{
  if(status=='Paid')
    return "badge badge-success p-1"
  else if(status=='Pending')
    return "badge badge-info p-1"
  else if(status=='Cancelled' || status=='Void')
    return "badge badge-dark p-1"
  else
    return "badge badge-secondary p-1"
}
const ExpanableComponent = ({ data }) => <div className="p-2">{data.description}</div>;
const columns = [
  {
    name: "Order No",
    selector: "id",
    sortable: false,
    wrap: true,
    compact: true,
    cell: row => <Link to={"/members/order-detail/"+row.id}>{row.id}</Link>,
  },
  {
    name: "Type",
    selector: "typeName",
    sortable: false,
    wrap: true,
    compact: true,
    grow:2
  },
  // {
  //   name: "Description",
  //   selector: "description",
  //   wrap: true,
  //   grow: 3,
  //   compact: true,
  // },
  {
    name: "Amount (RM)",
    selector: "amount",
    sortable: false,
    compact: true,
    wrap: true,
  },
  // {
  //   name: "Status",
  //   selector: "status",
  //   sortable: true,
  //   wrap: true,
  //   compact: true,
  //   grow:0,
  //   cell: row => <div className={getBadgeClass(row.status)}>{row.status}</div>,
  // },
  {
    name: "Created Date",
    selector: "createdAt",
    sortable: false,
    wrap: true,
    compact: true,
  },
];
class CommissionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commissionList: [],
      updateErr: false,
      isOpen: false,
      startDate: defaultStart,
      endDate: defaultEnd,
      currentPage: 1,
      perPage: 10,
      loading: true,
      searchValue: "",
      typeList: [],
      firstLoad: true,
      noRecord: false,
      filterLoading: false,
      selectedStatus: { value: "0", label: "All" },
    };
  }

  componentDidMount() {
      this.getMyCommission();
  }

  getMyCommission() {
    let params = {
      per_page: this.state.perPage,
      page: this.state.currentPage,
      start_date: moment(this.state.startDate).format("YYYY-MM-DD"),
      end_date: moment(this.state.endDate).add(1, "days").format("YYYY-MM-DD"),
    };
    if (this.state.selectedStatus && this.state.selectedStatus.value!=="0") {
      params.status = this.state.selectedStatus.value;
    }
    DEALER.getCommissionList(params)
      .then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          let optionList = [{ value: "0", label: "All" }];
          let list = Object.entries(res.data.status_list);
          if (list && list.length > 0) {
            list.map((type) => {
              optionList.push({ value: type[0], label: type[1] });
            });
          }
          this.setState(
            {
              commissionList: res.data.data,
              pagination: res.data.pagination,
              loading: false,
              filterLoading: false,
              options: optionList
            },
            () => {
              if (this.state.firstLoad) this.setState({ firstLoad: false });
            }
          );
        } else {
          this.setState(
            {
              loading: false,
              filterLoading: false,
              noRecord: this.state.firstLoad ? true : false,
            },
            () => {
              if (this.state.firstLoad) this.setState({ firstLoad: false });
            }
          );
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        }
      });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedStatus: selectedOption }, () => {
      this.filterList();
    });
  };

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  }

  handlePageClick = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.getMyCommission();
      }
    );
  };

  handlePerPageChanges = (page) => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.getMyCommission();
      }
    );
  };

  getDealerType(type) {
      if (this.state.typeList.find(TYPE=>TYPE[0]==type))
        return this.state.typeList.find(TYPE=>TYPE[0]==type)[1]?this.state.typeList.find(TYPE=>TYPE[0]==type)[1].name:'-';
      else
        return '-'
  }

  filterList() {
    this.setState({ currentPage: 1, filterLoading: true, commissionList: [] }, () => {
      this.getMyCommission();
    });
  }

  clearFilter(){
    this.setState({
      per_page:10,
      page:1,
      searchValue:'',
      startDate:defaultStart,
      endDate:defaultEnd,
      selectedStatus:{ value: "0", label: "All" },
      loading:true
    },()=>this.getMyCommission())
  }

  render() {
    const { selectedStatus, startDate, endDate, commissionList, noRecord, filterLoading, options, pagination } = this.state;
    const tbData = [];
    commissionList.map((member,index) => {
      tbData.push({
        id: member.reference,
        typeName:  member.commission_type_name,
        description: member.description,
        amount: member.amount ,
        status: member.status_name,
        createdAt: moment(member.created_at).format("YYYY-MM-DD"),
      });
    });
    return (
      <div>
        <Breadcrumb title={"Members"} />

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab={"commission"} />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="row align-items-center">
                      <div className=" col-6 col-md-8">
                        <div className="page-title mb-2">
                          <h2>Commission List</h2>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 text-right mb-2">
                       
                      </div>
                    </div>
                    <div className="welcome-msg">
                      <p>All your earned commission will display here.</p>
                    </div>
                    <hr />
                    {this.state.loading ? (
                      <div className="loading-cls" />
                    ) : (
                      <>
                        <div className="row table-filter" hidden={noRecord}>
                          <div className="col">
                            <div>Date Range :</div>
                            <DateRangePicker
                              onEvent={(e, picker) => this.handleEvent(e, picker)}
                              onApply={() => this.filterList()}
                              startDate={startDate}
                              endDate={endDate}>
                              <input
                                className="dateRange-select-box c-pointer mr-3"
                                readOnly
                                value={startDate.format("DD-MM-YYYY") + "   to   " + endDate.format("DD-MM-YYYY")}
                              />
                            </DateRangePicker>
                          </div>
                          {/* <div className="col">
                            <span>Status : </span><Select styles={customStyles} value={selectedStatus} onChange={this.handleChange} options={options} />
                          </div> */}
                          <div className="col">
                            <button className="btn btn-solid medium" onClick={() => this.clearFilter()}>
                              Reset
                            </button>
                          </div>
                        </div>

                        {filterLoading ? (
                          <div className="loading-cls" />
                        ) : commissionList.length > 0 ? (
                          <DataTable
                            data={tbData}
                            columns={columns}
                            theme="solarized"
                            customStyles={customTableStyles}
                            striped
                            pagination
                            paginationServer
                            paginationTotalRows={pagination.total}
                            onChangeRowsPerPage={this.handlePerPageChanges}
                            onChangePage={this.handlePageClick}
                            expandableRows
                            expandableRowsComponent={<ExpanableComponent />}
                          />
                        ) : (
                          <h6 className="mt-4">No Commission Records Found</h6>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(null, mapDispatchToProps)(CommissionList);
