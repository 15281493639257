import React, { Component } from 'react';
import Slider from 'react-slick';

class SmallImages extends Component {
    constructor (props) {
        super(props);
        this.state = {
            nav2: null
        }
    }
    componentDidMount() {
        this.setState({
            nav2: this.slider2
        });
    }

    render() {
        const { item, settings } = this.props;

        var productsnav = settings;

        return (
            <div className="row">
                <div className="col-12 p-0">
                    <Slider {...productsnav} asNavFor={this.props.navOne} 
                    ref={slider => (this.slider2 = slider)} className="slider-nav">
                        {item.image && item.image.length>1?
                            item.image.map((img, index) =>
                            <div key={index} onClick={()=>this.props.updateSelectedImg(img.filename)}>
                                <img src={`${img.filename}`} key={index} alt=""  />
                            </div>
                        ):null}
                    </Slider>
                </div>
            </div>
        );
    }
}

export default SmallImages;