import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import * as AUTH from '../../api/auth'
import * as CART from '../../api/cart'
import { updateToken, refreshProfile } from '../../actions'
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { svgFreeShipping, svgservice, svgoffer, svgpayment, svgnote, svgchecklist, svgtag, svgstar, svgclose, svgchart, svgdollar } from "../../services/script";
import dropshippingbanner1 from '../../assets/images/dropshippingbanner1.png';
import dropshippingbanner2 from '../../assets/images/dropshippingbanner2.png';
import WarrenBuffett from '../../assets/images/Warren Buffett.jpg';
import Robert from '../../assets/images/Robert Kiyosaki.jpg';
import { Slider2, Team4 } from "../../services/script"
import Slider from 'react-slick';
import { Row, Media, Col } from "reactstrap";

const re = /^[0-9\b]+$/;
class DropshippingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginErr: false,
      failMsg: ''
    }
  }

  componentWillMount() {
    // if (this.props.token)
    //   this.props.history.replace('/')
  }



  render() {
    const { loginErr, failMsg } = this.state
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Dropshipping Info | " + websiteTitle;
    var seoDesc = "Dropshipping Info in " + websiteTitle;
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{seoTitle}</title>
        </Helmet>
        <Breadcrumb title={"Dropshipping Info"} />

        <br></br>
        <br></br>

                 {/*collection banner layout*/}
                 <section className="banner-padding absolute-banner pb-0 ratio2_1 ">
                    <div className="container absolute-lg">
                        <div className="row partition2">
                            <div className="col-md-6">
                                    <div className="collection-banner p-right text-center">
                                        <div>
                                            <img src={dropshippingbanner1}
                                                 className="img-fluid  bg-img" alt="" />
                                        </div>                             
                                    </div>
                            </div>
                            <div className="col-md-6">
                                    <div className="collection-banner p-right text-center">
                                        <div>
                                            <img src={dropshippingbanner2}
                                                 className="img-fluid blur-up lazyload bg-img" alt="" />
                                        </div>                                
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*collection banner layout end*/}
        
  {/* Testimonial
  <section className="testimonial small-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
              <Row>
                <Slider {...Slider2} className="slide-2 testimonial-slider no-arrow">
                 
                    <Col>
                  <div>           
                    <img src={dropshippingbanner1} className="img-fluid" alt="#" />
                  </div>
                  </Col>
                  <Col>
                  <div> 
                        <img src={dropshippingbanner2} className="img-fluid" alt="#" />
                  </div>
                  </Col>
                 
                </Slider>
                </Row>
              </div>
            </div>
          </div>
        </section> */}

                  {/*Service One*/}
                  <div className="container">                
                    <section className="service section-b-space  border-section border-top-0">
                    <div className="row">
                        <div className="col">
                            <div className="title4">
                                <h2 className="title-inner4">10 BENEFITS</h2>
                                <div className="line"><span></span></div>
                            </div>
                            </div>
                    </div>
                    <br></br>
                        <div className="row partition4 ">
                            <div className="col-lg-3 col-md-6 service-block1">                         
                                <div dangerouslySetInnerHTML={{ __html: svgstar }} />
                                        <h4>Easy to Get Started</h4>
                                        <p>You don't have to deal with physical products!</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1 ">
                            <div dangerouslySetInnerHTML={{ __html: svgservice }} />  
                                        <h4>Easy to Manage</h4>
                                        <p>You can sell your products to your customers 
                        by just sending them an affiliate link!</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1 border border-0">
                            <div dangerouslySetInnerHTML={{ __html: svgnote }} />
                                        <h4>Multiple Products Selection</h4>
                                        <p>Avoid wasting time on sourcing new products</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1 border border-0">
                            <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
                      <h4 >Fast Commission Withdrawal</h4>
                      <p>Takes only 7 working days in maximum</p>
                            </div>
                        </div>
                    </section>
                </div>
                  {/*Service Two*/}
                  <div className="container">
                    <section className="service section-b-space  border-section border-top-0">
                        <div className="row partition4 ">
                            <div className="col-lg-3 col-md-6 service-block1">
                            <div dangerouslySetInnerHTML={{ __html: svgchart }} />
                      <h4 >Build Your Own Customer Database</h4>
                      <p>Make the most of your customers' data!</p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1 ">
                            <div dangerouslySetInnerHTML={{ __html: svgclose }} />                 
                      <h4>Stock Free</h4>
                      <p>No more warehousing problems
                        </p>
                        <p>No more rental & commitment problems
                        </p>
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1 border border-0">
                            <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                      <h4 >Delivery Service</h4>
                      <p>We can pack the product and handle the delivery service for you</p>                
                            </div>
                            <div className="col-lg-3 col-md-6 service-block1 border border-0">
                            <div dangerouslySetInnerHTML={{ __html: svgtag }} />
                      <h4>Additional Bonus</h4>
                      <p>Achieve the sales target in seasonal campaign for certain categories of products to claim the bonus! </p>         
                            </div>
                        </div>
                    </section>
                </div>

                {/*Service Three*/}
                <div className="container">
                    <section className="service section-b-space  border-section border-top-0">
                        <div className="row partition4 ">
                            <div className="col-lg-6 col-md-6 service-block1">
                            <div dangerouslySetInnerHTML={{ __html: svgdollar }} />
                      <h4 >Attractive Commission Scheme</h4>
                      <p>Seller, Gold Seller, Platinum Seller</p>
                            </div>
                            <div className="col-lg-6 col-md-6 service-block1 ">
                            <div dangerouslySetInnerHTML={{ __html: svgchecklist }} />
                      <h4 >Low Entry Level</h4>
                      <p>Any Malaysian can be our seller!</p>
                      <p>Just maintain your seller identity with a minimum total of 5 sales in 6 months</p>
                            </div>
                            {/* <div className="col-lg-3 col-md-6 service-block1 border border-0">
                            <div dangerouslySetInnerHTML={{ __html: svgtag }} />
                      <h4>Additional Bonus</h4>
                      <p>Achieve the sales target in seasonal campaign for certain categories of products to claim the bonus! </p>         
                            </div> */}
                            {/* <div className="col-lg-3 col-md-6 service-block1 border border-0">
                            <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
                      <h4 >Fast Commission Withdrawal</h4>
                      <p>Takes only 7 working days in maximum</p>
                            </div> */}
                        </div>
                    </section>
                </div>


        {/* collection banner layout
        <section className="banner-padding absolute-banner pb-0">
          <div className="container absolute-bg">
            <div className="service p-0">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgstar }} />
                    <div className="media-body">
                      <h4 >Easy to Get Started</h4>
                      <p>You don't have to deal with physical products!</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                    <div className="media-body">
                      <h4>Easy to Manage</h4>
                      <p>You can sell your products to your customers 
                        by just sending them an affiliate link!
                        </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgnote }} />
                    <div className="media-body">
                      <h4 >Multiple Products Selection</h4>
                      <p>Avoid wasting time on sourcing new products</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </section> */}
        {/* <section className="banner-padding absolute-banner pb-0">
          <div className="container absolute-bg">
            <div className="service p-0">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgchart }} />
                    <div className="media-body">
                      <h4 >Build Your Own Customer Database</h4>
                      <p>Make the most of your customers' data!</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgclose }} />
                    <div className="media-body">
                      <h4>Stock Free</h4>
                      <p>No more warehousing problems
                        </p>
                        <p>No more rental & commitment problems
                        </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                  <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                    <div className="media-body">
                      <h4 >Delivery Service</h4>
                      <p>We can pack the product and handle the delivery service for you</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </section>
              <section className="banner-padding absolute-banner pb-0">
          <div className="container absolute-bg">
            <div className="service p-0">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgchecklist }} />
                    <div className="media-body">
                      <h4 >Low Entry Level</h4>
                      <p>Any Malaysian can be our seller!</p>
                      <p>Just maintain your seller identity with a minimum total of 5 sales in 6 months</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgtag }} />
                    <div className="media-body">
                      <h4>Additional Bonus</h4>
                      <p>Achieve the sales target in seasonal campaign for certain categories of products to claim the bonus! </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
                    <div className="media-body">
                      <h4 >Fast Commission Withdrawal</h4>
                      <p>Takes only 7 working days in maximum</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        collection banner layout end */}
        <br></br>
            <Row>
                <Col md="12">
                    <div className="success-text">
                    <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/register-as-dealer`} data-lng="en">
                JOIN NOW
              </Link>
                    </div>
                </Col>
            </Row>
            <br></br>

        {/*Testimonial*/}
        <section className="testimonial small-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Slider {...Slider2} className="slide-2 testimonial-slider no-arrow">
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img src={WarrenBuffett} alt="#" />
                    
                      </div>
                      <div className="media-body">
                        <p>“If you don’t find a way to make money while you sleep, you will work until you die.”</p>
                        <p>- Warren Buffett，the billionaire investor</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="media">
                      <div className="text-center">
                        <img src={Robert} alt="#" />
               
                      </div>
                      <div className="media-body">
                        <p>“To obtain financial freedom, one must be either a business owner, an investor, or both, generating passive income, particularly on a monthly basis.”</p>
                        <p>- Robert Kiyosaki, the businessman and author of Rich Dad Poor Dad</p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const errMsg = {
  color: 'red',
  marginLeft: 6,
  paddingBottom: 5,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
  updateToken: token => dispatch(updateToken(token)),
  refreshProfile: profile => dispatch(refreshProfile(profile)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropshippingInfo);