import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as DOWNLINE from "../../../api/downline";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as AUTH from "../../../api/auth";
import { updateToken, addMyCart,} from "../../../actions/index";
import { connect } from "react-redux";
import SideMenu from "../sidemenu";
import AddressItem from "../address/address-item";
const mobileReg = /^[0-9\b]+$/;

class MemberDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id ? props.match.params.id : 0,
      member: {},
      name: "",
      email: "",
      gender: "",
      mobileno: "",
      failMsg: [],
      imgFailMsg: "",
      updateErr: false,
      isOpen: false,
      avatarFile: null,
      loading: false,
      addressList:[],
      loadAddress:true
    };
    this.inputRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.getDownlineDetails();
    this.getAddressList();
  }

  getAddressList() {
    DOWNLINE.getDownlineAddressList(this.state.id)
      .then((res) => {
        if (res.http_code==200) {
          this.setState(
            {
              addressList: res.data,
              address: true,
              loadAddress: false,
              addressLength: res.data.length,
            },
            () => {
              this.setState({
                isLoading: false,
              });
            }
          );
        } else {
          this.setState({
            address: false,
            loadAddress: false,
          });
        }
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
          this.setState({
            address: false,
            loadAddress: false,
          });
        }
      });
  }

  getDownlineDetails() {
    DOWNLINE.getDownlineDetails(this.state.id)
      .then((res) => {
        this.setState({
          member: res.data,
          name: res.data.name,
          email: res.data.email,
          mobileno: res.data.mobileno,
          loading: false,
        });
      })
      .catch((err) => {
        if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
          AUTH.logout(this.props);
          toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
          this.props.history.replace("/login");
        } else {
          toast.error("Something went wrong, please try again later", { autoClose: 3000, position: "top-center" });
        }
      });
  }

  renderErrorMsg(name) {
    if (this.state.failMsg && this.state.failMsg.find((msg) => msg.name == name) !== undefined)
      return this.state.failMsg.find((msg) => msg.name == name).msg;
    else return null;
  }

  handleSubmit(e) {
    e.preventDefault();
    let valid = true;
    let failMsg = [];
    if (!mobileReg.test(this.state.mobileno)) {
      valid = false;
      failMsg.push({ name: "mobileno", msg: "Mobile Number is invalid" });
    }
    if (valid) {
      this.setState({ failMsg: [] });
      const profileData = new FormData();
      profileData.append("name", this.state.name);
      profileData.append("mobileno", this.state.mobileno);
      if (this.state.avatarFile) profileData.append("images", this.state.avatarFile);

      DOWNLINE.updateDownlineDetails(profileData, this.state.id)
        .then((res) => {
          toast.success("Update Reseller Successful!", { autoClose: 3000, position: "top-center" });
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          } else {
            if (err.data) {
              if (err.data.email) {
                failMsg.push({ name: "name", msg: err.data.name });
              }else if (err.data.mobileno){
                failMsg.push({ name: "mobileno", msg: err.data.mobileno });
              }
            }
            else if (err.message && err.http_code == 422) {
              toast.error(err.message, { autoClose: 3000, position: "top-center" });
            }
          }
        });
    }
    this.setState({failMsg})
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleAvatarChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        avatarFile: file,
        avatarUrl: reader.result,
      });
    };
    reader.onerror = (event) => {
      toast.error("File could not be read ", { autoClose: 2000, position: "top-center" });
    };
    if (file) reader.readAsDataURL(file);
  };

  render() {
    const { updateErr, failMsg, member, loadAddress, loading ,addressList} = this.state;
    const styles = {
      radioButton: {
        margin: "5px 15px",
      },
    };
    return (
      <div>
        <Breadcrumb title={"Members"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab="reseller" />
              </div>
              {loading ? (
                <div className="loading-cls" />
              ) : (
                <div className="col-lg-9">
                  <div className="dashboard-right">
                    <div className="dashboard">
                      <div className="page-title">
                        <h2>Seller Details</h2>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "left" }}>
                          <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/reseller-list`}>
                            <i className="fa fa-chevron-left" />
                            &nbsp; Back
                          </Link>
                        </div>
                      </div>
                      <div className="box-account box-info">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="box">
                              <div className="box-content">
                                <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                  <div className="form-row">
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="email">Name</label>
                                      <input
                                        maxLength={50}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                        value={this.state.name}
                                        type="text"
                                        className="form-control"
                                        id="fname"
                                        placeholder="Name"
                                        required=""
                                      />
                                      {this.renderErrorMsg("name") && this.state.mobileno.length>0 ? (
                                        <div className="text-danger small">{this.renderErrorMsg("name")}</div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-6 mt-3">
                                      <label htmlFor="review">Mobile Number</label>
                                      <input
                                        maxLength={18}
                                        onChange={(e) => this.setState({ mobileno: e.target.value })}
                                        value={this.state.mobileno}
                                        type="number"
                                        className="form-control"
                                        id="mobileno"
                                        placeholder="Mobile Number"
                                        required=""
                                      />
                                      {this.renderErrorMsg("mobileno") && this.state.mobileno.length>0 ? (
                                        <div className="text-danger small">{this.renderErrorMsg("mobileno")}</div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="form-row">
                                    <div className="col-md-6 mt-3 pl-2">
                                      <label htmlFor="email">Email</label>
                                      <p className="mb-0">{this.state.email}</p>
                                    </div>
                                    <div className="col-md-6 mt-3 pl-2">
                                      <label htmlFor="user_type">User Type</label>
                                      <p className="mb-0">{member.acc_type_name}&nbsp; 
                                      {member.dealer_type_name ? 
                                      <>
                                        - {member.dealer_type_name}
                                      </> : null}</p>
                                    </div>
                                    {member.referral ? (
                                      <div className="col-md-6 mt-3 pl-2">
                                        <label htmlFor="joined_at">Referral</label>
                                        <p className="mb-0">
                                          {member.referral.name} - {member.referral.dealer_type_name}
                                        </p>
                                      </div>
                                    ) : null}
                                    <div className="col-md-6 mt-3 pl-2">
                                      <label htmlFor="joined_at">Joined At</label>
                                      <p className="mb-0">{member.joindate}</p>
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="col-md-6 mt-3 pl-2">
                                      <label htmlFor="ic_no">Ic Number</label>
                                      <p className="mb-0">{member.ic_no}</p>
                                    </div>
                                    {
                                      member.ic_file_url ? (
                                      <div className="col-md-6 mt-3 pl-2">
                                        <label htmlFor="ic_image">Ic Image</label>
                                          <div>
                                          {
                                            member.ic_mimetype == 'application/pdf' ?
                                            <a href={member.ic_file_url} target="_blank">Image.pdf</a> :
                                            <img src={member.ic_file_url} style={{ maxWidth: "85%", height: "auto" }} />
                                          }
                                          </div>
                                      </div>
                                      ) :null
                                    }
                                  </div>
                                  <div className="form-row text-right">
                                    <div style={{ paddingLeft: "5px" }} className="mt-3 col-12">
                                      <input type="submit" className="btn btn-solid" value="Update" />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {loadAddress?
                        <div className="loading-cls" />
                        :
                        <div className="row">
                          <div className="col-sm-12">
                          <div className="page-title">
                            <h3>Address List</h3>
                          </div>
                          {addressList.length>0?
                            addressList.map((address, index) => (
                              <div key={index}>
                                  <AddressItem address={address} from={'downline'}/>
                              </div>
                              ))
                          :<h6 className="mt-3">No Address Found</h6>
                          }
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(null, mapDispatchToProps)(MemberDetails);
