import React, { Component } from "react";
import { Link } from "react-router-dom";
import noImg from "../../../../assets/images/defaultProduct.jpg";

const renderName = (item) => {
  return (
    <div style={nameText}>
      {item.name + " "}
      {item.selected_pov
        ? "(" + item.selected_pov.attribute1 + (item.selected_pov.attribute2 ? "+ " + item.selected_pov.attribute2 : "")
        : null}
      {item.selected_pov ? ")" : null}
    </div>
  );
};

const checkInvalid = (item) => {
  if (!item.buyable || item.stockStatus == "13" || item.productQty == 0 || (item.qty>item.productQty&&item.cid)) {
    return true;
  } else return false;
}

const CartHeader = ({ symbol, item, total, removeFromCart, THIS, index }) => (
  <li>
    <div className="media">
      {checkInvalid(item) ? (
        <span className="badge badge-danger p-1 pl-2 pr-2 invalid-item" style={{ left: 0 }}>
          {item.stockStatus == "13" || item.productQty == 0  ? "Out Of Stock" : "Invalid"}
        </span>
      ) : null}
      <Link
        to={`${process.env.PUBLIC_URL}/product/${item.slug}`}
        className={checkInvalid(item) ? "disabled-link" : ""}>
        <img
          alt=""
          className="mr-3"
          ref={(img) => (THIS[`${item.pid + "" + index}_ref`] = img)}
          onError={() => (THIS[`${item.pid + "" + index}_ref`].src = noImg)}
          style={{
            width: "85px",
            objectFit: "scale-down",
          }}
          src={item.image ? item.image : noImg}
        />
      </Link>
      <div className="media-body">
        {checkInvalid(item)? (
          renderName(item)
        ) : (
          <Link style={proNameStyle} to={`${process.env.PUBLIC_URL}/product/${item.slug}`}>
            {renderName(item)}
          </Link>
        )}
        <h6>
          <span>
            {item.qty} x {symbol} {item.finalPrice}
          </span>
        </h6>
      </div>
    </div>
    {/*<span>{cart}</span>*/}
    <div className="close-circle">
      <a href={null} onClick={removeFromCart}>
        <i className="fa fa-times" aria-hidden="true" />
      </a>
    </div>
  </li>
);

const nameText = {
  overflow: "hidden",
  fontSize: 13,
  lineHeight:1.2
};

const proNameStyle = {
  color: "#333333",
};

export default CartHeader;
