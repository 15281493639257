import { UPDATE_AUTH } from "../constants/ActionTypes";


const initialState = {
    token:  localStorage.getItem('token'),
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_AUTH:
            return { ...state, token: action.token };
        default:
            return state;
    }
};
export default authReducer;