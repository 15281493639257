import React, { Component } from 'react';
import Breadcrumb from "../../common/breadcrumb";
import * as Profile from '../../../api/profile';
import * as AUTH from '../../../api/auth';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { updateToken, addMyCart } from '../../../actions/index'
import { connect } from "react-redux";
import SideMenu from '../sidemenu';
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const re = /^\d*(\.\d{1,2})?$/;
const now = new Date();
const defaultEnd = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
const defaultStart = moment(defaultEnd).subtract(1, "month");
const customTableStyles = {
    headCells: {
        style: {
            paddingLeft: "8px",
            paddingRight: "8px",
            fontSize: "14px",
            fontWeight: "600",
        },
    },
    cells: {
        style: {
            paddingLeft: "8px",
            paddingRight: "8px",
        },
    },
    header: {
        style: {
            minHeight: "10px",
        },
    },
};

const columns = [
    {
        name: "Transaction Date",
        selector: "createdAt",
        sortable: true,
        wrap: true,
        compact: false,
        grow: 2,
    },
    {
        name: "Description",
        selector: "description",
        sortable: false,
        wrap: true,
        compact: false,
        grow: 2,
    },
    {
        name: "Amount",
        selector: "amount",
        sortable: false,
        compact: false,
        wrap: true,
        style: {
            textAlign: 'right',
            fontWeight: 'bold'
        }
    },
    {
        name: "Attachement",
        selector: "file",
        sortable: false,
        compact: false,
        wrap: true,
        style: {
            textAlign: 'right',
            fontWeight: 'bold'
        }
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        compact: false,
        wrap: true,
        grow: 0,
        cell: row => <div className={getBadgeClass(row.status)}>{row.status}</div>,
    },
];

const getBadgeClass = (status) => {
    if (status == 'Succeed')
        return "badge badge-success p-1"
    else if (status == 'Failed')
        return "badge badge-danger p-1"
    else if (status == 'Pending')
        return "badge badge-info p-1"
}

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            secPassword: '',
            failMsg: '',
            topUpAmt: '',
            ewAmt: '',
            refundAmt: '',
            tpAmt: '',
            paymentUrl: '',
            paymentData: {},
            topUpErr: false,
            currentPage: 1,
            perPage: 10,
            startDate: defaultStart,
            endDate: defaultEnd,
            recordList: [],
            imageFile: "",
            imageFileUrl: "",
        }
    }

    componentWillMount() {
        this.getWalletAmt();
        this.getWalletHistory();
    }

    handleEvent(event, picker) {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
    }

    handlePageClick = (page) => {
        this.setState(
            {
                currentPage: page,
            },
            () => {
                this.getWalletHistory();
            }
        );
    };

    handlePerPageChanges = (perPage) => {
        this.setState(
            {
                perPage: perPage,
            },
            () => {
                this.getWalletHistory();
            }
        );
    };

    filterList() {
        this.setState({ currentPage: 1, filterLoading: true, recordList: [] }, () => {
            this.getWalletHistory();
        });
    }

    clearFilter() {
        this.setState({
            per_page: 10,
            page: 1,
            startDate: defaultStart,
            endDate: defaultEnd,
            loading: true
        }, () => this.getWalletHistory())
    }

    getWalletAmt() {
        Profile.getWallet().then((res) => {
            if (!res.errors) {
                this.setState({
                    ewAmt: res.data.ewallet,
                    tpAmt: res.data.topup,
                    refundAmt: res.data.refund
                })
            }
        }).catch(err => {
            if (err.http_code == 401 || (err.http_code == 403 && err.message == 'token expired')) {
                AUTH.logout(this.props)
                toast.error("Session Expired", { autoClose: 3000, position: 'top-center' });
                this.props.history.replace('/login')
            }
        })
    }

    getWalletHistory() {
        let params = {
            per_page: this.state.perPage,
            page: this.state.currentPage,
            start_date: moment(this.state.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.state.endDate).add(1, "days").format("YYYY-MM-DD"),
        };
        Profile.getWalletHistory(params)
            .then((res) => {
                if (res.data) {
                    this.setState(
                        {
                            recordList: res.data,
                            pagination: res.pagination,
                            loading: false,
                            filterLoading: false,
                        },
                        () => {
                            if (this.state.firstLoad) this.setState({ firstLoad: false });
                        }
                    );


                } else {
                    this.setState(
                        {
                            loading: false,
                            filterLoading: false,
                            noRecord: this.state.firstLoad ? true : false,
                        },
                        () => {
                            if (this.state.firstLoad) this.setState({ firstLoad: false });
                        }
                    );
                }
            })
            .catch((err) => {
                if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
                    AUTH.logout(this.props);
                    toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
                    this.props.history.replace("/login");
                } else {
                    this.setState(
                        {
                            loading: false,
                            filterLoading: false,
                            noRecord: true,
                        }
                    );
                }
            });
    }

    updateValue(e) {
        this.setState({
            topUpAmt: e
        })
    }

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                imageFile: file,
                imageFileUrl: reader.result,
            });
        };
        if (file) reader.readAsDataURL(file);
    };


    handleRemoveImage = (e) => {
        this.setState({
            imageFile: "",
            imageFileUrl: "",
        });
    };

    handleSubmit(e) {
        e.preventDefault();
        const topUpData = new FormData();
        let amt = parseFloat(this.state.topUpAmt).toFixed(2);
        let valid = true
        if (!re.test(amt)) {
            valid = false
            this.setState({
                topUpErr: true,
                failMsg: 'Top up amount format incorrect, only integer and amount up to 2 decimal point are allowed. (Ex: 10.50)'
            })
        }
        // if (amt < 10.00) {
        //     valid = false
        //     this.setState({
        //         topUpErr: true,
        //         failMsg: 'Required a minimum top up amount of RM 10.'
        //     })
        // }
        if (valid === true) {
            if (!this.state.imageFile) {
                topUpData.append('amount', amt);
                topUpData.append('to_wallet_type', 'ewallet');
                topUpData.append('payment_channel', 'kp');
                topUpData.append('ttype', '10');
                topUpData.append('uid', 'own');

                Profile.topUpWallet(topUpData).then((res) => {
                    this.setState({
                        topUpAmt: '',
                        paymentUrl: res.data.url,
                        paymentData: res.data.data
                    }, () => {
                        this.submitBtn.click();
                    })
                }).catch(err => {
                    console.log(err);
                    if (err.http_code == 401 || (err.http_code == 403 && err.message == 'token expired')) {
                        AUTH.logout(this.props)
                        toast.error("Session Expired", { autoClose: 3000, position: 'top-center' });
                        this.props.history.replace('/login')
                    }
                })
            } else {
                topUpData.append('amount', amt);
                topUpData.append('to_wallet_type', 'ewallet');
                topUpData.append('payment_channel', 'b');
                topUpData.append('ttype', '10');
                topUpData.append('uid', 'own');
                topUpData.append('image', this.state.imageFile);

                Profile.topUpWallet(topUpData).then((res) => {
                    this.setState({
                        topUpAmt: '',
                        paymentUrl: '',
                        paymentData: res.data.data
                    }, () => {
                        window.location.reload();
                    })
                }).catch(err => {
                    console.log(err);
                    if (err.http_code == 401 || (err.http_code == 403 && err.message == 'token expired')) {
                        AUTH.logout(this.props)
                        toast.error("Session Expired", { autoClose: 3000, position: 'top-center' });
                        this.props.history.replace('/login')
                    }
                })
            }
        }
    }



    render() {
        const { ewAmt, tpAmt, refundAmt, paymentData, topUpErr, failMsg, noRecord, startDate, endDate,
            filterLoading, recordList, pagination, imageFileUrl } = this.state
        const tbData = [];
        recordList.map((record, index) => {
            var amount = record.amount;
            if (record.type == 'a' || record.type == 'c') {
                var amount = '+ ' + record.amount;
            } else {
                var amount = '- ' + record.amount;
            };
            var file = '';
            if (record.file_url) {
                file = <a target="_blank" href={record.file_url}>View Slip</a>
            } else {
                file = '-';
            }
            tbData.push({
                description: record.descr,
                amount: amount,
                createdAt: moment(record.cdate).format("YYYY-MM-DD hh:mm:ss A"),
                status: record.status == 1 ? 'Succeed' : record.status == 2 ? 'Failed' : record.status == 3 ? 'Pending' : '-',
                file: file
            });
        });
        var websiteTitle = localStorage.getItem("website");
        var seoTitle = "Wallet | " + websiteTitle + " Member Area";
        var seoDesc = "";
        var seoImage = process.env.BASE_URL + "/logo.png";
        return (
            <div>
                <Helmet>
                    <meta property="og:image" content={seoImage} data-react-helmet="true" />
                    <meta property="og:title" itemprop="name" content={seoTitle} />
                    <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
                    <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

                    <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
                    <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
                    <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
                    <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

                    <meta property="description" content={seoDesc} data-react-helmet="true" />
                    <meta property="title" content={seoTitle} data-react-helmet="true" />
                    <title>{seoTitle}</title>
                </Helmet>
                <Breadcrumb title={'Credit'} />
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <SideMenu activeTab={'credit'} />
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>My Credit</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>You may view your credit information and top up your credit here.</p>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="box">
                                                        <div className="box-title"></div>
                                                        <div className="box-content">
                                                            <div className="row" style={{ marginTop: "10px" }}>
                                                                <div style={{ textAlign: "center" }} className="col-lg-6 col-12">
                                                                    <div>
                                                                        <p style={{ marginBottom: "8px" }}>Amount (RM)</p>
                                                                        <h2>{ewAmt}</h2>
                                                                    </div>
                                                                </div>
                                                                <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }} className="col-lg-3 col-6">
                                                                    <div className="theme-border-box" style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                                                                        <p style={{ marginBottom: "5px" }}>Top Up (RM)</p>
                                                                        <h4 style={{ margin: "0px" }}>{tpAmt}</h4>
                                                                    </div>
                                                                </div>
                                                                <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px", paddingLeft: "0px" }} className="col-lg-3 col-6">
                                                                    <div style={{ border: "1px solid #ffcb04", paddingTop: "8px", paddingBottom: "8px" }}>
                                                                        <p style={{ marginBottom: "5px" }}>Refund (RM)</p>
                                                                        <h4 style={{ margin: "0px" }}>{refundAmt}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                                                <div style={orderBox}>
                                                                    <h5>Top Up Credit</h5>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                            <input disabled onChange={(e) => this.setState({ topUpAmt: e.target.value })} className="form-control" value={this.state.topUpAmt} id="topUpAmt"
                                                                                placeholder="Enter preferred amount" required="" />
                                                                            <p style={{ marginBottom: "0px" }}>Minimum top up amount is RM 50</p>
                                                                            {topUpErr ?
                                                                                <div style={errMsg}>{failMsg}</div> : null
                                                                            }
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                            <div className="row" style={{ marginLeft: "3px" }}>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(1)} style={topUpBtn1}>RM 1</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(50)} style={topUpBtn1}>RM 50</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(100)} style={topUpBtn1}>RM 100</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(150)} style={topUpBtn1}>RM 150</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(200)} style={topUpBtn1}>RM 200</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(300)} style={topUpBtn1}>RM 300</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(400)} style={topUpBtn1}>RM 400</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(500)} style={topUpBtn1}>RM 500</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(1000)} style={topUpBtn1}>RM 1000</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(2000)} style={topUpBtn1}>RM 2000</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(5000)} style={topUpBtn1}>RM 5000</button>
                                                                                <button type="button" className="text-theme theme-border-box" onClick={(e) => this.updateValue(10000)} style={topUpBtn1}>RM 10000</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <input
                                                                            hidden
                                                                            ref={(inputRef1) => (this.inputRef1 = inputRef1)}
                                                                            accept="image/png,image/jpeg,image/jpg"
                                                                            style={{ marginLeft: "5px", marginBottom: "5px" }}
                                                                            type="file"
                                                                            onChange={(e) => this.handleImageChange(e)}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "100%" }}>
                                                                        {imageFileUrl ? (
                                                                            <img
                                                                                style={{ border: "1px solid #ddd", height: "200px", maxWidth: "100%" }}
                                                                                alt="pay-slip"
                                                                                src={imageFileUrl}
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                    <button
                                                                        style={uploadbtn}
                                                                        type="button"
                                                                        onClick={() => this.inputRef1.click()}
                                                                        className="btn btn-solid mr-2">
                                                                        Upload Bank Transfer Slip
                                                                    </button>
                                                                    <button
                                                                        hidden={!imageFileUrl}
                                                                        style={uploadbtn}
                                                                        onClick={() => this.handleRemoveImage()}
                                                                        type="submit"
                                                                        className="btn btn-solid">
                                                                        Remove
                                                                    </button>
                                                                    <div className="form-row">
                                                                        <div style={{ paddingLeft: '5px' }} className="mt-3">
                                                                            <input type="submit" className="btn btn-solid" value="Confirm" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="row align-items-center">
                                            <div className=" col-6 col-md-8">
                                                <div className="page-title mb-2">
                                                    <h2>History</h2>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-4 text-right mb-2">
                                            </div>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>All your credit histories will display here.</p>
                                        </div>
                                        <hr />
                                        {this.state.loading ? (
                                            <div className="loading-cls" />
                                        ) : (
                                            <>
                                                <div className="row table-filter" hidden={noRecord}>
                                                    <div className="col">
                                                        <div>Date Range :</div>
                                                        <DateRangePicker
                                                            onEvent={(e, picker) => this.handleEvent(e, picker)}
                                                            onApply={() => this.filterList()}

                                                            startDate={startDate}
                                                            endDate={endDate}>
                                                            <input
                                                                className="dateRange-select-box c-pointer mr-3"
                                                                readOnly
                                                                value={startDate.format("DD-MM-YYYY") + "   to   " + endDate.format("DD-MM-YYYY")}
                                                            />
                                                        </DateRangePicker>
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn btn-solid medium" onClick={() => this.clearFilter()}>
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>

                                                {filterLoading ? (
                                                    <div className="loading-cls" />
                                                ) : recordList.length > 0 ? (
                                                    <DataTable
                                                        data={tbData}
                                                        columns={columns}
                                                        theme="solarized"
                                                        customStyles={customTableStyles}
                                                        striped
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={pagination.total}
                                                        onChangeRowsPerPage={this.handlePerPageChanges}
                                                        onChangePage={this.handlePageClick}
                                                    />
                                                ) : (
                                                    <h6 className="mt-4">No Wallet Record Found</h6>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* pass Kiple payment use */}
                {this.state.paymentUrl ?
                    <>
                        <form method="post" action={this.state.paymentUrl}>
                            <input type="hidden" name="ord_mercID" value={paymentData.ord_mercID} />
                            <input type="hidden" name="ord_date" value={paymentData.ord_date} />
                            <input type="hidden" name="ord_totalamt" value={paymentData.ord_totalamt} />
                            <input type="hidden" name="ord_gstamt" value={paymentData.ord_gstamt} />
                            <input type="hidden" name="ord_shipname" value={paymentData.ord_shipname} />
                            <input type="hidden" name="ord_shipcountry" value={paymentData.ord_shipcountry} />
                            <input type="hidden" name="ord_mercref" value={paymentData.ord_mercref} />
                            <input type="hidden" name="ord_telephone" value={paymentData.ord_telephone} />
                            <input type="hidden" name="ord_email" value={paymentData.ord_email} />
                            <input type="hidden" name="ord_delcharges" value={paymentData.ord_delcharges} />
                            <input type="hidden" name="ord_svccharges" value={paymentData.ord_svccharges} />
                            <input type="hidden" name="ord_customfield1" value={paymentData.ord_customfield1} />
                            <input type="hidden" name="ord_customfield2" value={paymentData.ord_customfield2} />
                            <input type="hidden" name="ord_returnURL" value={paymentData.ord_returnURL} />
                            <input type="hidden" name="merchant_hashvalue" value={paymentData.merchant_hashvalue} />
                            <input hidden ref={btn => this.submitBtn = btn} type="submit" value="Submit" />
                        </form>
                    </> : null}

            </div >
        )
    }

}

const topUpBtn1 = {
    padding: "7px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "30px",
    margin: "3px 5px"
}

const topUpBtn2 = {
    padding: "7px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "30px",
    marginRight: "8px"
}

const topUpBtn3 = {
    padding: "7px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "white",
    borderRadius: "30px",
}

const errMsg = {
    color: 'red',
    paddingBottom: 8,
}

const orderBox = {
    marginTop: '20px',
    border: '1px solid #dddddd',
    padding: '15px',
    marginBottom: '18px'
}

const confirmBtn = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "10px",
    marginTop: "10px"
}

const uploadbtn = {
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "10px",
};

const mapDispatchToProps = dispatch => ({
    updateToken: token => dispatch(updateToken(token)),
    addMyCart: cart => dispatch(addMyCart(cart))
})

export default connect(null, mapDispatchToProps)(Wallet);