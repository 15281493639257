import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import * as GENERAL from "../../api/general";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const stateArr = GENERAL.getStateList();
const cookies = new Cookies();

class ShippingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            shipping: 0,
            billing: 0,
            street1: "",
            street2: "",
            city: "",
            zip: "",
            state: "",
            country: "MY",
            addErr: false,
            failMsg: "",
            addressData: "",
            availabilityErrMsg: ""
        };
    }

    renderErrorMsg(name) {
        if (this.state.failMsg && this.state.failMsg.find((msg) => msg.name == name) !== undefined) return this.state.failMsg.find((msg) => msg.name == name).msg;
        else return null;
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let addressData = {};
        addressData.shipping_street1 = this.state.street1;
        addressData.shipping_street2 = this.state.street2;
        addressData.shipping_city = this.state.city;
        addressData.shipping_zip = this.state.zip;
        addressData.shipping_state = this.state.state;
        addressData.shipping_country = this.state.country;
        GENERAL.checkShippingAddressAvailability(addressData).then(res => {
            addressData.availability = true;
            localStorage.setItem("shipping", JSON.stringify(addressData));
            this.setState({
                availabilityErrMsg: null
            })
            toast.success("Destination within coverage area", { autoClose: 3000, position: "top-center" });
            this.onCloseModal();
        }).catch(err => {
            this.setState({
                availabilityErrMsg: err.message
            })
        })
    }

    render() {

        return (

            (cookies.get('partner-referral') ? null :

                <Modal closeOnOverlayClick={false} open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-dialog-centered " role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body p-3 pl-4 pr-4">
                                <div className="page-title">
                                    <h3 style={{ color: "#222" }}>Check Delivery Address</h3>
                                </div>
                                <div className="welcome-msg"><p>Whether your address is within our service coverage area.</p></div>
                                {this.state.availabilityErrMsg ?
                                    <div style={{ border: "1px solid #d20c0c", backgroundColor: "#ffdbdb" }}>
                                        <p style={{ marginBottom: 0, padding: "10px", color: "#d20c0c" }}>{this.state.availabilityErrMsg}</p>
                                    </div>
                                    : null}
                                <div className="box-account box-info">
                                    <div className="box-title" />
                                    <div className="box-content">
                                        <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                                            <div className="form-row" style={{ marginTop: "15px" }}>
                                                <div className="col-md-6" style={{ marginBottom: 12 }}>
                                                    <label htmlFor="street1">Street 1</label>
                                                    <input
                                                        onChange={(e) => this.setState({ street1: e.target.value })}
                                                        value={this.state.street1}
                                                        type="text"
                                                        className="form-control"
                                                        id="street1"
                                                        placeholder="18, Jalan Example"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="street2">Street 2</label>
                                                    <input onChange={(e) => this.setState({ street2: e.target.value })} value={this.state.street2} type="text" className="form-control" id="street2" placeholder="Optional" />
                                                </div>
                                            </div>
                                            <div className="form-row" style={{ marginTop: "15px" }}>
                                                <div className="col-md-6" style={{ marginBottom: 12 }}>
                                                    <label htmlFor="city">City</label>
                                                    <input onChange={(e) => this.setState({ city: e.target.value })} value={this.state.city} type="text" className="form-control" id="city" placeholder="Kepong" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="zip">Zip</label>
                                                    <input onChange={(e) => this.setState({ zip: e.target.value })} value={this.state.zip} type="text" className="form-control" id="zip" placeholder="52100" required />
                                                    {this.renderErrorMsg("zip") && this.state.zip.length > 0 ? <div className="text-danger small">{this.renderErrorMsg("zip")}</div> : null}
                                                </div>
                                            </div>
                                            <div className="form-row" style={{ marginTop: "15px" }}>
                                                <div className="col-md-6" style={{ marginBottom: 12 }}>
                                                    <label htmlFor="state">State</label>
                                                    <select onChange={(e) => this.setState({ state: e.target.value })} value={this.state.state} class="form-control" id="state">
                                                        {stateArr.map((state, index) => (
                                                            <option key={index} value={Object.keys(state)}>
                                                                {Object.values(state)}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {this.renderErrorMsg("state") && this.state.state == "" ? <div className="text-danger small">{this.renderErrorMsg("state")}</div> : null}
                                                </div>
                                            </div>
                                            <div hidden={true} className="form-row" style={{ marginTop: "15px" }}>
                                                <div className="col-md-6" style={{ marginBottom: 12 }}>
                                                    <label for="shipping">Default Shipping Address:</label>
                                                    <select required onChange={(e) => this.setState({ shipping: e.target.value })} value={this.state.shipping} class="form-control" id="shipping">
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                                <div hidden={true} className="col-md-6">
                                                    <label for="billing">Default Billing Address:</label>
                                                    <select required onChange={(e) => this.setState({ billing: e.target.value })} value={this.state.billing} className="form-control" id="billing">
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <Link to={`${process.env.PUBLIC_URL}/login`} style={{ position: "absolute", bottom: "10px" }}>
                                                        Login Now
                                </Link>
                                                </div>
                                                <div className="col-6" style={{ textAlign: "right" }}>
                                                    <button style={{ marginTop: "20px" }} type="submit" className="btn btn-solid">
                                                        Confirm
                                </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        );
    }
}

const qtyInput = {
    border: "1px solid #ced4da",
    fontSize: 14,
};

const btnStyle = {
    width: "90%",
    color: "white",
    paddingLeft: 10,
    paddingRight: 10,
    border: 0,
};

const optionStyles = {
    padding: "8px",
};

export default ShippingModal;
