import React, { Component } from "react";
import "../common/index.scss";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as PRODUCTS from "../../api/product";
// import custom Components
import RelatedProduct from "./relatedProduct";
import Breadcrumb from "../common/breadcrumb";
import DetailsTopTabs from "./details-top-tabs";
import { addToCart, addToCartUnsafe,updateToken,addMyCart } from "../../actions";
import ImageZoom from "./common/product/image-zoom";
import noImg from "../../assets/images/defaultProduct.jpg";
import DetailsWithPrice from "./details-price";
import SmallImages from './common/product/small-image'
import * as GENERALS from "../../api/general";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      id: props.match.params.id ? props.match.params.id : 0,
      product: null,
      loading: true,
      categoryLv:[],
      ref: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        loading:true,
        product:null,
        id:this.props.match.params.id,
      },()=>{
        this.getProducts()
      })
    }
  }

  componentDidMount() {
    let query = new URLSearchParams(this.props.location.search);
    this.ref = query.get("ref");
    if (this.ref) {
      this.getReferralDetails();
    }
    this.getProducts()
  }

  getReferralDetails() {
    GENERALS.getReferralDetails(this.ref)
      .then((res) => {
        if (res) {
          if (typeof res.referral === "object" && res.referral !== null) {
            localStorage.setItem("referral", JSON.stringify(res.referral));
          }
        }
      })
      .catch((err) => {
    });
  }

  getProducts(){
    PRODUCTS.getProduct(this.state.id, this.ref)
      .then((res) => {
        if (res) {
          var websiteTitle = localStorage.getItem("website");
          this.setState({
            product: res[0],
            selectedImg:res[0].image ? res[0].image[0].filename : null,
            categoryLv:res[0].category,
            loading: false,
            seoTitle: res[0].name + " | " + websiteTitle,
            seoDesc: "Buy " + res[0].name + " at " + websiteTitle
          });

          if (typeof res[0].referral === "object" && res[0].referral !== null) {
            localStorage.setItem("referral", JSON.stringify(res[0].referral));
          }
        }
      })
      .catch((err) => {
        this.props.history.replace('/404-not-found')
      });
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { symbol, addToCart, addToCartUnsafe,history,isDealer } = this.props;
    const { product, loading,categoryLv,selectedImg, seoTitle, seoDesc } = this.state;
    var productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      lazyLoad: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };

    return (
      <div>
        {!loading && product ? (
          <>
          <Helmet>
            <meta property="og:image" content={selectedImg} data-react-helmet="true" />
            <meta property="og:title" itemprop="name" content={seoTitle} />
            <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
            <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

            <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
            <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
            <meta name="twitter:image" content={selectedImg} data-react-helmet="true" />
            <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

            <meta property="description" content={seoDesc} data-react-helmet="true" />
            <meta property="title" content={seoTitle} data-react-helmet="true" />
            <title>{ seoTitle }</title>
          </Helmet>
           <Breadcrumb  parent={categoryLv} title={product.name} />
            <section>
              <div className="collection-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 product-thumbnail">
                      {product.image?
                        <ImageZoom
                          onError={() => this.img.src = noImg}
                          image={selectedImg} className="img-fluid image_zoom_cls-0" />
                        :
                        <ImageZoom
                          image={noImg} className="img-fluid image_zoom_cls-0" />
                      }
                      {product.image && product.image.length>1?
                        <SmallImages item={product} settings={productsnav} navOne={this.state.nav1}
                          updateSelectedImg={(url)=>this.setState({selectedImg:url})}/>
                      :null}
                    </div>
                    <DetailsWithPrice
                      symbol={symbol}
                      item={product}
                      history={history}
                      isDealer={isDealer}
                      navOne={this.state.nav1}
                      addToCartClicked={(prod, qty)=>addToCart(prod, qty,this.props)}
                      BuynowClicked={addToCartUnsafe}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="tab-product m-2" style={descBox}>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    <DetailsTopTabs item={product} symbol={symbol} isDealer={isDealer}/>
                  </div>
                </div>
              </div>
            </section>
            <RelatedProduct PROPS={this.props} itemID={product.id}/>
          </>
        ) : (
            <div className="loading-cls" style={loadingBar}></div>
        )}
      </div>
    );
  }
}

const descBox={
  paddingBottom:20
}

const loadingBar = {
  marginTop: '15%',
  marginBottom:'15%'
}

const mapStateToProps = (state) => {
  return {
    symbol: state.data.symbol,
    user:state.data.user,
    isDealer: state.data.user.role && state.data.user.role !== 0 ? true : false,
  };
};

export default connect(mapStateToProps,{updateToken, addMyCart,addToCart, addToCartUnsafe })(ProductDetails);
