import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { addToCart, updateToken, addMyCart } from "../../actions/index";
import ProductItem from "../products/product-item";
import * as PRODUCTS from "../../api/product";
import { convertToCartItem } from "../../services/index";
import loading from "../../assets/images/loader.gif";

class SpecialProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      productList: [],
      tab: ["is_promotion", "is_featured", "is_latest"],
      currentTab: 0,
      currentPage: 1,
      moreLoading: false,
      noResult:false,
    };
  }

  componentWillMount() {
    this.getProductList();
  }

  getProductList() {
    let tab = this.state.currentTab;
    let params = {
      per_page: 12,
      page: this.state.currentPage,
      status: 1,
      sort_type: "desc",
      sort_by: "id"
    };
    if (tab == 0) params.is_promotion = 1;
    else if (tab == 1) params.is_featured = 1;
    else params.is_latest = 1;
    PRODUCTS.getProductList(params)
      .then(res => {
        if(res.data){
          this.setState(
            {
              productList: this.state.currentPage == 1 ? res.data? res.data:[] : [...this.state.productList, ...res.data],
              totalPage: res.pagination.total_page
            },
            () => {
              this.setState({
                isLoading: false,
                moreLoading: false
              });
            }
          );
        }else{
          this.setState({
            noResult: true,
            isLoading: false,
            moreLoading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          moreLoading: false
        });
        console.log(err);
      });
  }

  fetchMore() {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
        moreLoading: true
      },
      () => {
        this.getProductList();
      }
    );
  }

  changeTab(tab) {
    if (tab !== this.state.currentTab) {
      this.setState({ currentTab: tab, currentPage: 1, productList: [] }, () => {
        this.getProductList();
      });
    }
  }

  viewMoreContent = () => {
    return (
      <>
        {this.state.moreLoading ? (
          <div className="container" style={{ textAlign: "center" }}>
            <img src={loading} style={loaderStyle} alt="" />
          </div>
        ) : this.state.currentPage !== this.state.totalPage ? (
          <div className="container text-theme" style={getMoreStyle} onClick={() => this.fetchMore()}>
            <b>
              view more <i className="fa fa-angle-double-down" aria-hidden="true" />
            </b>
          </div>
        ) : null}
      </>
    );
  };

  render() {
    const { productList,noResult } = this.state;
    const { addToCart, title, subtitle, symbol,history } = this.props;
    return (
      <div style={{minHeight:'300px',justifyContent:'center'}} >
        <div className="title1" >
          {subtitle ? <h4>{subtitle}</h4> : ""}
          <h2 className="title-inner1">
            {title}
          </h2>
        </div>
        {this.state.isLoading && productList ? (
          <div className="loading-cls" />
        ) : (
          <section className="section-b-space addtocart_count" style={{paddingTop:0}}>
            <div className="container">
              <Tabs className="theme-tab">
                <TabList className="tabs tab-title">
                  <Tab onClick={() => this.changeTab(0)}>Promotion</Tab><span> | </span>
                  <Tab onClick={() => this.changeTab(1)}>Featured Products</Tab><span> | </span>
                  <Tab onClick={() => this.changeTab(2)}>New Products</Tab>
                </TabList>
                <TabPanel>
                  <div className="no-slider row">
                    {productList.map((product, index) => (
                      <ProductItem
                        product={product}
                        symbol={symbol}
                        history={history}
                        updateToken={updateToken}
                        onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                        key={index}
                      />
                    ))}
                    {productList.length == 0 ? (
                       noResult?
                       <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                     :
                      <div className="container" style={loadingContainer}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : (
                      this.viewMoreContent()
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="no-slider row">
                    {productList.map((product, index) => (
                      <ProductItem
                        product={product}
                        symbol={symbol}
                        history={history}
                        updateToken={updateToken}
                        onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                        key={index}
                      />
                    ))}
                    {productList.length == 0 ? (
                       noResult?
                       <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                     :
                      <div className="container" style={loadingContainer}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : (
                      this.viewMoreContent()
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className=" no-slider row">
                    {productList.map((product, index) => (
                      <ProductItem
                        product={product}
                        updateToken={updateToken}
                        history={history}
                        symbol={symbol}
                        onAddToCartClicked={(product, qty) => addToCart(convertToCartItem(product), qty ? qty : 1, this.props.PROPS)}
                        key={index}
                      />
                    ))}
                    {productList.length == 0 ? (
                      noResult?
                        <div className="container" style={{textAlign:'center'}}>No Product Found</div>
                      :
                      <div className="container" style={loadingContainer}>
                        <img src={loading} style={loaderStyle} alt="" />
                      </div>
                    ) : (
                      this.viewMoreContent()
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const loadingContainer = {
  textAlign: "center",
  minHeight:'400px',
  justifyContent:'center'
}

const loaderStyle = {
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  margin: "0 auto",
  marginTop:'200px'
};

const getMoreStyle = {
  textAlign: "center",
  fontSize: 20,
  cursor: "pointer",
};

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol
  };
};

export default connect(
  mapStateToProps,{ addToCart, updateToken, addMyCart })(SpecialProducts);
