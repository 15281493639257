import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import * as AUTH from "../../api/auth";
import * as CART from "../../api/cart";
import { updateToken, refreshProfile, saveUser } from "../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginErr: false,
      failMsg: "",
      from: props.location.state ? props.location.state.from : "",
      showPassword: false,
    };
  }

  componentDidMount() {
    if (this.props.token) this.props.history.replace("/");
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loginErr: false });
    let loginData = {
      email: event.target.email.value,
      password: event.target.password.value,
    };
    AUTH.login(loginData)
      .then((res) => {
        toast.success("Login Successful!", { autoClose: 3000, position: "top-center" });
        this.props.updateToken(res.data.access_token);
        this.props.saveUser({ id: res.data.id, name: res.data.fname, role: res.data.dealer ? res.data.dealer : 0 });
        this.props.refreshProfile(true);
        CART.getLatestCart();
        if (this.state.from) this.props.history.replace("/checkout");
        else this.props.history.replace("/");
      })
      .catch((err) => {
        let failMsg = "Invalid username/password";
        this.setState({
          loginErr: true,
          failMsg: failMsg,
        });
      });
  }

  render() {
    const { loginErr, failMsg, showPassword } = this.state;
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Login | " + websiteTitle;
    var seoDesc = "Login into " + websiteTitle;
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{ seoTitle }</title>
        </Helmet>
        <Breadcrumb title={"Login"} />

        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>Login</h3>
                <div className="theme-card">
                  <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" className="form-control" id="email" placeholder="Email" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <img
                        className="password-icon login"
                        onClick={() => this.setState({ showPassword: !showPassword })}
                        src={
                          showPassword
                            ? `${process.env.PUBLIC_URL}/assets/images/icon/hide.png`
                            : `${process.env.PUBLIC_URL}/assets/images/icon/eye.png`
                        }
                        alt=""
                      />
                      <input
                        type={showPassword?"text":"password"}
                        className="form-control"
                        id="password"
                        style={{ paddingRight: 37 }}
                        placeholder="Enter your password"
                        required
                      />
                    </div>
                    {loginErr ? <p className="text-danger mb-3">{failMsg}</p> : null}
                    <button type="submit" className="btn btn-solid">
                      Login
                    </button>
                    <div style={link}>
                      <Link to={`${process.env.PUBLIC_URL}/forget-password`} data-lng="en">
                        Forget Password
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div className="theme-card authentication-right">
                  <h6 className="title-font">Create An Account</h6>
                  <p>
                    Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order
                    from our shop. To start shopping click register.
                  </p>
                  <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/register`} data-lng="en">
                    Create an Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const errMsg = {
  color: "red",
  marginLeft: 6,
  paddingBottom: 8,
};

const link = {
  textAlign: "right",
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  refreshProfile: (profile) => dispatch(refreshProfile(profile)),
  saveUser: (user) => dispatch(saveUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
