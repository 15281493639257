import * as AUTH from './auth'
const axios = require("axios");

export async function getOrderList(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/orders?'
  if (params.per_page)
    url += "&per_page=" + params.per_page
  if (params.page)
    url += "&page=" + params.page
  if (params.status)
    url += "&status=" + params.status
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getOrderDetail(refno) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/order/' + refno
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function placeOrder(data) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/checkout/place-order'
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}


// export async function getShippingFee() {
//   let url=process.env.API_URL + process.env.API_PREFIX  +'/checkout/shipping_fee'
//     return new Promise((resolve, reject) => {
//     axios
//       .get(url,{
//         headers: {
//           Accept: "application/json",
//           token : AUTH.getAccessToken()
//         }
//       })
//       .then(response => {
//         const { data } = response;
//         resolve(data);
//       })
//       .catch(error => {
//         if (error.response) {
//           if (error.response.data) {
//             reject(error.response.data);
//           }
//         }
//       })
//   });
// }

export async function getPaymentMethod() {
  let url = process.env.API_URL + process.env.API_PREFIX + '/payment/method'
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          token: AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function uploadPaySlip(data, id) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX + "/order/upload_payslip/" + id, data, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function checkShipping() {
  let url = process.env.API_URL + process.env.API_PREFIX + '/shipping/distance'
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function cancelOrder(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX + "/order/cancel/" + id, null, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function orderReceived(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX + "/order/receive/" + id, null, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getEligibleShippingMethod(data) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/checkout/shipping-list'
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function calculateShippingFee(data) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/checkout/shipping-fee'
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getShippingTimeSlot() {
  let url = process.env.API_URL + process.env.API_PREFIX + '/checkout/shipping-time-slot'
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          token: AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getDealerSetting() {
  let url = process.env.API_URL + process.env.API_PREFIX + '/checkout/dealer-setting'
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function showOrderDetail(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/show-order?'
  if (params.order_num)
    url += "&order=" + params.order_num
  if (params.email)
    url += "&email=" + params.email
  if (params.key)
    url += "&key=" + params.key
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function guestUploadPaySlip(data, id) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/upload-order-payslip/" + id + "?";

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function guestCancelOrder(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/cancel-order?";
  if (params.order_num)
    url += "&order=" + params.order_num
  if (params.email)
    url += "&email=" + params.email

  return new Promise((resolve, reject) => {
    axios
      .post(url, null, {
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function guestOrderReceived(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + "/received-order?";
  if (params.order_num)
    url += "&order=" + params.order_num
  if (params.email)
    url += "&email=" + params.email

  return new Promise((resolve, reject) => {
    axios
      .post(url, null, {
        headers: {
          Accept: "application/json",
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function makePayment(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.API_URL + process.env.API_PREFIX + "/payment/pay-order", data, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}

export async function getDownlineOrderList(params) {
  let url = process.env.API_URL + process.env.API_PREFIX + '/downline-orders?'
  if (params.per_page)
    url += "&per_page=" + params.per_page
  if (params.page)
    url += "&page=" + params.page
  if (params.status)
    url += "&status=" + params.status
  if (params.role)
    url += "&role=" + params.role
  if (params.target_id)
    url += "&target_id=" + params.target_id

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          'token': AUTH.getAccessToken()
        }
      })
      .then(response => {
        const { data } = response;
        if (data.http_code == '200')
          resolve(data);
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data) {
            reject(error.response.data);
          }
        }
      })
  });
}