import { CHANGE_CURRENCY,} from "../constants/ActionTypes";


const initialState = {
    symbol: 'RM',
    profile:false,
    user:{},
    cartInfo:{}
};

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PROFILE':
            return { ...state, profile: true };
        case 'FINISH_UPDATE':
            return { ...state, profile: false };
        case 'STORE_USER':
            return { ...state, user: action.user };
        case 'CART_INFO':
            return { ...state, cartInfo: action.info };
        case CHANGE_CURRENCY:
            return { ...state,
                symbol: action.symbol };
        default:
            return state;
    }
};
export default generalReducer;