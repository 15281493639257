import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import * as DOWNLINE from "../../../api/downline";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as AUTH from "../../../api/auth";
import { updateToken, addMyCart } from "../../../actions/index";
import { connect } from "react-redux";
// import defaultAvatar from "../../../assets/images/defaultAvatar.jpg";
import SideMenu from "../sidemenu";
import * as GENERAL from "../../../api/general";

const mobileReg = /^[0-9\b]+$/;
const stateArr = GENERAL.getStateList();
const re = /^[0-9\b]+$/;
const reZip = /^\d{5}$/;

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id ? props.match.params.id : 0,
      member: {},
      name: "",
      email: "",
      gender: "",
      mobileno: "",
      failMsg: [],
      updateErr: false,
      isOpen: false,
      avatarFile: null,
      loading: false,

      title: "",
      fname: "",
      lname: "",
      shipping: 0,
      billing: 0,
      street1: "",
      street2: "",
      city: "",
      zip: "",
      state: "",
      addMobileno: "",
      country: "MY",
      addErr: false,
    };
    this.inputRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  renderErrorMsg(name) {
    if (this.state.failMsg && this.state.failMsg.find((msg) => msg.name == name) !== undefined)
      return this.state.failMsg.find((msg) => msg.name == name).msg;
    else return null;
  }

  handleSubmit(e) {
    e.preventDefault();
    let failMsg = [];
    let valid = true;
    if (!mobileReg.test(this.state.mobileno)) {
      valid = false;
      failMsg.push({ name: "mobileno", msg: "Mobile Number is invalid" });
    }
    // if (!mobileReg.test(this.state.addMobileno)) {
    //   valid = false;
    //   failMsg.push({ name: "addMobileno", msg: "Shipping Mobile Number is invalid" });
    // }
    
    if (valid) {
      this.setState({ failMsg: [] });
      const profileData = new FormData();
      profileData.append("name", this.state.name);
      profileData.append("email", this.state.email);
      profileData.append("mobileno", this.state.mobileno);
      profileData.append("acc_type", 'p');

      if (this.state.title !== '0')
        profileData.append("title", this.state.title);
      profileData.append("fname", this.state.name);
      profileData.append("shipping", this.state.shipping);
      profileData.append("billing", this.state.billing);
      profileData.append("street1", this.state.street1);
      profileData.append("street2", this.state.street2);
      profileData.append("city", this.state.city);
      profileData.append("zip", this.state.zip);
      profileData.append("state", this.state.state);
      profileData.append("addmobileno", this.state.mobileno);
      profileData.append("country", this.state.country);
      profileData.append("atype", '0'); // 1 = home, 2 = office       

      DOWNLINE.addDownline(profileData)
        .then((res) => {
          toast.success("Add Member Successful!", { autoClose: 3000, position: "top-center" });
          this.props.history.push('/members/member-list')
        })
        .catch((err) => {
          if (err.http_code == 401 || (err.http_code == 403 && err.message == "token expired")) {
            AUTH.logout(this.props);
            toast.error("Session Expired", { autoClose: 3000, position: "top-center" });
            this.props.history.replace("/login");
          } else {
            if (err.data) {
              if (err.data.email) {
                failMsg.push({ name: "email", msg: err.data.email });
              } else if (err.data.mobileno) {
                failMsg.push({ name: "mobileno", msg: err.data.mobileno });
              }
            }
            else if (err.message && err.http_code == 422) {
              toast.error(err.message, { autoClose: 3000, position: "top-center" });
            }
          }
        });
    }
    this.setState({ failMsg })
  }

  render() {
    // const { loading } = this.state;
    // const styles = {
    //   radioButton: {
    //     margin: "5px 15px",
    //   },
    // };
    return (
      <div>
        <Breadcrumb title={"Members"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <SideMenu activeTab="member" />
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>Add New Member</h2>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-6" style={{ marginTop: "8px", marginBottom: "8px", textAlign: "left" }}>
                        <Link className="text-theme" to={`${process.env.PUBLIC_URL}/members/member-list`}>
                          <i className="fa fa-chevron-left" />
                          &nbsp; Back
                        </Link>
                      </div>
                    </div>
                    <div className="box-account box-info">
                      <form className="theme-form" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="form-row mb-3">
                        <div className="col-md-6">
                            <label for="title">Title</label>
                            <select
                              required
                              onChange={(e) => this.setState({ title: e.target.value })}
                              value={this.state.title}
                              className="form-control"
                              id="title">
                              <option value="0">Select title(Optional)</option>
                              <option value="1">Mr</option>
                              <option value="2">Ms</option>
                              <option value="3">Mrs</option>
                              <option value="4">Madam</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name"
                              maxLength={50}
                              onChange={(e) => this.setState({ name: e.target.value })}
                              value={this.state.name}
                              placeholder="" required />
                          </div>
                        </div>
                        <div className="form-row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="review">Email</label>
                            <input type="email" className="form-control"
                              maxLength={50}
                              onChange={(e) => this.setState({ email: e.target.value })}
                              value={this.state.email}
                              id="email" required />
                            {this.renderErrorMsg("email") && this.state.email.length > 0 ? (
                              <div className="text-danger small">{this.renderErrorMsg("email")}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="mobileNumber">Mobile Number</label>
                            <input type="number" className="form-control"
                              maxLength={18}
                              onChange={(e) => this.setState({ mobileno: e.target.value })}
                              value={this.state.mobileno} id="mobileNum" placeholder="" required />
                            {this.renderErrorMsg("mobileno") && this.state.mobileno.length > 0 ? (
                              <div className="text-danger small">{this.renderErrorMsg("mobileno")}</div>
                            ) : null}
                          </div>
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="welcome-msg">
                              <p>Fill up the form below to add new customer shipping address.</p>
                            </div>
                          </div>
                        </div>

                        <div className="form-row mb-3">
                          
                          {/* <div className="col-md-6">
                            <label htmlFor="fname">Name</label>
                            <input
                              onChange={(e) => this.setState({ fname: e.target.value })}
                              value={this.state.fname}
                              type="text"
                              className="form-control"
                              id="fname"
                              placeholder="Your name"
                              required
                            />
                          </div> */}
                        </div>
                        <div className="form-row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="street1">Street 1</label>
                            <input
                              onChange={(e) => this.setState({ street1: e.target.value })}
                              value={this.state.street1}
                              type="text"
                              className="form-control"
                              id="street1"
                              placeholder="18, Jalan Example"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="street2">Street 2</label>
                            <input
                              onChange={(e) => this.setState({ street2: e.target.value })}
                              value={this.state.street2}
                              type="text"
                              className="form-control"
                              id="street2"
                              placeholder="Taman Example(Optional)"
                            />
                          </div>
                        </div>
                        <div className="form-row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="city">City</label>
                            <input
                              onChange={(e) => this.setState({ city: e.target.value })}
                              value={this.state.city}
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder="Kepong"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="zip">Zip</label>
                            <input
                              onChange={(e) => this.setState({ zip: e.target.value })}
                              value={this.state.zip}
                              type="text"
                              className="form-control"
                              id="zip"
                              placeholder="52100"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="state">State</label>
                            {/* <input onChange={(e)=>this.setState({state : e.target.value})} value={this.state.state} type="text" className="form-control" id="state" placeholder="Kuala Lumpur" required /> */}
                            <select
                              onChange={(e) => this.setState({ state: e.target.value })}
                              value={this.state.state}
                              className="form-control"
                              id="state">
                              {stateArr.map((state, index) => (
                                <option key={index} value={Object.keys(state)}>{Object.values(state)}</option>
                              ))}
                            </select>
                          </div>
                          {/* <div className="col-md-6">
                            <label htmlFor="addMobileno">Mobile Number</label>
                            <input
                              onChange={(e) => this.setState({ addMobileno: e.target.value })}
                              value={this.state.addMobileno}
                              type="number"
                              className="form-control"
                              id="addMobileno"
                              placeholder="01xxxxxxxxx"
                              required
                            />
                          </div> */}
                        </div>

                        <div className="text-right">
                          <button type="submit" className="btn btn-solid">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateToken: (token) => dispatch(updateToken(token)),
  addMyCart: (cart) => dispatch(addMyCart(cart)),
});

export default connect(null, mapDispatchToProps)(AddMember);
