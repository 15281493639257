import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import * as AUTH from '../../api/auth'
import * as CART from '../../api/cart'
import { updateToken, refreshProfile } from '../../actions'
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.min.css';

const re = /^[0-9\b]+$/;
class DealerRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      email: '',
      mobileNum: '',
      password: '',
      confirmPass: '',
      icNum: '',
      name: '',
      education: '',
      loginErr: false,
      failMsg: ''
    }
  }

  componentWillMount() {
    // if (this.props.token)
    //   this.props.history.replace('/')
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  };

  handleImageChange = (e) => {
    this.setState({
      image: e.target.files[0]
    })
  };

  handleRegister(event) {
    event.preventDefault()
    let valid = true
    this.setState({ loginErr: false })
    if (event.target.mobileNum.value && !re.test(event.target.mobileNum.value)) {
      valid = false
      this.setState({
        loginErr: true,
        failMsg: 'The Mobile Number is invalid'
      })
    }

    if (! this.state.image) {
      valid = false
      this.setState({
        loginErr: true,
        failMsg: 'The IC Image is required'
      })
    }

    if (valid) {
      const registerData = new FormData();
      // Update the registerData object
      registerData.append('ic_image', this.state.image);
      registerData.append('name', this.state.name);
      registerData.append('email', this.state.email);
      registerData.append('password', this.state.password);
      registerData.append('confirm_password', this.state.confirmPass);
      registerData.append('ic_no', this.state.icNum);
      registerData.append('mobile_no', this.state.mobileNum);
      registerData.append('education', this.state.education);

      let referral = localStorage.getItem('referral');
      if (!!referral) {
        let referralData = JSON.parse(referral);
        registerData.append('ref', referralData.code);
      }
      
      AUTH.registerAsDealer(registerData).then(res => {
        if (res.data.status == "pending") {
          toast.success(res.data.message, { autoClose: 4000, position: 'top-center' });
          this.props.history.replace('/')
        } else if (res.data.status == 'completed') {
          toast.success(res.data.message, { autoClose: 4000, position: 'top-center' });
          AUTH.login(registerData).then(result => {
            this.props.updateToken(result.data.access_token)
            this.props.refreshProfile(true)
            CART.getLatestCart()
            this.props.history.replace('/')
          }).catch(err => {
            let failMsg = 'Invalid username/password'
            this.setState({
              loginErr: true,
              failMsg: failMsg
            })
          })
        }

      }).catch(err => {
        let failMsg = ''
        if (err.data) {
          if (err.data.name)
            failMsg = err.data.email
          if (err.data.email)
            failMsg = err.data.email
          if (err.data.password)
            failMsg = err.data.password
          if (err.data.confirm_password)
            failMsg = err.data.confirm_password
          this.setState({
            loginErr: true,
            failMsg: failMsg
          })
        }
      })
    }
  }

  // handleFrontIcImage = (e) => {
  //   console.log(e)
  //   e.preventDefault();
  //   let reader = new FileReader();
  //   let file = e.target.files[0];
  //   reader.onloadend = () => {
  //     this.setState({
  //       frontIcFile: file,
  //       frontIcUrl: reader.result,
  //     });
  //   };
  //   // console.log(this.state.frontIcFile);
  //   reader.onerror = (event) => {
  //     toast.error("File could not be read ", { autoClose: 2000, position: "top-center" });
  //   };
  //   if (file) reader.readAsDataURL(file);
  // };


  render() {
    const { loginErr, failMsg } = this.state
    var websiteTitle = localStorage.getItem("website");
    var seoTitle = "Register for Dropshipping | " + websiteTitle;
    var seoDesc = "Register for Dropshipping in " + websiteTitle;
    var seoImage = process.env.BASE_URL + "/logo.png";

    return (
      <div>
        <Helmet>
          <meta property="og:image" content={seoImage} data-react-helmet="true" />
          <meta property="og:title" itemprop="name" content={seoTitle} />
          <meta property="og:url" itemprop="url" content={window.location.href} data-react-helmet="true" />
          <meta itemprop="description" property="og:description" content={seoDesc} data-react-helmet="true" />

          <meta name="twitter:title" content={seoTitle} data-react-helmet="true" />
          <meta name="twitter:description" content={seoDesc} data-react-helmet="true" />
          <meta name="twitter:image" content={seoImage} data-react-helmet="true" />
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />

          <meta property="description" content={seoDesc} data-react-helmet="true" />
          <meta property="title" content={seoTitle} data-react-helmet="true" />
          <title>{seoTitle}</title>
        </Helmet>
        <Breadcrumb title={"Register As Dealer"} />
        {/*Regsiter section*/}
        <section className="register-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>Register for Dropshipping</h3>
                <div className="theme-card">
                  <form className="theme-form" onSubmit={(e) => this.handleRegister(e)}>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" onChange={this.handleChange} id="name" placeholder="" required />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="review">Email</label>
                        <input type="email" className="form-control" id="email" onChange={this.handleChange} placeholder="eg: abc@example.com" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <input type="number" className="form-control" id="mobileNum" onChange={this.handleChange} placeholder="eg: 60123456789" required />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" onChange={this.handleChange} placeholder="At least 6 characters and contain Alphabets, Numbers and Symbols" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="ConPassword">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPass"
                          onChange={this.handleChange}
                          placeholder="Confirm your password"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="password">IC Number</label>
                        <input type="number" className="form-control" id="icNum" onChange={this.handleChange} placeholder="eg: 975625055656" required />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="ConPassword">IC Image (Front)</label>
                        <br></br>
                        <input
                          className="fileInput"
                          type="file"
                          ref={this.inputRef}
                          accept="image/png,image/jpeg,image/jpg"
                          onChange={(e) => this.handleImageChange(e)}
                        />
                      </div>
                    </div>
                    {/* <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="password">Education</label>
                        <select required onChange={(e) => this.setState({ education: e.target.value })} value={this.state.education} class="form-control" id="education" style={{height: 'auto', fontSize: '12px', padding: '17px 25px'}}>
                            <option value="" disabled>Please Select</option>
                            <option value="SPM">SPM</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Degree">Degree</option>
                            <option value="Master">Master</option>
                            <option value="Others">Others</option>
                        </select>
                      </div>
                    </div> */}
                    <br/>
                    {loginErr ?
                        <div style={errMsg}>{failMsg}</div> : null
                    }
                    <br/>
                    <div style={{ textAlign: 'right' }}>
                      <button type="submit" className="btn btn-solid" >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const errMsg = {
  color: 'red',
  marginLeft: 6,
  paddingBottom: 5,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
  updateToken: token => dispatch(updateToken(token)),
  refreshProfile: profile => dispatch(refreshProfile(profile)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealerRegister);